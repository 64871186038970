import { Box, Button, Card, Container, Divider, FormControlLabel, MenuItem, Switch, Tab, TablePagination, Tabs, Typography } from '@mui/material';
import Page from 'src/appComponents/Page';
import { useLocation, useNavigate, } from 'react-router-dom';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import Iconify from 'src/components/iconify';
import { useSettingsContext } from 'src/components/settings';
import { useLocales } from 'src/locales';
import { PATH_DASHBOARD } from 'src/routes/paths';
import FormProvider from 'src/components/hook-form';
import { DataGrid, GridCellParams, GridColDef, GridSortModel } from '@mui/x-data-grid';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import useTable from 'src/appHooks/useTable';
import useResponsive from 'src/hooks/useResponsive';
import { useSelector } from 'react-redux';
import { RootState, dispatch } from 'src/redux/store';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { CloseShipSliceProps, ContainerFilters, ContainerStatusType, ContainerTemplateSearchResult, DEFAULT_CONTAINER_FILTERS, EnableDisableSliceProps, TemplateStatusType } from 'src/@types/container';
import { useForm } from 'react-hook-form';
import { isArray, isObject, remove, isEqual, isBoolean } from 'lodash';
import { setFiltersInUrl } from 'src/redux/container/container-slices';
import useTabs from 'src/appHooks/useTabs';
import Label from 'src/components/label';
import TableMoreMenu from 'src/appComponents/TableMoreMenu';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { OrganizationPermissionTypes } from 'src/@types/permissions';
import { useSnackbar } from 'notistack';
import ContainerFilterSummary from 'src/sections/@dashboard/container/ContainerFilterSummary';
import ContainerGeneralFilters from 'src/sections/@dashboard/container/ContainerGeneralFilters';
import DateZone from 'src/appComponents/DateZone';
import { containerOperations } from 'src/redux/container';
import NewContainer from './NewContainer';
import GenericFilterSidebar from 'src/utils/list/sidebar/GenericFilterSidebar';
import { FilterListType, QuickFilters } from 'src/@types/list';
import containerService from 'src/services/containerService';
import { RequestTypeArr } from 'src/@types/request';
import { CloseContainerModal, ShipModal } from './ContainerDetail';

const BASIC_FILTER_OPTIONS = {
    pageIndex: 0,
    pageSize: 10,
    status: null
};

interface ContainerListProps {
    isTemplate?: boolean
}

export default function ContainerList({ isTemplate }: ContainerListProps) {

    const {
        page,
        setPage,
        order,
        setOrder,
        orderBy,
        setOrderBy,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage,
    } = useTable();

    const { translate, currentLang } = useLocales();

    const { themeStretch } = useSettingsContext();

    const { enqueueSnackbar } = useSnackbar();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { isLoading, containersList, totalCount, statistics, pageSize, pageIndex, filtersInUrl } = useSelector((state: RootState) => state.container);

    const [filters, setFilters] = useState<ContainerFilters>(DEFAULT_CONTAINER_FILTERS);

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [editId, setEditId] = useState<string | undefined>(undefined);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [resetFormElement, setResetFormElement] = useState("");

    const [openCreation, setOpenCreation] = useState(false);

    const [showSummary, setShowSummary] = useState(false);

    const [openShip, setOpenShip] = useState(false);

    const [openClose, setOpenClose] = useState(false);

    const methods = useForm({ defaultValues: DEFAULT_CONTAINER_FILTERS });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected 
    const statsKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending", "status", "onlyEnabled", "dateField"], []);

    const fullKeysToRemove: string[] = useMemo(() => ["from", "to", "fromCreatedOn", "toCreatedOn", "fromClosedOn", "toClosedOn", "fromShippedOn", "toShippedOn"], []);

    const statsKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key));
    }, [statsKeysToRemove]);

    const fullKeyRemover = useCallback((key: string) => {
        return !(statsKeysToRemove.includes(key) || fullKeysToRemove.includes(key));
    }, [statsKeysToRemove, fullKeysToRemove]);

    const isDefault = useCallback((filter: ContainerFilters, controller?: ContainerFilters, forStats?: boolean) => {

        const check = controller ? controller : DEFAULT_CONTAINER_FILTERS;

        const found = remove(Object.keys(check), forStats ? statsKeyRemover : fullKeyRemover)
            .find((element) => {
                if (isArray(filter[element]) && isArray(check[element])) {
                    return !isEqual(filter[element], check[element]);
                } else if (isObject(filter[element]) && isObject(check[element])) {

                    const strFilters = JSON.stringify(filter[element], Object.keys(filter[element]).sort());

                    const strDefaultFilters = JSON.stringify(check[element], Object.keys(check[element]).sort());

                    return strFilters !== strDefaultFilters;
                } else {
                    return filter[element] !== check[element];
                }
            });

        return (!found);
    }, [statsKeyRemover, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- SIDEBAR FILTERS ----// 
    const filtersInSidebar: FilterListType[] = useMemo(() => [
        {
            name: "name",
            label: `${translate(`containers.tableHeaders.containerRef`)}`,
            type: "TextField"
        },
        {
            name: "contentTypes",
            label: `${translate("containers.tableHeaders.contentTypes")}`,
            type: "MultiSelect",
            options: ["All"].concat(RequestTypeArr.slice(1)).map((type) => {
                return {
                    value: type,
                    label: type === "All" ? `${translate(`commons.all`)}` : `${translate(`request.${type}`)}`
                };
            })
        },
        {
            name: "tagsSection",
            label: `${translate("commons.tags")}`,
            type: "Section"
        },
        {
            name: "tags",
            label: `${translate("commons.tags")}`,
            type: "Autocomplete",
            service: isTemplate ? containerService.searchTagsContainerTemplate : containerService.searchTagsContainer
        }
    ], [isTemplate, translate]);

    //---- CLOSE AND OPEN SIDEBAR FUNC
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = () => {
        if (resetForm) {
            reset(DEFAULT_CONTAINER_FILTERS);
        };
        setOpenSidebar(false);
    };

    //---- CLEAR FROM SUMMARY FUNC
    const handleClearFromSummary = (section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    };

    //---- FILTERS IN URL GET/SET START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(BASIC_FILTER_OPTIONS);

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters)
            .filter(x => isArray(filters[x]) ? filters[x].length > 0 :
                isBoolean(filters[x]) ? filters[x] !== DEFAULT_CONTAINER_FILTERS[x] : (filters[x] && filters[x] !== DEFAULT_CONTAINER_FILTERS[x]))
            .map((key) => {
                if (isArray(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]).replace(/stellantis-europe-/g, ""))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate]);

    const getPageAndSize = useCallback((filtersInUrl?: string) => {
        if (firstRender) {
            let returnValues = [0, 0];

            if (filtersInUrl) {
                const cleanedHash = filtersInUrl.slice(1);

                const decodedQuery = decodeURIComponent(cleanedHash);

                const searchParams = new URLSearchParams(decodedQuery);

                returnValues = [
                    searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : pageSize,
                    searchParams.get('pageIndex') ? Number(searchParams.get('pageIndex')) : pageIndex
                ];
            } else {
                returnValues = [pageSize, pageIndex];
            }

            return returnValues;
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    const getFiltersFromUrl = useCallback(() => {
        const { hash } = location;

        let searchFilters = {};

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize(filtersInUrl);

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                name: searchParams.get('name'),
                contentTypes: searchParams.get('contentTypes') ? JSON.parse(searchParams.get('contentTypes')!) : null,
                tags: searchParams.get('tags') ? JSON.parse(searchParams.get('tags')!) : null,
                customFields: searchParams.get('customFields') ? JSON.parse(searchParams.get('customFields')!.replace(/containers/g, "stellantis-europe-containers")) : null,
                onlyEnabled: searchParams.get('onlyEnabled') ? (searchParams.get('onlyEnabled') === "false" ? false : true) : null,
                from: searchParams.get('from'),
                to: searchParams.get('to'),
                status: searchParams.get('status'),
                fromCreatedOn: searchParams.get('fromCreatedOn'),
                toCreatedOn: searchParams.get('toCreatedOn'),
                fromClosedOn: searchParams.get('fromClosedOn'),
                toClosedOn: searchParams.get('toClosedOn'),
                fromShippedOn: searchParams.get('fromShippedOn'),
                toShippedOn: searchParams.get('toShippedOn')
            };

        } else {
            searchFilters = {
                pageIndex: pageIndexFunc,
                pageSize: pageSizeFunc,
                sortField: orderBy || null,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null
            };
        }

        searchFilters = Object.fromEntries(Object.entries(searchFilters).filter(([_, value]) => value !== null && value !== 'null'));

        return searchFilters as ContainerFilters;

    }, [location, order, orderBy, getPageAndSize, filtersInUrl]);
    //---- FILTERS IN URL GET/SET END ----//

    //---- QUICKFILTERS START ----// 
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(isTemplate ? "Enabled" : "");

    const quickFilters: QuickFilters[] = useMemo(() => [
        {
            key: '',
            label: `${translate('commons.all')}`,
            color: 'info'
        },
        ...(isTemplate ?
            [{
                key: 'Enabled',
                label: `${translate('statuses.enabled')}`,
                color: 'success',
            },
            {
                key: 'Disabled',
                label: `${translate('statuses.disabled')}`,
                color: 'warning',
            }] :
            [{
                key: 'Open',
                label: `${translate('statuses.open')}`,
                color: 'success',
            },
            {
                key: 'Closed',
                label: `${translate('statuses.closed')}`,
                color: 'error'
            },
            {
                key: 'Shipped',
                label: `${translate('statuses.shipped')}`,
                color: 'warning'
            }]) as QuickFilters[]
    ], [translate, isTemplate]);

    const renderQuickFilters = (key: string) => {
        if (statistics) {
            switch (key) {
                case "":
                    return statistics.all;
                case "Enabled":
                    return statistics.enabled;
                case "Disabled":
                    return statistics.disabled;
                case "Open":
                    return statistics.open;
                case "Closed":
                    return statistics.closed;
                case "Shipped":
                    return statistics.shipped;
                default:
                    return 0;
            }
        } else return 0;
    };
    //---- QUICKFILTERS END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: ContainerFilters) => {

        var searchFilters: any = {};

        if (values === DEFAULT_CONTAINER_FILTERS) {
            searchFilters = BASIC_FILTER_OPTIONS;
        } else {

            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null,
                name: values.name !== DEFAULT_CONTAINER_FILTERS.name ? values.name : null,
                contentTypes: JSON.stringify(values.contentTypes) !== JSON.stringify(DEFAULT_CONTAINER_FILTERS.contentTypes) ? values.contentTypes : null,
                tags: JSON.stringify(values.tags) !== JSON.stringify(DEFAULT_CONTAINER_FILTERS.tags) ? values.tags : null,
                customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_CONTAINER_FILTERS.customFields) ? values.customFields : null,
                onlyEnabled: values.onlyEnabled,
                status: values.status !== DEFAULT_CONTAINER_FILTERS.status ? values.status : null,
                from: values.from !== DEFAULT_CONTAINER_FILTERS.from ? values.from : null,
                to: values.to !== DEFAULT_CONTAINER_FILTERS.to ? values.to : null,
                fromCreatedOn: values.fromCreatedOn !== DEFAULT_CONTAINER_FILTERS.fromCreatedOn ? values.fromCreatedOn : null,
                toCreatedOn: values.toCreatedOn !== DEFAULT_CONTAINER_FILTERS.toCreatedOn ? values.toCreatedOn : null,
                fromClosedOn: values.fromClosedOn !== DEFAULT_CONTAINER_FILTERS.fromClosedOn ? values.fromClosedOn : null,
                toClosedOn: values.toClosedOn !== DEFAULT_CONTAINER_FILTERS.toClosedOn ? values.toClosedOn : null,
                fromShippedOn: values.fromShippedOn !== DEFAULT_CONTAINER_FILTERS.fromShippedOn ? values.fromShippedOn : null,
                toShippedOn: values.toShippedOn !== DEFAULT_CONTAINER_FILTERS.toShippedOn ? values.toShippedOn : null
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [order, orderBy, page, rowsPerPage, updateFiltersInUrl]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Requests list and statistics using filters
    const adjustLastFiltered = useCallback((values: ContainerFilters) => ({
        pageIndex: 0,
        pageSize: 10,
        sortField: "",
        sortAscending: false,
        name: values.name !== DEFAULT_CONTAINER_FILTERS.name ? values.name : undefined,
        contentTypes: JSON.stringify(values.contentTypes) !== JSON.stringify(DEFAULT_CONTAINER_FILTERS.contentTypes) ? values.contentTypes : undefined,
        tags: JSON.stringify(values.tags) !== JSON.stringify(DEFAULT_CONTAINER_FILTERS.tags) ? values.tags : undefined,
        customFields: JSON.stringify(values.customFields) !== JSON.stringify(DEFAULT_CONTAINER_FILTERS.customFields) ? values.customFields : undefined,
        onlyEnabled: values.onlyEnabled,
        status: values.status !== DEFAULT_CONTAINER_FILTERS.status ? values.status : undefined,
        from: values.from !== DEFAULT_CONTAINER_FILTERS.from ? values.from : undefined,
        to: values.to !== DEFAULT_CONTAINER_FILTERS.to ? values.to : undefined,
        fromCreatedOn: values.fromCreatedOn !== DEFAULT_CONTAINER_FILTERS.fromCreatedOn ? values.fromCreatedOn : undefined,
        toCreatedOn: values.toCreatedOn !== DEFAULT_CONTAINER_FILTERS.toCreatedOn ? values.toCreatedOn : undefined,
        fromClosedOn: values.fromClosedOn !== DEFAULT_CONTAINER_FILTERS.fromClosedOn ? values.fromClosedOn : undefined,
        toClosedOn: values.toClosedOn !== DEFAULT_CONTAINER_FILTERS.toClosedOn ? values.toClosedOn : undefined,
        fromShippedOn: values.fromShippedOn !== DEFAULT_CONTAINER_FILTERS.fromShippedOn ? values.fromShippedOn : undefined,
        toShippedOn: values.toShippedOn !== DEFAULT_CONTAINER_FILTERS.toShippedOn ? values.toShippedOn : undefined
    }), []);

    const onSearch = useCallback(async (filtersFromUrl: ContainerFilters) => {

        updateFiltersInUrl(filtersFromUrl);

        const filtersFromUrlFormatted = JSON.parse(JSON.stringify(filtersFromUrl));

        if (filtersFromUrlFormatted.from && filtersFromUrlFormatted.to) {
            filtersFromUrlFormatted.from = new Date(filtersFromUrlFormatted.from).toISOString();
            filtersFromUrlFormatted.to = new Date(new Date(filtersFromUrlFormatted.to).setHours(24, 59, 59)).toISOString();
        } else if (filtersFromUrlFormatted.fromCreatedOn && filtersFromUrlFormatted.toCreatedOn) {
            setValue("dateField", "Opened");
            filtersFromUrlFormatted.fromCreatedOn = new Date(filtersFromUrlFormatted.fromCreatedOn).toISOString();
            filtersFromUrlFormatted.toCreatedOn = new Date(new Date(filtersFromUrlFormatted.toCreatedOn).setHours(24, 59, 59)).toISOString();
        } else if (filtersFromUrlFormatted.fromClosedOn && filtersFromUrlFormatted.toClosedOn) {
            setValue("dateField", "Closed");
            filtersFromUrlFormatted.fromClosedOn = new Date(filtersFromUrlFormatted.fromClosedOn).toISOString();
            filtersFromUrlFormatted.toClosedOn = new Date(new Date(filtersFromUrlFormatted.toClosedOn).setHours(24, 59, 59)).toISOString();
        } else if (filtersFromUrlFormatted.fromShippedOn && filtersFromUrlFormatted.toShippedOn) {
            setValue("dateField", "Shipped");
            filtersFromUrlFormatted.fromShippedOn = new Date(filtersFromUrlFormatted.fromShippedOn).toISOString();
            filtersFromUrlFormatted.toShippedOn = new Date(new Date(filtersFromUrlFormatted.toShippedOn).setHours(24, 59, 59)).toISOString();
        }

        const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrlFormatted.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl: ContainerFilters = { ...filtersFromUrlFormatted, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        let searchStats = false;

        const strFiltersFromUrl = JSON.stringify(filtersFromUrl, Object.keys(filtersFromUrl).sort());

        const strBasicFilters = JSON.stringify(BASIC_FILTER_OPTIONS, Object.keys(BASIC_FILTER_OPTIONS).sort());

        if (strFiltersFromUrl === strBasicFilters
            || !isDefault(filtersFromUrl, lastStatsFilters as ContainerFilters, true)
            || firstRender
        ) {
            searchStats = true;
            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
        };

        if (isTemplate) {
            dispatch(containerOperations.searchContainerTemplate(updatedFiltersFromUrl));
            delete updatedFiltersFromUrl.onlyEnabled;
            if (searchStats) dispatch(containerOperations.searchStatisticsContainerTemplate(updatedFiltersFromUrl));
        } else {
            dispatch(containerOperations.searchContainer({ filters: updatedFiltersFromUrl, isIstance: false }));
            if (searchStats) dispatch(containerOperations.searchStatisticsContainer(updatedFiltersFromUrl));
        }

        if (firstRender) setFirstRender(false);

    }, [updateFiltersInUrl, firstRender, adjustLastFiltered, isDefault, lastStatsFilters, isTemplate, setValue]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filterToCheck: ContainerFilters, controller: ContainerFilters) => {

        const differencesToController = Object.keys(filterToCheck).filter((filter) => filterToCheck[filter] !== controller[filter]);

        if (differencesToController.includes("sortField") || differencesToController.includes("sortAscending")) return true;

        const differencesToFilter = Object.keys(controller).filter((filter) => controller[filter] !== filterToCheck[filter]);

        return differencesToFilter.includes("sortField") || differencesToFilter.includes("sortAscending");
    }, []);

    useEffect(() => {

        let searchFilters = getFiltersFromUrl();

        switch (filterStatus) {
            case "":
                searchFilters.onlyEnabled = undefined;
                searchFilters.status = undefined;
                break;
            case "Enabled":
                searchFilters.onlyEnabled = true;
                searchFilters.status = undefined;
                break;
            case "Disabled":
                searchFilters.onlyEnabled = false;
                searchFilters.status = undefined;
                break;
            case "Open":
                searchFilters.onlyEnabled = undefined;
                searchFilters.status = "Open";
                break;
            case "Closed":
                searchFilters.onlyEnabled = undefined;
                searchFilters.status = "Closed";
                break;
            case "Shipped":
                searchFilters.onlyEnabled = undefined;
                searchFilters.status = "Shipped";
                break;
        };

        const strSearchFilters = JSON.stringify(searchFilters, Object.keys(searchFilters).sort());

        const strFilters = JSON.stringify(filters, Object.keys(filters).sort());

        if (strSearchFilters !== strFilters || lastUsedLang !== currentLang.label) {

            if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;
            setFilters(searchFilters as ContainerFilters);
            onSearch(searchFilters as ContainerFilters);
            setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [firstRender, location, page, rowsPerPage, orderBy, order, filterStatus, currentLang, isTemplate, lastUsedLang, onSearch, getFiltersFromUrl, filters, setPage, setRowsPerPage, isOrderDiff]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = () => {
        fetchData(getValues());
        setResetForm(false);
        setOpenSidebar(false);
        setPage(0);
    };

    //---- FILTERS RESET - START ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = () => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        setPage(0);
        setResetForm(true);
        setShowSummary(false);
        fetchData(DEFAULT_CONTAINER_FILTERS);
        reset(DEFAULT_CONTAINER_FILTERS);
    };

    const handleResetSingleFilter = (fieldName: string, value?: any) => {

        if (isArray(formValues[fieldName])) {

            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                if (formValues[fieldName].length === 0) formValues[fieldName] = DEFAULT_CONTAINER_FILTERS[fieldName];
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);

        } else {
            formValues[fieldName] = DEFAULT_CONTAINER_FILTERS[fieldName];
            setValue(fieldName, DEFAULT_CONTAINER_FILTERS[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues);
        }
    };
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE START ----//
    const [openMenu, setOpenMenuActions] = useState<HTMLElement | null>(null);

    const [actualRow, setActualRow] = useState<any>(null);

    const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
        setOpenMenuActions(event.currentTarget);
    };

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    const handleCellClick = (params: GridCellParams<any>) => {

        setActualRow(params);

        if (params.field !== "options") {
            if (isTemplate)
                navigate(PATH_DASHBOARD.containers.templates.detail(params.row.id.toString()));
            else
                navigate(PATH_DASHBOARD.containers.detail(params.row.id.toString()));
        }
    };

    const handleSort = (sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    };

    const getHeight = () => {
        let height: string | number = "auto";

        if (!dense || containersList.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    };

    const getMaxHeight = () => {
        return isDesktop ? 650 : 700;
    };

    const handleLogs = useCallback((id: string) => {
        if (isTemplate)
            navigate(PATH_DASHBOARD.containers.templates.logs(id));
        else
            navigate(PATH_DASHBOARD.containers.logs(id));
    }, [navigate, isTemplate]);

    const handleEnableDisableContainer = useCallback(async (id: string, action: "Enable" | "Disable") => {

        const options: EnableDisableSliceProps = {
            id: id,
            tab: filterStatus as TemplateStatusType,
            action: action,
            totalCount: totalCount,
            pageSize: pageSize,
            listLen: containersList.length,
            filters: filters
        };

        let x = await dispatch(containerOperations.enableDisableContainerTemplate(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "Enable" ? 'commons.enabled' : 'commons.disabled')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate, filterStatus, containersList, totalCount, filters, pageSize]);

    const handleOpenContainer = useCallback(async (id: string) => {

        let x = await dispatch(containerOperations.openContainer(id));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate('containers.list.opened')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate]);

    const handleCloseShipContainer = useCallback(async (id: string, action: "Close" | "Ship", date?: string) => {

        const options: CloseShipSliceProps = {
            id: id,
            tab: filterStatus as ContainerStatusType,
            action: action,
            totalCount: totalCount,
            pageSize: pageSize,
            listLen: containersList.length,
            now: new Date().toISOString(),
            filters: filters,
            shippedOn: date
        };

        let x = await dispatch(containerOperations.closeShipContainer(options));

        if (x.meta.requestStatus !== 'fulfilled') {
            enqueueSnackbar(`${translate('commons.error')}`, {
                variant: "error",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
        else {
            enqueueSnackbar(`${translate(action === "Close" ? 'statuses.closed' : 'statuses.shipped')}`, {
                variant: "success",
                autoHideDuration: 3000,
                anchorOrigin: { vertical: "top", horizontal: "right" }
            });
        }
    }, [enqueueSnackbar, translate, filterStatus, containersList, totalCount, filters, pageSize]);

    const TEMPLATE_COLUMNS: GridColDef<ContainerTemplateSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: `${translate('containers.tableHeaders.containerRef')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.name}
                    </Typography>
                );
            }
        },
        {
            field: 'contentTypes',
            headerName: `${translate('containers.tableHeaders.contentTypes')}`,
            flex: isDesktop ? 1.1 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.contentTypes.map((type) => `${translate(`request.${type}`)}`).join(', ')}
                    </Typography>
                );
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'createdBy',
            headerName: `${translate('containers.detail.createdBy')}`,
            flex: isDesktop ? 0.75 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            valueGetter: (_, row) => {
                return row.createdBy?.firstName + " " + row.createdBy?.lastName;
            }
        },
        {
            field: 'active',
            headerName: `${translate('statuses.active')}`,
            flex: isDesktop ? 0.3 : undefined,
            minWidth: !isDesktop ? 250 : undefined,
            headerAlign: 'center',
            align: 'center',
            renderCell: (obj) => {
                return (
                    <Iconify
                        icon={obj.row.active ? 'eva:checkmark-circle-fill' : 'eva:close-circle-outline'}
                        sx={{
                            width: 20,
                            height: 20,
                            color: 'success.main',
                            ...(!obj.row.active && { color: 'error.main' }),
                        }}
                    />
                );
            }
        },
        {
            field: 'onlyEnabled',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                const searchFilter = obj.row.enabled ? "Enabled" : "Disabled";

                return (
                    <Label
                        color={quickFilters.find((filter) => filter.key === searchFilter)!.color}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={searchFilter.length >= 15}
                    >
                        {`${translate(`statuses.${searchFilter.toLowerCase()}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        openModal={setOpenCreation}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        setEdit={setEditId}
                        currentRow={actualRow}
                        handleEnableDisable={handleEnableDisableContainer}
                        handleOpen={handleOpenContainer}
                        isTemplate={isTemplate}
                        handleLogs={handleLogs}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, quickFilters, handleEnableDisableContainer, handleOpenContainer, isTemplate, handleLogs]);

    const CONTAINER_COLUMNS: GridColDef<ContainerTemplateSearchResult>[] = useMemo(() => [
        {
            field: 'name',
            headerName: `${translate('containers.tableHeaders.containerRef')}`,
            flex: isDesktop ? 0.8 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.name}
                    </Typography>
                );
            }
        },
        {
            field: 'contentTypes',
            headerName: `${translate('containers.tableHeaders.contentTypes')}`,
            flex: isDesktop ? 1.3 : undefined,
            minWidth: !isDesktop ? 155 : undefined,
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {obj.row.contentTypes.map((type) => `${translate(`request.${type}`)}`).join(', ')}
                    </Typography>
                );
            }
        },
        {
            field: 'createdOn',
            headerName: `${translate('orders.tableHeaders.createdOn')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={obj.row.createdOn}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'closedOn',
            headerName: `${translate('containers.tableHeaders.closedOn')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.closedOn || "")}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'shippedOn',
            headerName: `${translate('containers.tableHeaders.shippedOn')}`,
            flex: isDesktop ? 0.9 : undefined,
            minWidth: !isDesktop ? 180 : undefined,
            renderCell: (obj) => {
                return (
                    <DateZone
                        date={new Date(obj.row.shippedOn || "")}
                        shortMonth
                        noSeconds
                        variant={"body2"}
                    />
                );
            }
        },
        {
            field: 'quantity',
            headerName: `${translate('commons.quantity')}`,
            flex: isDesktop ? 0.45 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    <Typography noWrap variant='body2'>
                        {`${(obj.row.numberOfItems || 0) + (obj.row.numberOfExtraItems || 0)} ${obj.row.numberOfExtraItems ? `(${obj.row.numberOfItems}+${obj.row.numberOfExtraItems})` : ""}`}
                    </Typography>
                );
            }
        },
        {
            field: 'status',
            headerName: `${translate('returns.tableHeaders.status')}`,
            flex: isDesktop ? 0.6 : undefined,
            minWidth: !isDesktop ? 175 : undefined,
            headerAlign: 'center',
            align: "center",
            renderCell: (obj) => {
                return (
                    obj.row.status &&
                    <Label
                        color={quickFilters.find((filter) => filter.key === obj.row.status)!.color}
                        sx={{ textTransform: 'uppercase' }}
                        smallerText={obj.row.status.length >= 15}
                    >
                        {`${translate(`statuses.${obj.row.status.toLowerCase()}`)}`}
                    </Label>
                );
            }
        },
        {
            field: 'options',
            headerName: ``,
            flex: isDesktop ? 0.2 : undefined,
            maxWidth: !isDesktop ? 70 : undefined,
            headerAlign: 'center',
            align: "center",
            sortable: false,
            renderCell: (obj: any) => {
                return (
                    <OptionsComponent
                        openMenu={openMenu}
                        handleOpenMenu={handleOpenMenu}
                        handleCloseMenu={handleCloseMenu}
                        object={obj}
                        currentRow={actualRow}
                        handleCloseShip={(id, action) => action === "Ship" ? setOpenShip(true) : setOpenClose(true)}
                        openModal={setOpenCreation}
                        setEdit={setEditId}
                        handleLogs={handleLogs}
                    />
                );
            }
        }
    ], [translate, isDesktop, openMenu, actualRow, quickFilters, handleLogs]);

    useEffect(() => {
        const [rowsFromFunc, pageFromFunc] = getPageAndSize();

        if (totalCount <= rowsFromFunc * pageFromFunc) onChangePage(null, 0);

    }, [onChangePage, totalCount, getPageAndSize]);

    useEffect(() => {
        if (pageIndex !== page) setPage(pageIndex);

    }, [pageIndex, setPage]);
    //---- HANDLE TABLE END ----//

    return (
        <Page title={`${translate(`menu.management.containers${isTemplate ? "Template" : ""}.title`)}`}>

            <Container maxWidth={themeStretch ? false : 'lg'}>
                <ShipModal isOpen={openShip} onSubmit={handleCloseShipContainer} toggle={(v) => setOpenShip(v)} id={actualRow?.row?.id} name={actualRow?.row?.name} />
                <CloseContainerModal isOpen={openClose} onSubmit={handleCloseShipContainer} toggle={(v) => setOpenClose(v)} id={actualRow?.row?.id} name={actualRow?.row?.name} />
                <HeaderBreadcrumbs
                    heading={`${translate(`menu.management.containers${isTemplate ? "Template" : ""}.title`)}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate(`menu.management.containers${isTemplate ? "Template" : ""}.title`)}` }
                    ]}
                    action={
                        <>
                            {isTemplate &&
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Template_Create]}>
                                    <Button
                                        variant="contained"
                                        sx={{ borderRadius: "60px !important" }}
                                        onClick={() => setOpenCreation(true)}
                                        startIcon={<Iconify icon={'eva:plus-fill'} />}
                                    >
                                        {`${translate('commons.new')}`}
                                    </Button>
                                </PermissionBasedGuard>
                            }
                        </>
                    }
                />

                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Template_Create]}>
                    <NewContainer
                        isOpen={openCreation}
                        toggle={() => setOpenCreation(false)}
                        id={editId}
                        edit={setEditId}
                        options={{
                            tab: filterStatus as TemplateStatusType,
                            totalCount: totalCount,
                            listLen: containersList.length,
                            filters: filters,
                            pageSize: pageSize
                        }}
                    />
                </PermissionBasedGuard>

                <Card>

                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, pb: 2 }}>

                        <Typography variant="body2" sx={{ mt: 2 }}>
                            {`${translate(`containers.${isTemplate ? "template." : "detail."}subtitle`)}`}
                        </Typography>

                        <Box sx={{ mt: 2, display: 'flex' }}>

                            <FormProvider methods={methods}>
                                <GenericFilterSidebar
                                    isOpen={openSidebar}
                                    onOpen={handleOpenSidebar}
                                    onClose={handleCloseSidebar}
                                    onFilter={handleSearchFilters}
                                    onResetAll={handleResetAllFilter}
                                    defaultFilters={DEFAULT_CONTAINER_FILTERS}
                                    resetTrigger={resetForm}
                                    filterValues={{ ...formValues, ...(getFiltersFromUrl()) }}
                                    filterList={filtersInSidebar}
                                    isDefault={isDefault}
                                    setShowSummary={setShowSummary}
                                    customfieldContext={["Containers"]}
                                    resetFormElement={resetFormElement}
                                    setResetFormElement={setResetFormElement}
                                />
                            </FormProvider>
                        </Box>
                    </Box>

                    <Divider />

                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        value={filterStatus}
                        onChange={onChangeFilterStatus}
                        sx={{
                            px: { xs: 0, md: 2 },
                            bgcolor: 'background.neutral'
                        }}
                    >
                        {quickFilters.map((tab) =>
                            <Tab
                                disableRipple
                                key={tab.key}
                                label={tab.label}
                                value={tab.key}
                                icon={
                                    <Label color={tab?.color} sx={{ mr: 1 }}>
                                        {renderQuickFilters(tab.key)}
                                    </Label>
                                }
                            />
                        )}
                    </Tabs>

                    <Divider />

                    <FormProvider methods={methods}>
                        <ContainerGeneralFilters
                            filters={{ ...formValues, ...(getFiltersFromUrl()) }}
                            onFilter={handleSearchFilters}
                            resetAll={handleResetAllFilter}
                            showSummary={showSummary}
                            setShowSummary={setShowSummary}
                            isTemplate={!!isTemplate}
                        />
                    </FormProvider>

                    <ContainerFilterSummary
                        filters={openSidebar ? DEFAULT_CONTAINER_FILTERS : { ...formValues, ...(getFiltersFromUrl()) }}
                        isShowReset={!isDefault({ ...formValues, ...(getFiltersFromUrl()) }) && !openSidebar && showSummary}
                        onResetAll={handleResetAllFilter}
                        onResetFilter={handleResetSingleFilter}
                    />

                    <Divider />

                    <Box>
                        <DataGrid
                            rows={containersList}
                            columns={isTemplate ? TEMPLATE_COLUMNS : CONTAINER_COLUMNS}
                            pagination
                            paginationModel={{
                                page: page,
                                pageSize: rowsPerPage
                            }}
                            density={(dense && containersList.length > 0) ? 'compact' : 'standard'}
                            sortingMode={"server"}
                            onSortModelChange={handleSort}
                            loading={isLoading}
                            disableColumnResize
                            slots={{
                                noRowsOverlay: noData,
                                footer: () => (
                                    <Box sx={{
                                        position: 'relative',
                                        width: { xs: "90vw", md: "auto" },
                                    }}>
                                        <TablePagination
                                            rowsPerPageOptions={[5, 10, 15, 30]}
                                            component="div"
                                            count={totalCount}
                                            rowsPerPage={rowsPerPage}
                                            page={!totalCount || totalCount <= 0 ? 0 : page}
                                            onPageChange={onChangePage}
                                            onRowsPerPageChange={onChangeRowsPerPage}
                                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                            sx={{
                                                overflow: "hidden",
                                                "& .MuiTablePagination-input": {
                                                    ml: { xs: 0.5, md: "default" },
                                                    mr: { xs: 3.5, md: "default" }
                                                }
                                            }}
                                        />
                                        <FormControlLabel
                                            control={<Switch checked={dense} onChange={onChangeDense} />}
                                            label={`${translate('commons.dense')}`}
                                            sx={{
                                                px: { xs: 0, sm: 3 },
                                                py: { xs: 0, sm: 1.5 },
                                                pb: { xs: 1.5, sm: 0 },
                                                mx: 0,
                                                top: 0,
                                                justifyContent: "center",
                                                width: { xs: "90vw", sm: "auto" },
                                                position: { sm: 'absolute' }
                                            }}
                                        />
                                    </Box>
                                )
                            }}
                            disableColumnMenu
                            pageSizeOptions={[5, 10, 15, 30]}
                            disableRowSelectionOnClick
                            onCellClick={(param) => {
                                if (!window.getSelection()?.toString())
                                    handleCellClick(param);
                            }}
                            sx={{
                                ...DataGridStyle,
                                cursor: 'pointer',
                                height: getHeight(),
                                maxHeight: getMaxHeight(),
                            }}
                        />
                    </Box>

                </Card>

                <Typography sx={{ textAlign: 'right', mt: 3, mr: 1 }} fontSize={'13px'}>
                    {`${translate('containers.messages.quantityAdvice')}`}
                </Typography>

            </Container>
        </Page>
    );
}

type OptionsComponentProps = {
    openMenu: HTMLElement | null,
    handleOpenMenu: (event: React.MouseEvent<HTMLElement>) => void,
    handleCloseMenu: () => void,
    object: any,
    currentRow: any,
    handleEnableDisable?: (id: string, action: "Enable" | "Disable") => Promise<void>,
    isTemplate?: boolean,
    handleCloseShip?: (id: string, action: "Close" | "Ship") => void,
    handleOpen?: (id: string) => Promise<void>,
    handleLogs: (id: string) => void,
    setEdit: (id: string) => void,
    openModal: (open: boolean) => void
};

function OptionsComponent({ openMenu, handleOpenMenu, handleCloseMenu, object, currentRow, handleEnableDisable, isTemplate, handleOpen, setEdit, openModal, handleCloseShip, handleLogs }: OptionsComponentProps) {

    const { translate } = useLocales();

    return (
        <TableMoreMenu
            showMenu={currentRow && object.id === currentRow.id}
            open={openMenu}
            onOpen={(event) => handleOpenMenu(event)}
            onClose={() => handleCloseMenu()}
            actions={
                <>
                    {isTemplate ?
                        <>
                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Template_EnableDisable]}>
                                <MenuItem
                                    onClick={() => {
                                        if (handleEnableDisable) {
                                            if (object.row.enabled) handleEnableDisable(object.id, "Disable");
                                            else handleEnableDisable(object.id, "Enable");
                                        }
                                        handleCloseMenu();
                                    }}
                                    sx={{ color: object.row.enabled ? 'error.main' : 'success.main' }}
                                >
                                    {!object.row.enabled ? `${translate("commons.enable")}` : `${translate("commons.disable")}`}
                                </MenuItem>
                            </PermissionBasedGuard>

                            {(!object.row.active && object.row.enabled) &&
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Open]}>
                                    <MenuItem
                                        onClick={() => {
                                            if (handleOpen) handleOpen(object.id);
                                            handleCloseMenu();
                                        }}
                                        sx={{ color: 'success.main' }}
                                    >
                                        {`${translate("containers.list.activate")}`}
                                    </MenuItem>
                                </PermissionBasedGuard>
                            }

                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Template_Edit]}>
                                <MenuItem
                                    onClick={() => {
                                        setEdit(object.row.id);
                                        openModal(true);
                                        handleCloseMenu();
                                    }}
                                >
                                    {`${translate("commons.edit")}`}
                                </MenuItem>
                            </PermissionBasedGuard>
                        </>
                        :
                        <>
                            {(object.row.createdOn && !object.row.closedOn) &&
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Close]}>
                                    <MenuItem
                                        onClick={() => {
                                            if (handleCloseShip) handleCloseShip(object.id, "Close");
                                            handleCloseMenu();
                                        }}
                                        sx={{ color: 'error.main' }}
                                    >
                                        {`${translate("commons.close")}`}
                                    </MenuItem>
                                </PermissionBasedGuard>
                            }

                            {(object.row.closedOn && !object.row.shippedOn) &&
                                <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_Ship]}>
                                    <MenuItem
                                        onClick={() => {
                                            if (handleCloseShip) handleCloseShip(object.id, "Ship");
                                            handleCloseMenu();
                                        }}
                                        sx={{ color: 'warning.main' }}
                                    >
                                        {`${translate("containers.detail.ship")}`}
                                    </MenuItem>
                                </PermissionBasedGuard>
                            }

                            <PermissionBasedGuard permissions={[OrganizationPermissionTypes.Shipping_Container_ViewLogs]} >
                                <MenuItem
                                    onClick={() => {
                                        handleLogs(object.row.id);
                                        handleCloseMenu();
                                    }}
                                >
                                    {`${translate("commons.logs")}`}
                                </MenuItem>
                            </PermissionBasedGuard>
                        </>
                    }
                </>
            }
        />
    );
}