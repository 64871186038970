import { Box, Button, Card, FormControlLabel, Grid, Switch, TextField, Typography } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Dispatch, SetStateAction, useState } from "react";
import { UploadAvatar } from "src/components/upload";
import { useLocales } from "src/locales";
import { fData } from "src/utils/formatNumber";
import SaveIcon from '@mui/icons-material/Save';
import RichEditor from "src/components/richEditor/RichEditor";
import BoxSwitch from "src/components/custom-input/BoxSwitch";

interface DetailStepProps {
    changeStep: Dispatch<SetStateAction<number>>
}

export default function DetailStep({ changeStep }: DetailStepProps) {

    const { translate } = useLocales();

    const [editorState, setEditorState] = useState<string>();

    return (
        <Grid container gap={5}>
            <Grid item lg={8}>
                <Grid container  >
                    <Grid item gap={3} lg={12}>
                        <Details />
                        <Duration />
                        <Card sx={{ p: 3, mt: 3, width: '100%' }}>
                            <BoxSwitch label="Allow backorder" />
                        </Card>

                        <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
                            <Typography variant="h6">
                                Internal note (optional)
                            </Typography>
                            <Box sx={{ width: '100%' }}>

                                <Typography variant="subtitle2" color="GrayText" sx={{ mb: 1 }}> Only for Hub Operator, Sales Agents, and Back Office Operators</Typography>
                                {/* <RichEditor /> */}
                                <RichEditor sx={{ p: 2 }} value={editorState} onChange={(v) => setEditorState(v)} />
                                {/* <TextField multiline rows={4} fullWidth /> */}
                            </Box>
                        </Card>

                    </Grid>
                </Grid>
            </Grid>
            <Grid item  >
                <Box sx={{ position: 'sticky', top: 70 }}>
                    <Card sx={{ width: '100%', p: 3, textAlign: 'center' }} elevation={15}>
                        <Box>
                            <UploadAvatar helperText={
                                <Box sx={{ display: 'flex', flexDirection: 'column', my: 2 }}>
                                    <Typography variant="subtitle1">Cover Photo</Typography>
                                    <Typography
                                        variant="caption"
                                        sx={{
                                            mt: 2,
                                            mx: 'auto',
                                            display: 'block',
                                            textAlign: 'center',
                                            color: 'text.secondary',
                                        }}
                                    >
                                        {`${translate("user.form.allowed")} *.jpeg, *.jpg, *.png, *.gif`}
                                        <br /> {`${translate("user.form.maxSize")}`} {fData(3145728)}
                                    </Typography>
                                </Box>
                            } />
                        </Box>
                        <FormControlLabel
                            labelPlacement="start"
                            label="Prioritize Webshop"
                            control={<Switch />}
                        />
                    </Card>
                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                        <Button variant="outlined" sx={{ borderRadius: '100px' }} startIcon={<SaveIcon />}>
                            Save draft
                        </Button>
                        <Button variant="contained" sx={{ borderRadius: '100px' }} onClick={() => changeStep(prev => prev + 1)}>
                            Next
                        </Button>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    );
}

function Details() {
    return (
        <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3 }}>
            <Typography variant="h6">
                Details
            </Typography>
            <TextField label="webshop title" />
            <Box>
                <Typography sx={{ mb: 1 }} variant="subtitle2" color={"GrayText"}>Short Description</Typography>
                <RichEditor sx={{ px: 2, height: '10vh' }} />
            </Box>
            {/* <TextField label="short description" multiline rows={2} /> */}
            <Box>
                <Typography sx={{ mb: 1 }} variant="subtitle2" color={"GrayText"}>long Description</Typography>
                <RichEditor sx={{ px: 2, height: '20vh' }} />
            </Box>
            {/* <TextField label="long description" multiline rows={4} /> */}
        </Card>
    );
}

function Duration() {
    const { translate } = useLocales();

    return (
        <Card sx={{ p: 3, display: 'flex', flexDirection: 'column', gap: 3, mt: 3 }}>
            <Typography variant="h6">Duration</Typography>
            <Box sx={{ width: '100%' }}>
                <Typography variant="subtitle1">Preview</Typography>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', gap: 3 }}>
                    <DateTimePicker label='Start date and time' sx={{ mt: 2, width: '100%' }} />
                </Box>
            </Box>
            <Box>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="subtitle1">publishing</Typography>
                    <FormControlLabel
                        label={`${translate('permanent')}`}
                        control={<Switch />}
                    />
                </Box>
                <Box sx={{ display: 'flex', gap: 3, mt: 2 }}>
                    <DateTimePicker label='Start date and time' sx={{ width: '100%' }} />
                    <DateTimePicker label='end date and time' sx={{ width: '100%' }} />
                </Box>
            </Box>
        </Card>
    );
}

