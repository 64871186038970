import {
  Box,
  Card,
  IconButton,
  InputAdornment,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { useState, useEffect, useRef } from 'react';
import { useDropzone } from 'react-dropzone';
import { RequestNew } from 'src/@types/request';
import { useLocales } from 'src/locales';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { OrganizationSearchResult } from 'src/@types/organizations';
import { DeliveryAddressAutocomplete } from './DetailNewParts';
import { CancelRounded } from '@mui/icons-material';

interface DetailQualityProps {
  request: RequestNew;
  onChange: (name: string, value: any, item?: boolean) => void;
  available: number;
  defaultAddress: OrganizationSearchResult;
  images: File[],
  organization: string,
  isVendor: boolean
}

export default function DetailQuality({
  request,
  onChange,
  available,
  defaultAddress,
  images,
  organization,
  isVendor
}: DetailQualityProps) {
  // const { isItemsLoading, requestItems } = useSelector((state: RootState) => state.request);

  const { translate } = useLocales();

  const [quantity, setQuantity] = useState<number>(request.items[0].quantity);

  const [bigAndHeavy, setBigAndHeavy] = useState(request.items[0].bigAndHeavy);

  const [isImage, setIsImage] = useState(false);

  const [files, setFiles] = useState<File[]>(images);

  const [note, setNote] = useState(request.note);

  const [noteError, setNoteError] = useState("");

  useEffect(() => {

    onChange("images", files);

    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    // return () => imagesUrls.forEach((file) => URL.revokeObjectURL(file));
  }, [files]);

  return (

    <Card sx={{ my: 2, width: '100%', flexDirection: 'column', gap: 2, display: 'flex', p: 2 }}>
      <Typography variant="subtitle1">{`${translate('request.form.complete')}`}</Typography>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('commons.quantity')}`} <span style={{ color: 'red' }}>*</span></Typography>
          <Typography variant="body2">{`${translate('request.selectQty')}`}</Typography>
        </Stack>
        <Box
          sx={{
            m: 2,
            ml: 'auto',
            mr: 2,
            display: 'flex',
            justifyContent: 'end',
            alignItems: 'center',
          }}
        >
          <Typography color={'text.secondary'} variant="caption" sx={{ pr: 2 }}>
            {`${translate('statuses.available')}`}: {available}
          </Typography>
          <TextField
            sx={{ width: '40%' }}
            type="number"
            value={quantity}
            onChange={(e) => {
              setQuantity(+e.target.value < 0 ? 0 : +e.target.value);
              onChange('quantity', +e.target.value < 0 ? 0 : +e.target.value, true);
            }}
            inputProps={{ style: { textAlign: 'center' } }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    onClick={() => {
                      setQuantity((prev) => (prev > 0 ? prev - 1 : 0));
                      onChange('quantity', quantity > 0 ? quantity - 1 : 0, true);
                    }}
                  >
                    <RemoveIcon />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => {
                      setQuantity((prev) => (prev < available ? prev + 1 : available));
                      onChange('quantity', quantity < available ? quantity + 1 : available, true);
                    }}
                  >
                    <AddIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      </Box>
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: '#E2E2E2',
        }}
      >
        <Stack sx={{ m: 2, mr: 'auto' }}>
          <Typography variant="h6">{`${translate('request.big')}`}</Typography>
          <Typography variant="body2">{`${translate('request.messages.bigDesc')}`}</Typography>
        </Stack>
        <Box sx={{ m: 2, ml: 'auto' }}>
          <Switch
            name="bigAndHeavy"
            checked={bigAndHeavy}
            onChange={(e) => {
              onChange(e.target.name, e.target.checked, true);
              setBigAndHeavy(e.target.checked);
            }}
          />
        </Box>
      </Box>
      {request.requestReason === 'ThePartDoesntWork' && (
        <Box>
          <Box>
            <Typography variant="h6">{`${translate('request.form.whyNotWork')}`} <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              name="note"
              variant="outlined"
              rows={4}
              value={note}
              multiline
              error={!!noteError}
              onBlur={(e) => setNoteError(e.target.value.length === 0 ? `${translate('commons.validation.requiredField')}` : '')}
              helperText={noteError}
              required
              fullWidth
              type="text"
              placeholder={`${translate('commons.insertDesc')}`}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
                setNote(e.target.value);
              }}
            />
          </Box>
          <Box
            sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', width: '100%' }}
          >
            <Stack sx={{ m: 2, mr: 'auto' }}>
              <Typography variant="h6">{`${translate('request.form.issueVisible')}`}</Typography>
              <Typography variant="body2">{`${translate('request.form.addPic')}`}</Typography>
            </Stack>
            <Box sx={{ m: 2, ml: 'auto' }}>
              <Switch
                checked={isImage}
                name="damage"
                onChange={(e) => setIsImage(e.target.checked)}
              />
            </Box>
          </Box>
          {isImage && (
            <Box
              sx={{
                alignItems: 'start',
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
              }}
            >
              <UploadComponent files={files} setFiles={setFiles} />
            </Box>
          )}
        </Box>
      )}
      {request.requestReason === 'OriginalPackagingContainsWrongParts' && (
        <Box>
          <Box>
            <Typography sx={{ my: 2 }} variant="h6">{`${translate('request.messages.wrongPartAndDesc')}`} <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              name="note"
              rows={4}
              value={note}
              multiline
              error={!!noteError}
              onBlur={(e) => setNoteError(e.target.value.length === 0 ? `${translate('commons.validation.requiredField')}` : '')}
              helperText={noteError}
              fullWidth
              type="text"
              placeholder={`${translate('commons.insertDesc')}`}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
                setNote(e.target.value);
              }}
            />
          </Box>
          <Box
            sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <Stack sx={{ m: 2, mr: 'auto' }}>
              <Typography variant="h6">{`${translate('request.form.partPicture')}`}</Typography>
              <Typography variant="body2">{`${translate('request.form.pictureLabel')}`}</Typography>
            </Stack>
            <UploadComponent files={files} setFiles={setFiles} />
          </Box>
        </Box>
      )}
      {request.requestReason === 'PartOrderedIsNotComplete' && (
        <Box>
          <Box>
            <Typography sx={{ my: 2 }} variant="h6">{`${translate('request.form.partDescription')}`} <span style={{ color: 'red' }}>*</span></Typography>
            <TextField
              variant="outlined"
              name="note"
              rows={4}
              value={note}
              multiline
              error={!!noteError}
              onBlur={(e) => setNoteError(e.target.value.length === 0 ? `${translate('commons.validation.requiredField')}` : '')}
              helperText={noteError}
              fullWidth
              type="text"
              placeholder={`${translate('commons.insertDesc')}`}
              onChange={(e) => {
                onChange(e.target.name, e.target.value);
                setNote(e.target.value);
              }}
            />
          </Box>
          <Box
            sx={{ alignItems: 'start', display: 'flex', flexDirection: 'column', width: '100%' }}
          >
            <Stack sx={{ m: 2, mr: 'auto' }}>
              <Typography variant="h6">{`${translate('request.form.pictureAll')}`}</Typography>
              <Typography variant="body2">{`${translate('request.form.addPictureAll')}`}</Typography>
            </Stack>
            <UploadComponent files={files} setFiles={setFiles} />
          </Box>
        </Box>
      )}
      <Box>
        <DeliveryAddressAutocomplete onChange={onChange} defaultAddress={defaultAddress} required organization={organization} isVendor={isVendor} />
      </Box>
    </Card>
  );
}

interface UploadComponentProps {
  files: File[]
  setFiles: (files: any) => void,
}

function UploadComponent({ files, setFiles }: UploadComponentProps) {

  const ref = useRef<HTMLDivElement>(null);

  const { translate } = useLocales();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setFiles((prev: File[]) => prev.concat(acceptedFiles));
    },
    accept: {
      'image/png': ['.png'],
      'image/jpeg': [".jpg", ".jpeg"]
    },
    maxSize: 5000000
  });

  useEffect(() => {

    ref.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center'
    });

  }, [files]);

  return (
    <Box sx={{ m: 2, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 3, overflowX: 'auto', width: '100%' }}>
      {files.map((f, ind) => {

        const url = URL.createObjectURL(f);

        return (
          <Box key={ind} sx={{ position: 'relative', borderRadius: '10px' }}>
            <img
              style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '10px', border: '1px ridge', borderColor: '#d3d4d4' }}
              alt={f.name}
              src={url}
              onLoad={() => URL.revokeObjectURL(url)}
              onError={() => URL.revokeObjectURL(url)}
            />
            <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
              <IconButton
                aria-label="delete"
                size="large"
                sx={{
                  padding: '5px',
                  cursor: "pointer"
                }}
                onClick={() => {
                  setFiles((prev: File[]) => prev.filter((_, index) => ind !== index));
                }}
              >
                <CancelRounded sx={{ color: 'text.disabled' }} />
              </IconButton>
            </Box>
          </Box>
        );
      })}
      <div
        {...getRootProps({
          style: {
            display: 'flex',
            padding: '20px',
            height: '150px',
            width: '150px',
            borderRadius: '8px',
            background: '#F4F6F8',
            color: '#bdbdbd',
            minWidth: '150px',
            minHeight: '150px',
            outline: 'none',
            cursor: 'pointer',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          },
        })}
      >
        <input {...getInputProps()} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <AddAPhotoIcon />
          <Typography ref={ref} variant="caption">{`${translate(
            'commons.uploadPhoto'
          )}`}</Typography>
        </Box>
      </div>
    </Box>
  );

}