export const DataGridStyle = {
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
        width: '8px',
        height: '0.5em',
        backgroundColor: 'transparent',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-track': {
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        borderRadius: '4px',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
        borderRadius: '50px',
        backgroundColor: 'rgba(0, 0, 0, 0.20)',
        opacity: ' 0.20',
    },
    '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
        opacity: '0.8',
    },
    width: '100%',
    border: '0px',
    '& .MuiDataGrid-cell': {
        borderBottom: '0px',
        padding: '8px',
        cursor: 'pointer',
    },
    '& .MuiDataGrid-columnHeader, .MuiDataGrid-cell': {
        display: 'flex',
        alignItems: 'center'
    },
    '& .MuiDataGrid-columnHeader': {
        fontWeight: 'bold',
        backgroundColor: 'background.neutral',
        border: 'none',
        borderColor: '#00000'
    },
    '& .MuiDataGrid-columnHeaders': {
        backgroundColor: 'background.neutral',
    },
    '& .MuiDataGrid-columnHeader:focus ': {
        fontWeight: 'bold',
        backgroundColor: 'background.neutral',
        borderColor: '#00000',
        outline: 'none',
    },
    '&  .MuiDataGrid-columnSeparator': {
        color: 'background.neutral'
    },
    '& .MuiDataGrid-cell:focus-within': {
        outline: 'none',
    },
    '--unstable_DataGrid-radius': '0px'
};