import { Tooltip, Button, MenuItem, Checkbox, Typography, Stack } from "@mui/material";
import { GridColumnVisibilityModel } from "@mui/x-data-grid";
import { useState } from "react";
import MenuPopover from "src/components/menu-popover";
import SvgColor from "src/components/svg-color";
import { useLocales } from "src/locales";

interface VisibilityModelComponentProps {
    model: GridColumnVisibilityModel
    onChangeModel: (model: GridColumnVisibilityModel) => void
}

export default function VisibilityModelComponent({ model, onChangeModel }: VisibilityModelComponentProps) {

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

    const { translate } = useLocales();

    return (
        <>
            <Tooltip title={`${translate('user.messages.editColumns')}`}>
                <Button
                    startIcon={<SvgColor src={'/assets/icons/components/ic_edit_columns.svg'} sx={{ ml: 1.5 }} />}
                    onClick={(e) => setOpenPopover(e.currentTarget)}
                    sx={{ mt: 1 }}
                    size='large'
                />
            </Tooltip>
            <MenuPopover
                open={openPopover}
                onClose={() => setOpenPopover(null)}
                sx={{ maxHeight: '40vh', overflowY: 'auto' }}
            >
                <Stack>
                    {Object.entries(model).map(([key, val]) => (
                        <MenuItem
                            key={key}
                            onClick={() => onChangeModel({ ...model, [key]: !val })}
                            sx={{ display: 'flex', px: 3 }}
                        >
                            <Checkbox disableRipple checked={val} />
                            <Typography sx={{ mr: 2 }} variant='body2'>{key}</Typography>
                        </MenuItem>
                    ))}
                </Stack>
            </MenuPopover>
        </>
    );
}