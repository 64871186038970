import { OrderAmount } from "./orders";

export const OutputTypeArr = ["", "Pdf", "Excel", "Csv"] as const;

export type OutputType = typeof OutputTypeArr[number];

export const RecurringTypeArr = ["", "Daily", "Weekly", "Monthly", "MonthToDay"] as const;

export type RecurringType = typeof RecurringTypeArr[number];

export const ReportTypeDataArr = ["", "ReturnRequest", "container", "document"] as const;

export type ReportTypeData = typeof ReportTypeDataArr[number];

//-----------------FILTERS PER TYPE-----------------//

export type RequestReportFilters = {
    [key: string]: any
    tags: string[] | null,
    customFields: Record<string, string> | null,
    all: string | null,
    externalId: string | null,
    requestType: string | null,
    customerId: string | null,
    vendorId: string | null,
    status: string[]
}

export type InvoiceReportFilters = {
    [key: string]: any,
    tags: string[] | null
    customFields: Record<string, string> | null,
    all: string | null,
    externalId: string | null,
    customerId: string | null,
    vendorId: string | null,
    minAmount: OrderAmount | null,
    maxAmount: OrderAmount | null
}
//-----------------REPORT STUFF-----------------//

export type Fields = {
    id: string,
    isCustomField: boolean
}

export type ReportType = {
    id: string,
    name: string,
    description: string,
    outputType: OutputType,
    fields: Fields[],
    enabled: boolean,
    filter: RequestReportFilters,
    from: string,
    to: string,
    recurringType: RecurringType,
    additionalRecipients: string[],
    createdOn: string,
    lastExecution: string,
    reportType: ReportTypeData,
    hasError?: boolean,
    downloadUri?: string,
    overrideCulture?: string
}

export type ReportTemplate = {
    name: string,
    description: string,
    fields: Fields[]
    filter: any
}

export type NewReportScheduled = {
    overrideCulture?: string
    name: string,
    description: string,
    outputType: OutputType,
    fields: Fields[],
    filter?: RequestReportFilters,
    from: string | null,
    to: string | null,
    recurringType?: RecurringType,
    additionalRecipients: string[],
    containerId?: string
}

export type NewReportRecurring = {
    overrideCulture?: string
    name: string,
    description: string,
    outputType: OutputType,
    fields: Fields[],
    enabled: boolean,
    recurringType: RecurringType,
    filter?: RequestReportFilters,
    additionalRecipients: string[]
    from?: string,
    to?: string,
    containerId?: string
}

export type ReportSearchResult = {
    [key: string]: any
    id: string,
    name: string,
    outputType: OutputType,
    recurringType?: RecurringType,
    enabled: boolean,
    lastExecution: Date,
    nextExecution: Date,
    createdOn: Date,
    reportType: ReportTypeData,
    hasError: boolean,
    downloadUri: string
}

export type ReportFilters = {
    [key: string]: any,
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortAscending?: boolean,
    name?: string,
    outputType?: "All" | OutputType,
    recurringType?: "All" | RecurringType,
    reportType?: "All" | ReportTypeData
}

export type ReportDownloadSearchResult = {
    id: string,
    name: string
    reportDate: string,
    outputType: OutputType,
    size: number,
    link: string
}

export type ExecutionFilter = {
    pageIndex: number,
    pageSize: number,
    sortField?: string,
    sortAscending?: boolean,
}

export type ExecutionSearchResult = {
    lastExecution: string,
    hasError: boolean,
    downloadUri: string,
    tabIndex: number
}

export type ReportDetailTabs = "general" | "download";

//----------------------------------------------------------------

export const DEFAULT_REPORT_FILTERS: ReportFilters = {
    pageIndex: 0,
    pageSize: 10,
    sortField: "",
    sortAscending: false,
    name: "",
    outputType: "Excel", //"All",
    recurringType: "All",
    reportType: "All"
};

export const DEFAULT_REQUEST_REPORT_FITLERS: RequestReportFilters = {
    all: null,
    customerId: null,
    customFields: null,
    externalId: null,
    requestType: null,
    vendorId: null,
    status: [],
    tags: []
};

export const DEFAULT_NEW_REPORT_SCHEDULED: NewReportScheduled = {
    fields: [],
    description: "",
    filter: DEFAULT_REQUEST_REPORT_FITLERS,
    from: "",
    outputType: "Excel",
    name: "",
    to: "",
    additionalRecipients: [],
    overrideCulture: ""
};

export const DEFAULT_NEW_REPORT_RECURRING: NewReportRecurring = {
    fields: [],
    description: "",
    enabled: true,
    filter: DEFAULT_REQUEST_REPORT_FITLERS,
    outputType: "Excel",
    name: "",
    recurringType: "Daily",
    additionalRecipients: [],
    overrideCulture: ""
};