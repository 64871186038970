import { Box, Card, Divider, FormControlLabel, Grid, Radio, Stack, TextField, Theme, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import GridOnIcon from '@mui/icons-material/GridOn';
import BoxSwitch from "src/components/custom-input/BoxSwitch";
import StepButtons from "src/components/buttons/StepButtons";

interface DiscountModelStepProps {
    changeStep: Dispatch<SetStateAction<number>>
}

export default function DiscountModelStep({ changeStep }: DiscountModelStepProps) {
    // const { translate } = useLocales();

    const [button, setButton] = useState<"" | "FixedPrice" | "MatrixDiscount">("");

    return (
        <Card sx={{ p: 3 }}>
            <Box>
                <Typography variant="h6">
                    Discount Model
                </Typography>
                <ToggleButtonGroup
                    sx={{
                        gridTemplateColumns: '1fr 1fr',
                        display: 'grid',
                        border: 'none',
                        m: 2,
                        gap: 3,
                    }}
                    onChange={(_, v) => setButton(v)}
                    value={button}
                    exclusive
                    size="large"
                    color="primary"
                >
                    <ToggleButton
                        value={"FixedPrice"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <DoNotDisturbAltIcon />
                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {`Fixed Price`}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                    <ToggleButton
                        value={"MatrixDiscount"}
                        sx={{
                            outlineWidth: '1px',
                            outlineStyle: 'solid',
                            ml: '-1px !important',
                            outlineColor: '#E2E2E2',
                            p: 3
                        }}
                    >
                        <Grid item xs={4}>
                            <Stack
                                sx={{
                                    alignContent: 'center',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <GridOnIcon />
                                <Typography sx={{ mt: 1 }} variant="subtitle2">
                                    {`Matrix Discount`}
                                </Typography>
                            </Stack>
                        </Grid>
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {button === "FixedPrice" && <FixedPrice />}
            {button === "MatrixDiscount" && <MatrixDiscount />}
            <Divider sx={{ my: 3 }} />
            <StepButtons changeStep={changeStep} />
        </Card>
    );
}

const boxStyle = {
    p: 3,
    width: '100%',
    border: "1px solid #E2E2E2",
    borderRadius: '8px',
    ":focus-within": {
        borderColor: (theme: Theme) => theme.palette.primary.main
    }
};

function FixedPrice() {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
            <Typography variant="subtitle2">Select an option</Typography>
            <Box sx={boxStyle} >
                <BoxSwitch label="Fixed Prices only" />
            </Box>
            <Box sx={boxStyle}>
                <BoxSwitch label="Fixed Prices + Multiple Level Discount single order" />
            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch label="Fixed Prices + Multiple Level Discount multiple orders" />
            </Box>
        </Box>
    );
}

function MatrixDiscount() {

    const [show, setShow] = useState(false);

    const [radio, setRadio] = useState<number | null>(null);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 3 }}>
            <Typography variant="subtitle2">Select an option</Typography>
            <Box sx={boxStyle} >
                <BoxSwitch label="Only Discount on gross price" subtitle="No additional discount on the net price" />
            </Box>
            <Box sx={boxStyle}>
                <BoxSwitch
                    label="Matrix Discount + Flat"
                    subtitle="the same additional discount is applied to all the part references within the webshop"
                    value={show}
                    onChange={(v) => setShow(v)}
                />
                {show &&
                    <Box sx={{ my: 2, display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', justifyItems: 'center' }}>
                            <FormControlLabel
                                label={<span>Additional discount calculated on <b>net price</b></span>}
                                onChange={() => setRadio(0)}
                                control={<Radio checked={radio === 0} />}
                            />
                            <TextField
                                disabled={radio !== 0}
                                label={'Additional discount...'}
                                sx={{
                                    width: '30%',
                                    backgroundColor: (theme) => radio !== 0 ? theme.palette.action.disabledBackground : undefined,
                                    borderRadius: '8px'
                                }} />
                        </Box>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', justifyItems: 'center' }}>
                            <FormControlLabel
                                label={<span>Additional discount calculated on <b>gross price</b></span>}
                                onChange={() => setRadio(1)}
                                control={<Radio checked={radio === 1} />}
                            />
                            <TextField
                                disabled={radio !== 1}
                                label={'Additional discount...'}
                                sx={{
                                    width: '30%',
                                    backgroundColor: (theme) => radio !== 1 ? theme.palette.action.disabledBackground : undefined,
                                    borderRadius: '8px'
                                }} />
                        </Box>
                    </Box>
                }
            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch label="Matrix Discount + Flexible" subtitle="additional discounts applied to webshop part references based on discount matrix additional discount column" />

            </Box>
            <Box sx={boxStyle} >
                <BoxSwitch label="Matrix Discount + Multiple Level Discount single order Application" />
            </Box>
        </Box>
    );
}