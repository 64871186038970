import { Autocomplete, Box, Card, Divider, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { GreyCounter } from "./CustomerStep";
import RichEditor from "src/components/richEditor/RichEditor";
import StepButtons from "src/components/buttons/StepButtons";

interface TermsConditionStepProps {
    changeStep: Dispatch<SetStateAction<number>>
}

export default function TermsConditionStep({ changeStep }: TermsConditionStepProps) {

    //const { translate } = useLocales();

    const [manualTerms, setManualTerms] = useState(false);

    return (
        <Card sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" >Terms and conditions</Typography>
                <FormControlLabel
                    label={'Inserisci manualmentee'}
                    onChange={(_, c) => setManualTerms(c)}
                    control={<Switch value={manualTerms} />}
                />
            </Box>
            {!manualTerms && <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
                <Typography variant="subtitle2">Select template</Typography>
                <Autocomplete options={[]} renderInput={(params) => <TextField {...params} label={'Search Template Name'} />} />
                <GreyCounter label="No Template selected" />
            </Box>}
            {
                manualTerms &&
                <Box sx={{ mt: 2 }}>
                    <RichEditor sx={{ p: 2 }} />
                </Box>
            }
            <Divider sx={{ my: 3 }} />
            <StepButtons changeStep={changeStep} confirm />
        </Card>
    );
}