import { useAuth } from "src/appHooks/useAuth";
import connection from 'src/signalr-connection';
import { Notification } from 'src/services/paths';
import { enqueueSnackbar } from "notistack";
import { DataImportCompletedPayload, GroupMembershipUpdateCompletedPayload, ReportPayload, ReturnRequestStatusChangedPayload } from "src/@types/notification";
import { PATH_DASHBOARD } from "src/routes/paths";
import { useCallback, useEffect, useState } from "react";
import { Typography } from "@mui/material";
import { useLocales } from "src/locales";
import { ReportTypeData } from "src/@types/report";
import { dispatch } from "src/redux/store";
import { incrementUnread } from "src/redux/inbox/inbox-slices";
import { hasPermissions } from "src/utils/user";
import { OrganizationPermissionTypes } from "src/@types/permissions";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";

type ConnectionStorage = {
    inbox: connection,
    report: connection,
    returnRequest: connection,
    group: connection
}

enum targets {
    NEW_REPORT = "newReportGenerationCompleted",
    NEW_MESSAGE = "newInboxMessage",
    GROUP_UPDATED = "groupMembershipUpdateCompleted",
    DATA_IMPORT_COMPLETED = "dataImportCompleted",
    RETURN_REQUEST_STATUS_CHANGED = "returnRequestStatusChanged"
}

export default function NotificationHandler() {
    const { getAccessToken, isAuthenticated } = useAuth();

    const { organization } = useUserOrganizationContext();

    const { translate } = useLocales();

    const [connections, setConnections] = useState<ConnectionStorage>();

    const initialize = useCallback(() => {
        if (isAuthenticated)
            setConnections({

                inbox: new connection(Notification.public.sockets.inbox(), getAccessToken),
                report: new connection(Notification.public.sockets.report(), getAccessToken),
                returnRequest: new connection(Notification.public.sockets.returnRequest(), getAccessToken),
                group: new connection(Notification.public.sockets.group(), getAccessToken)

            });

    }, [getAccessToken, isAuthenticated]);

    //EVENT HANDLERS---------------------------------

    const reportEvent = useCallback((payload: ReportPayload) => {

        enqueueSnackbar(

            <Typography style={{ whiteSpace: 'pre-line' }}>
                {`${translate('report.detail.title')}`}: {payload.reportName} <span><br /></span>
                {`${translate('commons.status')}`}: {`${translate(`commons.${payload.status}`)}`}
            </Typography>,

            {
                autoHideDuration: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                variant: 'NotificationSnackbar',
                link: payload.reportCategory === "Scheduled" ?
                    PATH_DASHBOARD.reports.scheduled.detail(payload.reportId, "general", payload.reportType as ReportTypeData) :
                    PATH_DASHBOARD.reports.recurring.detail(payload.reportId, "general", payload.reportType as ReportTypeData),
            });

    }, [translate]);

    const groupUpdatedEvent = useCallback((payload: GroupMembershipUpdateCompletedPayload) => {

        enqueueSnackbar(

            <Typography style={{ whiteSpace: 'pre-line' }}>
                {`${translate('groups.detail.title')}`}: {payload.groupName} <span><br /></span>
                {translate('groups.messages.updated')}
            </Typography>,

            {
                autoHideDuration: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                variant: 'NotificationSnackbar',
                link: PATH_DASHBOARD.groups.detailsTab(payload.groupId, 'properties-permissions')
            });

    }, [translate]);

    const requestChangeStatusEvent = useCallback((payload: ReturnRequestStatusChangedPayload) => {

        let link: string | undefined = undefined;

        if (organization) {

            if (hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_VendorView], organization.roles))
                link = PATH_DASHBOARD.request.vendor.backlogDetail(payload.requestId);

            else if (hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CustomerView], organization.roles))
                link = PATH_DASHBOARD.request.customer.detail(payload.requestId);

            else if (hasPermissions([OrganizationPermissionTypes.WebShop_ReturnRequests_Requests_CarrierView], organization.roles))
                link = PATH_DASHBOARD.request.carrier.backlogDetail(payload.requestId);

        }

        enqueueSnackbar(

            <Typography style={{ whiteSpace: 'pre-line' }}>
                {`${translate('notifications.settings.returnRequest')}`}: {payload.prettyName} <span><br /></span>
                {translate('request.messages.changeStatus')} {translate(`commons.order.returnRequest.${payload.newStatus}`)}
            </Typography>,

            {
                autoHideDuration: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                variant: 'NotificationSnackbar',
                link: link
            });

    }, [translate, organization]);

    const dataImportEvent = (payload: DataImportCompletedPayload) => {

        enqueueSnackbar(

            <Typography style={{ whiteSpace: 'pre-line' }}>
                {payload.message}
            </Typography>,

            {
                autoHideDuration: null,
                anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                variant: 'NotificationSnackbar',
            });

    };

    const inboxEvent = () => {
        dispatch(incrementUnread());
    };

    useEffect(() => {

        if (!connections)
            initialize();

        else {

            connections.report.setEvents(targets.NEW_REPORT, reportEvent);
            connections.inbox.setEvents(targets.NEW_MESSAGE, inboxEvent);
            connections.group.setEvents(targets.GROUP_UPDATED, groupUpdatedEvent);
            connections.report.setEvents(targets.DATA_IMPORT_COMPLETED, dataImportEvent);
            connections.returnRequest.setEvents(targets.RETURN_REQUEST_STATUS_CHANGED, requestChangeStatusEvent);
        }
    }, [connections, groupUpdatedEvent, initialize, reportEvent, requestChangeStatusEvent]);

    return null;
}