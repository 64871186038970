import { Box, Checkbox, FormControlLabel, FormGroup, SxProps, Theme } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CustomField } from "src/@types/customField";
import RHFCurrencyTextField from "src/appComponents/RHFCurrencyTextField";
import useTenant from "src/appHooks/useTenant";
import { RHFSelect, RHFTextField } from "src/components/hook-form";
import RHFDatePicker from "src/components/hook-form/RHFDatePicker";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import { HandleCustomFieldsType } from "./CustomFieldManagment";
import useCurrency from "src/appHooks/useCurrency";
import { cloneDeep } from "lodash";
import { useFormContext } from "react-hook-form";

interface CustomFieldFormRendererProps {
    context: string,
    handleCustomFields: (params: HandleCustomFieldsType) => void,
    values: Record<string, string> | null,
    setErrorInCustom: (value: boolean) => void,
    useFromForm?: boolean,
    getValuesCustom?: any,
    setValueCustom?: any,
    onChangeValidate?: (field: any) => void,
    onEditForm?: boolean,
    setOnEditForm?: (value: boolean) => void,
    isDisabled?: (name: string, other?: boolean, and?: boolean) => boolean,
    section?: string[],
    columnGap?: number,
    rowGap?: number,
    repeat?: number | string,
    sx?: SxProps<Theme> | undefined,
}

export default function CustomFieldFormRenderer({
    onChangeValidate,
    onEditForm,
    setOnEditForm,
    context,
    columnGap,
    rowGap,
    repeat,
    handleCustomFields,
    values,
    isDisabled,
    section,
    setErrorInCustom,
    useFromForm = true,
    getValuesCustom,
    setValueCustom,
    sx
}: CustomFieldFormRendererProps) {

    const { getValues, setValue, setError, clearErrors } = useFormContext();

    const { currentLang } = useLocales();

    const { customFields } = useTenant();

    const { currency: currentCurrency } = useSettingsContext();

    const { rates } = useCurrency();

    const [field, setField] = useState<CustomField[]>([]);

    const [fieldsWithError, setFieldsWithError] = useState<CustomField[]>([]);

    const setErrorCustom = useCallback((field: string, error: { type: string, message: string }) => {
        setCustomFieldError(prev => ({ ...prev, [field.split(".")[1]]: error }));
    }, []);

    const clearErrorsCustom = useCallback((field: string) => {
        setCustomFieldError(prev => {
            let clone = cloneDeep(prev);

            delete clone[field];

            return clone;
        });
    }, []);

    const getValuesMemo = useMemo(() => useFromForm ? getValues : getValuesCustom, [getValuesCustom, getValues, useFromForm]);

    const setValueMemo = useMemo(() => useFromForm ? setValue : setValueCustom, [setValue, setValueCustom, useFromForm]);

    const setErrorMemo = useMemo(() => useFromForm ? setError : setErrorCustom, [setError, setErrorCustom, useFromForm]);

    const clearErrorsMemo = useMemo(() => useFromForm ? clearErrors : clearErrorsCustom, [clearErrorsCustom, clearErrors, useFromForm]);

    const contextedCustomfields = useMemo(() => {
        if (customFields) {
            return [...customFields.filter(x => x.enabled && x.entityType.toLowerCase() === context.toLowerCase())];
        }
    }, [customFields, context]);

    const [customFieldError, setCustomFieldError] = useState<Record<string, { type: string, message: string }>>({});

    const handleCallBack = (event: any, dateValue?: string, dateFieldName?: string) => {
        handleCustomFields({
            event,
            getValues: getValuesMemo,
            setValue: setValueMemo,
            setError: setErrorMemo,
            clearErrors: clearErrorsMemo,
            customFields: contextedCustomfields || [],
            fieldsWithError,
            setFieldsWithError,
            setErrorInCustom,
            dateValue,
            dateFieldName,
            currentCurrency,
            rates
        });
    };

    useEffect(() => {
        if (fieldsWithError.length === 0) {
            setErrorInCustom(false);
        }
    }, [fieldsWithError, setErrorInCustom]);

    useEffect(() => {

        if (customFields) {

            let customContext = [...customFields.filter(x => x.enabled && x.entityType.toLowerCase() === context.toLowerCase())];

            if (section) {
                customContext = [...customContext.filter(x => section.includes(x.section))];
            }

            if (customContext && customContext.length >= 1) {

                const fields = [...customContext.filter(x => {
                    if (x.type === "Checkbox") {
                        return x.required && getValuesMemo(`customFields.${x.id}`) === "disabled";
                    }

                    return x.required && !getValuesMemo(`customFields.${x.id}`);
                })];

                setFieldsWithError(fields);

                setErrorInCustom(fields.length >= 1);
            } else {
                setErrorInCustom(false);
            }

            setField(customContext.sort((a, b) => a.name[0].text!.toLowerCase().localeCompare(b.name[0].text!.toLowerCase())));
        }
    }, [customFields, values, setErrorInCustom, setFieldsWithError, context, section, getValuesMemo]);

    const getDefault = (field: CustomField) => {

        if (values && values[field.id]) {
            return values[field.id];
        }

        return field.defaultValue;
    };

    const getDefaultSelect = (field: CustomField) => {
        if (values && values[field.id]) {
            return values[field.id];
        }

        return field.selectOptions!.find(i => i.isDefault)?.values[0].text || '';
    };

    const checkDisabled = (name: string, other?: boolean) => {
        if (isDisabled) {
            return isDisabled(name, !!other);
        }

        return false;
    };

    const getHelperText = (field: CustomField) => {

        const { validationMessage, required, id } = field;

        if (required && !getValuesMemo(`customFields.${id}`)) {

            return "commons.validation.requiredField";
        }

        if (validationMessage && validationMessage.length > 0) {
            if (validationMessage.length === 1) {
                return validationMessage[0].text;
            }

            return validationMessage.find(localString => localString.culture === currentLang.value)?.text;
        }

        return "";
    };

    const getField = (field: CustomField, key: number) => {
        switch (field.type.toLowerCase()) {
            case 'string':
                return (
                    <RHFTextField
                        name={field.id}
                        key={field.id + key}
                        id={field.id}
                        onChangeVal={onChangeValidate}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        onChange={handleCallBack}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        error={!!(customFieldError[field.id])}
                        helperText={getHelperText(field)}
                        defaultValue={getDefault(field)}
                        disabled={checkDisabled(field.id)}
                    />
                );
            case 'number':
                return (
                    <RHFTextField
                        name={field.id}
                        id={field.id}
                        onChangeVal={onChangeValidate}
                        key={field.id + key}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        onChange={handleCallBack}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        error={!!(customFieldError[field.id])}
                        type="number"
                        helperText={getHelperText(field)}
                        defaultValue={getDefault(field)}
                        disabled={checkDisabled(field.id)}
                    />
                );
            case 'currency':
                return (
                    <RHFCurrencyTextField
                        name={field.id}
                        id={field.id}
                        key={field.id + key}
                        label={(field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text)}
                        currency={currentCurrency.label}
                        onChangeVal={onChangeValidate}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        onChange={handleCallBack}
                        required={field.required}
                        error={!!(customFieldError[field.id])}
                        helperText={getHelperText(field)}
                        defaultValue={getDefault(field)}
                        disabled={checkDisabled(field.id)}
                        isCustom={true}
                    />
                );
            case 'date':
                return (
                    <RHFDatePicker
                        name={field.id}
                        key={field.id + key}
                        id={field.id}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        onChangeVal={onChangeValidate}
                        onEditForm={onEditForm}
                        setOnEditForm={setOnEditForm}
                        required={field.required}
                        error={!!(customFieldError[field.id])}
                        helperText={getHelperText(field)}
                        defaultValue={getDefault(field) ? new Date(getDefault(field)) : null}
                        disabled={checkDisabled(field.id)}
                        onChangeDate={handleCallBack}
                    />
                );
            case 'checkbox':
                return (
                    <FormGroup key={key + ' form'}>
                        <FormControlLabel
                            name={field.id}
                            id={field.id}
                            key={field.id + key}
                            onBlur={() => {
                                if (setOnEditForm) {
                                    setOnEditForm(false);
                                }
                            }}
                            control={
                                <Checkbox
                                    required={field.required}
                                    checked={getDefault(field) === "enabled"}
                                    onChange={(e) => handleCallBack(e)}
                                />}
                            label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                            sx={{ ml: 0, '& .MuiFormControlLabel-asterisk': { color: 'error.main' } }}
                            disabled={checkDisabled(field.id)}
                        />
                    </FormGroup>
                );
            case 'select':
                return (
                    <RHFSelect
                        native
                        onEditForm={onEditForm} setOnEditForm={setOnEditForm}
                        name={field.id}
                        id={field.id}
                        onChangeVal={onChangeValidate}
                        onChange={handleCallBack}
                        error={!!(customFieldError[field.id])}
                        key={field.id + key}
                        label={field.name.find(x => x.culture === currentLang.value)?.text || field.name[0].text}
                        required={field.required}
                        defaultValue={getDefaultSelect(field)}
                        disabled={checkDisabled(field.id)}
                    >
                        <option value='' hidden />
                        {field.selectOptions && field.selectOptions.map((option, index) => (
                            <option
                                key={index + '  ' + key}
                                hidden={!!option.values.find(l => l.text === '')}
                                value={option.values[0].text || ''}>
                                {option.values.find(x => x.culture === currentLang.value)?.text || option.values[0].text || ''}
                            </option>)
                        )}

                    </RHFSelect>);
        }
    };

    const getRepeat = () => {
        if (isNaN(Number(repeat))) {
            return repeat;
        };

        return `repeat(${repeat ? repeat : 3}, 1fr)`;
    };

    return (
        <Box
            sx={{
                display: 'grid',
                columnGap: (columnGap ? columnGap : 2),
                rowGap: (rowGap ? rowGap : 3),
                gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: getRepeat() },
                ...sx
            }}
        >
            {field.map((f, ind) => (
                getField(f, ind)
            ))}
        </Box>
    );
}
