import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Tab, Tabs, Card, Box, FormControlLabel, Switch, TablePagination, Divider, Typography } from '@mui/material';
import { dispatch } from 'src/redux/store';
import useLocales from 'src/appHooks/useLocales';
import Label from 'src/components/label';
import useTable from 'src/appHooks/useTable';
import { useForm } from 'react-hook-form';
import { DataGrid, GridCellParams, GridColDef, GridColumnVisibilityModel, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import FormProvider from 'src/components/hook-form';
import { DataGridStyle } from 'src/utils/DataGridStyle';
import { noData } from 'src/components/empty-content/EmptyContent';
import { PagedResponseType, ToolbarSearchFilters } from 'src/@types/commons';
import { cloneDeep, isArray, isEqual, isObject, omitBy, remove } from 'lodash';
import useResponsive from 'src/hooks/useResponsive';
import useLocalStorage from 'src/hooks/useLocalStorage';
import GenericFilterSidebar from 'src/utils/list/sidebar/GenericFilterSidebar';
import { FilterListType, GenericListFilters, QuickFilters } from 'src/@types/list';
import GenericFilterSummary from 'src/utils/list/summary/GenericFilterSummary';
import GenericFilterToolbar from 'src/utils/list/toolbar/GenericFilterToolbar';
import { AsyncThunkAction } from '@reduxjs/toolkit';

const BASIC_FILTER_OPTIONS = {
    pageIndex: 0,
    pageSize: 10
};

type GenericListProps<T extends GridValidRowModel, Q extends GenericListFilters> = {
    pageIndex: number,
    pageSize: number,
    totalCount: number,
    list: T[],
    isLoading: boolean,
    defaultFilters: Q,
    statsKeysToDelete?: string[],
    fullKeysToDelete?: string[],
    quickFilters?: QuickFilters[],
    renderQuickFilters?: (key: string) => any,
    toolbarFiltersList?: ToolbarSearchFilters[],
    filtersInSidebar?: FilterListType[],
    updateCheckField: (field: string, filters: Q) => boolean,
    extraSearchFiltersChecks?: (searchFilters: Q) => Q,
    context: string,
    datagridColumns: GridColDef<T>[],
    setActualRow: Dispatch<any>,
    handleCellClick: (params: GridCellParams<any>) => void,
    setFiltersCallback?: Dispatch<SetStateAction<Q>>,
    filterStatus?: string,
    onChangeFilterStatus?: (event: React.SyntheticEvent<Element, Event> | null, newValue: string) => void,
    search?: (arg: any) => AsyncThunkAction<PagedResponseType<T>, any, any>,
    searchStatistics?: (arg: any) => AsyncThunkAction<any, any, any>,
    customSearchFunc?: (filtersFromUrl: Q) => void,
    filtersInUrl?: string,
    setFiltersInUrl?: any,
    listDescription: string,
    showDates?: boolean
}

export default function GenericList<T extends GridValidRowModel, Q extends GenericListFilters>({
    pageIndex,
    pageSize,
    totalCount,
    list,
    isLoading,
    defaultFilters,
    statsKeysToDelete,
    fullKeysToDelete,
    quickFilters,
    renderQuickFilters,
    toolbarFiltersList,
    filtersInSidebar,
    datagridColumns,
    updateCheckField,
    extraSearchFiltersChecks,
    context,
    setActualRow,
    handleCellClick,
    setFiltersCallback,
    filterStatus,
    onChangeFilterStatus,
    search,
    searchStatistics,
    customSearchFunc,
    filtersInUrl,
    setFiltersInUrl,
    listDescription,
    showDates
}: GenericListProps<T, Q>) {

    const {
        page,
        setPage,
        order,
        setOrderBy,
        orderBy,
        setOrder,
        rowsPerPage,
        setRowsPerPage,
        dense,
        onChangePage,
        onChangeDense,
        onChangeRowsPerPage
    } = useTable();

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const { translate, currentLang } = useLocales();

    const [filters, setFilters] = useState({ ...defaultFilters });

    const [lastUsedLang, setLastUsedLang] = useState(currentLang.label);

    const [openSidebar, setOpenSidebar] = useState(false);

    const [resetForm, setResetForm] = useState(true);

    const [resetFormElement, setResetFormElement] = useState("");

    const [showSummary, setShowSummary] = useState(true);

    const methods = useForm<GenericListFilters>({ defaultValues: defaultFilters });

    const { reset, getValues, watch, setValue } = methods;

    var formValues = watch();

    //---- IS DEFAULT - START ----//
    // Checks if there are some filters selected
    const statsKeyRemover = useCallback((key: string) => {
        return !((statsKeysToDelete ?? []).includes(key));
    }, [statsKeysToDelete]);

    const fullKeyRemover = useCallback((key: string) => {
        return !((statsKeysToDelete ?? []).includes(key) || (fullKeysToDelete ?? []).includes(key));
    }, [fullKeysToDelete, statsKeysToDelete]);

    const isDefault = useCallback((filter: GenericListFilters, controller?: GenericListFilters, forStats?: boolean) => {

        const check = controller ? controller : defaultFilters;

        const found = remove(Object.keys(check), forStats ? statsKeyRemover : fullKeyRemover)
            .find((element) => {
                if ((isArray(filter[element]) && isArray(check[element])) || (isObject(filter[element]) && isObject(check[element]))) {
                    return !isEqual(filter[element], check[element]);
                } else {
                    return filter[element] !== check[element];
                }
            });

        return (!found);
    }, [defaultFilters, statsKeyRemover, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    //---- CLOSE AND OPEN SIDEBAR FUNC ----//
    const handleOpenSidebar = () => {
        setOpenSidebar(true);
    };

    const handleCloseSidebar = useCallback(() => {
        if (resetForm) {
            reset(defaultFilters);
        };
        setOpenSidebar(false);
    }, [defaultFilters, reset, resetForm]);

    //---- CLEAR FROM SUMMARY FUNC ----//
    const handleClearFromSummary = useCallback((section: string) => {
        setResetFormElement(section);
        if (isDefault(formValues)) {
            setResetForm(true);
        }
    }, [formValues, isDefault]);

    //---- FILTERS IN URL GET/SET - START ----//
    const location = useLocation();

    const [firstRender, setFirstRender] = useState(true);

    const [lastStatsFilters, setLastStatsFilters] = useState<any>(BASIC_FILTER_OPTIONS);

    const updateFiltersInUrl = useCallback((filters: any) => {

        let queryString = Object.keys(filters).filter((field) => updateCheckField(field, filters))
            .map((key) => {
                if (isArray(filters[key]) || isObject(filters[key]))
                    return `${encodeURIComponent(key)}=${encodeURIComponent(JSON.stringify(filters[key]))}`;

                return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
            })
            .join('&');

        if (queryString) queryString = "#" + queryString;

        if (setFiltersInUrl) dispatch(setFiltersInUrl(queryString));

        navigate(location.pathname + queryString, { replace: true });

    }, [location, navigate, updateCheckField, setFiltersInUrl]);

    const getPageAndSize = useCallback((filtersInUrl?: string) => {
        if (firstRender) {
            let returnValues = [0, 0];

            if (filtersInUrl) {
                const cleanedHash = filtersInUrl.slice(1);

                const decodedQuery = decodeURIComponent(cleanedHash);

                const searchParams = new URLSearchParams(decodedQuery);

                returnValues = [
                    searchParams.get('pageSize') ? Number(searchParams.get('pageSize')) : pageSize,
                    searchParams.get('pageIndex') ? Number(searchParams.get('pageIndex')) : pageIndex
                ];
            } else {
                returnValues = [pageSize, pageIndex];
            }

            return returnValues;
        }
        else return [rowsPerPage, page];
    }, [firstRender, page, pageIndex, pageSize, rowsPerPage]);

    const getFiltersFromUrl = useMemo(() => {

        const { hash } = location;

        const [pageSizeFunc, pageIndexFunc] = getPageAndSize(filtersInUrl ?? "");

        let searchFilters: GenericListFilters = {
            pageIndex: pageIndexFunc,
            pageSize: pageSizeFunc,
            sortField: orderBy || undefined,
            sortAscending: orderBy ? (order === 'desc' ? true : false) : undefined
        };

        if (hash) {

            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            searchFilters = {
                ...(Object.fromEntries(searchParams.entries())),
                ...searchFilters
            };

            if (filtersInSidebar) filtersInSidebar.filter((field) => field.toParse).forEach((field) => searchFilters[field.name] = JSON.parse(searchFilters[field.name]));

            if (searchParams.get('customFields')) searchFilters["customFields"] = JSON.parse(searchParams.get('customFields')!);

        }

        searchFilters = omitBy(searchFilters, (x) => x === undefined || x === null) as GenericListFilters;

        return searchFilters as Q;

    }, [location, getPageAndSize, orderBy, order]);
    //---- FILTERS IN URL GET/SET - END ----//

    //---- FETCH DATA FUNC ----//
    // Gets all filter values ​​other than the default ones and puts them in the url
    const fetchData = useCallback(async (values: Q) => {

        var searchFilters: any = {};

        if (isEqual(values, defaultFilters)) {
            searchFilters = BASIC_FILTER_OPTIONS;
        } else {
            Object.keys(values).forEach((field) => { searchFilters[field] = !isEqual(values[field], defaultFilters[field]) ? values[field] : null; });

            searchFilters = {
                ...searchFilters,
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: orderBy ? (order === 'desc' ? true : false) : null
            };
        }

        updateFiltersInUrl(searchFilters);
    }, [defaultFilters, order, orderBy, page, rowsPerPage, updateFiltersInUrl]);

    //---- SEARCH FOR ITEMS AND STATISTICS - START ----//
    // This function is used to call APIs and get Users list and statistics using filters
    const adjustLastFiltered = useCallback((values: Q) => {
        return {
            ...Object.entries(values).map((field) => isEqual(values[field[0]], defaultFilters[field[0]]) ? values[field[0]] : null),
            pageIndex: 0,
            pageSize: 10,
            sortField: "",
            sortAscending: false
        };
    }, [defaultFilters]);

    const onSearch = useCallback((filtersFromUrl: Q) => {

        setFilters(filtersFromUrl);

        if (setFiltersCallback) setFiltersCallback(filtersFromUrl);

        updateFiltersInUrl(filtersFromUrl);

        const customFieldsFromUrl: Record<string, string> = Object.entries(filtersFromUrl.customFields || {})
            .map(([k, val]) => ({
                key: "customFields." + k,
                value: val
            }))
            .reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});

        const updatedFiltersFromUrl = { ...filtersFromUrl, ...customFieldsFromUrl };

        delete updatedFiltersFromUrl.customFields;

        if (customSearchFunc) customSearchFunc(updatedFiltersFromUrl);
        else if (search) dispatch(search(updatedFiltersFromUrl));

        if ((firstRender
            || isEqual(filtersFromUrl, BASIC_FILTER_OPTIONS)
            || !isDefault(filtersFromUrl, lastStatsFilters as Q, true)) && searchStatistics
        ) {
            dispatch(searchStatistics(updatedFiltersFromUrl));
            setLastStatsFilters(adjustLastFiltered(filtersFromUrl));
        }

        if (firstRender) setFirstRender(false);

    }, [setFiltersCallback, updateFiltersInUrl, customSearchFunc, search, firstRender, isDefault, lastStatsFilters, searchStatistics, adjustLastFiltered]);
    //---- SEARCH FOR ITEMS AND STATISTICS - END ----//

    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - START ----//
    // This hook is used to call onSearch when filters or language are changed
    const isOrderDiff = useCallback((filtersToCheck: Q, olderFilters: Q) => {

        const differencesToOld = Object.keys(filtersToCheck).filter((filter) => filtersToCheck[filter] !== olderFilters[filter]);

        if (differencesToOld.includes("sortField") || differencesToOld.includes("sortAscending")) return true;

        const differencesToFilters = Object.keys(olderFilters).filter((filter) => olderFilters[filter] !== filtersToCheck[filter]);

        return differencesToFilters.includes("sortField") || differencesToFilters.includes("sortAscending");

    }, []);

    useEffect(() => {

        let searchFilters = cloneDeep(getFiltersFromUrl);

        if (extraSearchFiltersChecks)
            searchFilters = extraSearchFiltersChecks(searchFilters);

        if (!isEqual(searchFilters, filters) || lastUsedLang !== currentLang.label) {

            if (!firstRender && isOrderDiff(searchFilters, filters)) searchFilters.pageIndex = 0;

            onSearch(searchFilters as Q);

            if (lastUsedLang !== currentLang.label) setLastUsedLang(currentLang.label);
            if (searchFilters.pageIndex !== page) setPage(searchFilters.pageIndex);
            if (searchFilters.pageSize !== rowsPerPage) setRowsPerPage(searchFilters.pageSize);
        }

    }, [location, page, rowsPerPage, orderBy, order, firstRender, filterStatus, currentLang, lastUsedLang,
        filters, setPage, setRowsPerPage, pageIndex, pageSize, isOrderDiff, extraSearchFiltersChecks, onSearch, getFiltersFromUrl]);
    //---- SEARCH FOR ITEMS AND STATISTICS HOOK - END ----//

    //---- FILTERS SEARCH FUNC ----//
    // Used for search buttons in filters
    const handleSearchFilters = useCallback(() => {
        fetchData(getValues() as Q);
        setResetForm(false);
        setOpenSidebar(false);
        setPage(0);
    }, [fetchData, getValues, setPage]);

    //---- FILTERS RESET - START ----//
    // Used for full clear buttons in filters
    const handleResetAllFilter = useCallback(() => {
        if (openSidebar) {
            handleCloseSidebar();
        }
        setPage(0);
        setResetForm(true);
        setShowSummary(false);
        fetchData(defaultFilters);
        reset(defaultFilters);
    }, [defaultFilters, fetchData, handleCloseSidebar, openSidebar, reset, setPage]);

    const handleResetSingleFilter = useCallback((fieldName: string, value?: any) => {
        if (isArray(formValues[fieldName])) {
            const index = formValues[fieldName].indexOf(value);

            if (index > -1) {
                formValues[fieldName].splice(index, 1);
                setValue(fieldName, formValues[fieldName]);
                handleClearFromSummary(fieldName);
                fetchData(formValues as Q);
            }
        } else if (isObject(formValues[fieldName])) {

            delete (formValues[fieldName] as Record<string, string>)[value];

            setValue(fieldName, formValues[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);

        } else {
            formValues[fieldName] = defaultFilters[fieldName];
            setValue(fieldName, defaultFilters[fieldName]);
            handleClearFromSummary(fieldName);
            fetchData(formValues as Q);
        }
    }, [defaultFilters, fetchData, formValues, handleClearFromSummary, setValue]);
    //---- FILTERS RESET - END ----//

    //---- HANDLE TABLE START ----//
    const getHeight = useCallback(() => {
        let height: string | number = "auto";

        if (!dense || list.length === 0) {
            if (isDesktop) height = rowsPerPage === 5 ? 380 : 650;
            else height = rowsPerPage === 5 ? 440 : 700;
        }

        return height;
    }, [dense, isDesktop, list, rowsPerPage]);

    const getMaxHeight = useCallback(() => {
        return isDesktop ? 650 : 700;
    }, [isDesktop]);

    const handleSort = useCallback((sortModel: GridSortModel) => {
        if (sortModel.length > 0) {
            setOrderBy(sortModel[0].field);
            setOrder(sortModel[0].sort!);
        } else {
            setOrderBy("");
            setOrder("asc");
        }
    }, [setOrder, setOrderBy]);

    useEffect(() => {
        const [rowsFromFunc, pageFromFunc] = getPageAndSize();

        if (totalCount <= rowsFromFunc * pageFromFunc) onChangePage(null, 0);

    }, [onChangePage, totalCount, getPageAndSize]);

    useEffect(() => {
        if (pageIndex !== page) setPage(pageIndex);
    }, [pageIndex, setPage]);
    //------------------ visibility -------------------//

    const [columns, setColumns] = useLocalStorage<Record<string, GridColumnVisibilityModel>>("columns", {});

    const [visibility, setVisibility] = useState<GridColumnVisibilityModel>(columns[context] || (() => datagridColumns.reduce((prev, curr) => {
        Object.assign(prev, { [curr.field]: true });

        return prev;
    }, {})));

    useEffect(() => {
        setColumns({ ...columns, "user": visibility });
    }, [visibility]);

    //---- HANDLE TABLE END ----//

    return (
        <Card>

            {(filtersInSidebar || listDescription) &&
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', px: { xs: 2, md: 3.5 }, pb: 2 }}>
                    {listDescription &&
                        <Box>
                            <Typography variant="body2" sx={{ mt: 2 }}>
                                {listDescription}
                            </Typography>
                        </Box>
                    }

                    {filtersInSidebar &&
                        <Box sx={{ mt: 2, display: 'flex' }}>
                            <FormProvider methods={methods}>
                                <GenericFilterSidebar
                                    isOpen={openSidebar}
                                    onOpen={handleOpenSidebar}
                                    onClose={handleCloseSidebar}
                                    onFilter={handleSearchFilters}
                                    onResetAll={handleResetAllFilter}
                                    defaultFilters={defaultFilters}
                                    resetTrigger={resetForm}
                                    filterValues={{ ...formValues, ...(getFiltersFromUrl) }}
                                    filterList={filtersInSidebar}
                                    isDefault={isDefault}
                                    setShowSummary={setShowSummary}
                                    customfieldContext={["User"]}
                                    resetFormElement={resetFormElement}
                                    setResetFormElement={setResetFormElement}
                                />
                            </FormProvider>
                        </Box>
                    }
                </Box>
            }

            {(quickFilters && renderQuickFilters) &&
                <>
                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons={!isDesktop}
                        value={filterStatus}
                        onChange={(e, value) => {
                            setPage(0);
                            if (onChangeFilterStatus) onChangeFilterStatus(e, value);
                        }}
                        sx={{
                            px: { xs: 0, md: 2 },
                            bgcolor: 'background.neutral'
                        }}
                    >
                        {quickFilters.map((tab) => (
                            <Tab
                                disableRipple
                                key={tab.key}
                                label={tab.label}
                                value={tab.key}
                                icon={
                                    <Label color={tab?.color} sx={{ mr: 1 }}>
                                        {renderQuickFilters(tab.key)}
                                    </Label>
                                }
                            />
                        ))}
                    </Tabs>

                    <Divider />
                </>
            }

            {toolbarFiltersList &&
                <FormProvider methods={methods}>
                    <GenericFilterToolbar
                        filterValues={{ ...formValues, ...getFiltersFromUrl }}
                        defaultFiltersValues={defaultFilters}
                        onSearch={handleSearchFilters}
                        onResetAll={handleResetAllFilter}
                        optionsFields={toolbarFiltersList}
                        showDates={showDates}
                        editableColumns
                        showSummary={showSummary}
                        setShowSummary={setShowSummary}
                        columnVisibility={visibility}
                        setColumnVisibility={setVisibility}
                    />
                </FormProvider>}

            {filtersInSidebar &&
                <GenericFilterSummary
                    showSummary={showSummary && !openSidebar && !isDefault({ ...formValues, ...(getFiltersFromUrl) })}
                    defaultFilters={defaultFilters}
                    filterValues={{ ...formValues, ...getFiltersFromUrl }}
                    filterList={filtersInSidebar}
                    onResetFilter={handleResetSingleFilter}
                    onResetAll={handleResetAllFilter}
                    customfieldContext={[context]}
                />
            }

            <Divider />

            <Box>
                <DataGrid
                    rows={list}
                    columns={datagridColumns}
                    pagination
                    disableColumnResize
                    paginationModel={{
                        page: page,
                        pageSize: rowsPerPage
                    }}
                    density={(dense && list.length > 0) ? 'compact' : 'standard'}
                    sortingMode={"server"}
                    onSortModelChange={handleSort}
                    loading={isLoading}
                    columnVisibilityModel={visibility}
                    slots={{
                        noRowsOverlay: noData,
                        footer: () => (
                            <Box
                                sx={{
                                    position: 'relative',
                                    width: { xs: "90vw", md: "auto" }
                                }}
                            >
                                <TablePagination
                                    rowsPerPageOptions={[5, 10, 15, 30]}
                                    component="div"
                                    count={totalCount}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    onPageChange={onChangePage}
                                    onRowsPerPageChange={onChangeRowsPerPage}
                                    labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                                    sx={{
                                        overflow: "hidden",
                                        "& .MuiTablePagination-input": {
                                            ml: { xs: 0.5, md: "default" },
                                            mr: { xs: 3.5, md: "default" }
                                        }
                                    }}
                                />
                                <FormControlLabel
                                    control={<Switch checked={dense} onChange={onChangeDense} />}
                                    label={`${translate('commons.dense')}`}
                                    sx={{
                                        px: { xs: 0, sm: 3 },
                                        py: { xs: 0, sm: 1.5 },
                                        pb: { xs: 1.5, sm: 0 },
                                        mx: 0,
                                        top: 0,
                                        justifyContent: "center",
                                        width: { xs: "90vw", sm: "auto" },
                                        position: { sm: 'absolute' }
                                    }}
                                />
                            </Box>
                        )
                    }}
                    disableColumnMenu
                    pageSizeOptions={[5, 10, 15, 30]}
                    onCellClick={(params) => {
                        setActualRow(params);

                        if (!window.getSelection()?.toString())
                            handleCellClick(params);
                    }}
                    sx={{
                        ...DataGridStyle,
                        cursor: 'pointer',
                        height: getHeight(),
                        maxHeight: getMaxHeight()
                    }}
                />
            </Box>

        </Card>
    );
} 