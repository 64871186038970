import { useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
    Card,
    Container,
    CircularProgress,
    TableContainer,
    Table,
    TableBody,
    TableRow,
    TableCell,
    Stack,
    Box,
    TablePagination,
    FormControlLabel,
    Switch
} from '@mui/material';
import { dispatch, RootState } from 'src/redux/store';
import { useSelector } from 'react-redux';
import useLocales from 'src/appHooks/useLocales';
import useTable from 'src/appHooks/useTable';
import { useSettingsContext } from 'src/components/settings';
import { PATH_DASHBOARD } from 'src/routes/paths';
import Page from 'src/appComponents/Page';
import { TableHeadCustom, TableNoData } from 'src/components/table';
import HeaderBreadcrumbs from 'src/components/custom-breadcrumbs';
import { scrollBarStyle } from 'src/components/scrollbar/Scrollbar';
import { Email } from 'src/@types/email';
import EmailTableToolbar from 'src/sections/@dashboard/systemEmail/EmailTableToolbar';
import EmailTableRow from 'src/sections/@dashboard/systemEmail/EmailTableRow';
import useResponsive from 'src/hooks/useResponsive';
import { emailOperations } from 'src/redux/email';

export default function EmailList() {
    const {
        page,
        order,
        orderBy,
        rowsPerPage,
        dense,
        setPage,
        onSort,
        onChangePage,
        onChangeRowsPerPage,
        onChangeDense
    } = useTable();

    const isDesktop = useResponsive('up', 'sm');

    const navigate = useNavigate();

    const { themeStretch } = useSettingsContext();

    const { translate, currentLang } = useLocales();

    const { isLoading, emailList } = useSelector((state: RootState) => state.email);

    const isNotFound = emailList.length === 0;

    //---- FILTERBAR 
    const location = useLocation();

    const getFiltersFromUrl = () => {
        const { hash } = location;

        let searchFilters = {};
        if (hash) {
            const cleanedHash = hash.slice(1);

            const decodedQuery = decodeURIComponent(cleanedHash);

            const searchParams = new URLSearchParams(decodedQuery);

            let filters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy || null,
                sortAscending: order === 'desc' ? false : true,
                recipient: searchParams.get('recipient')
            };
            searchFilters = Object.fromEntries(Object.entries(filters).filter(([_, value]) => value !== null && value !== 'null'));

        } else {
            searchFilters = {
                pageIndex: page,
                pageSize: rowsPerPage,
                sortField: orderBy,
                sortAscending: order === 'desc' ? false : true
            };
        }

        return (searchFilters);
    };

    const getInitialFilterName = (): string => {
        const filters = getFiltersFromUrl();

        for (const [key, value] of Object.entries(filters)) {
            if (key === "recipient" && typeof value === "string") {
                return value;
            }
        }

        return "";
    };

    const [filterName, setFilterName] = useState(getInitialFilterName());

    const handleFilterName = (filterName: string) => {
        setFilterName(filterName);
        setPage(0);
    };

    //---- FILTERS IN URL

    const updateFiltersInUrl = (filters: any) => {
        const queryString = Object.keys(filters).map((key) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(filters[key])}`;
        }).join('&');

        window.location.hash = queryString ? `#${queryString}` : '';
    };

    useEffect(() => {
        let searchFilters = getFiltersFromUrl();
        dispatch(emailOperations.getAllEmails(searchFilters));
    }, [location, page, rowsPerPage, orderBy, currentLang]);

    const onSearch = () => {
        var options = {
            pageIndex: page,
            pageSize: rowsPerPage,
        };
        (options as any).recipient = filterName;
        updateFiltersInUrl(options);
    };

    //---- TABLE
    const TABLE_HEAD = useMemo(() => [
        { id: 'recipient', label: `${translate('systemEmail.detail.recipient')}`, align: 'left' },
        { id: 'subject', label: `${translate('inbox.subject')}`, align: 'left' },
        { id: 'requestedAt', label: `${translate('systemEmail.detail.requestedAt')}`, align: 'left' },
        { id: 'status', label: `${translate('commons.status')}`, align: 'left' },
        //{ id: 'options', label: ``, align: 'center' }
    ], []);

    //---- OPEN DETAILS
    const handleDetailRow = (id: string) => {
        navigate(PATH_DASHBOARD.tenant.systemEmailDetails(id));
    };

    return (
        <Page title={`${translate('systemEmail.list.title')}`}>
            <Container maxWidth={themeStretch ? false : 'lg'}>
                <HeaderBreadcrumbs
                    heading={`${translate('systemEmail.list.title')}`}
                    links={[
                        { name: `${translate('commons.home')}`, href: PATH_DASHBOARD.root },
                        { name: `${translate('systemEmail.list.title')}` },
                        { name: `${translate('commons.list')}` },
                    ]}
                    sx={{ px: isDesktop ? 5 : 2, mb: isDesktop ? 5 : 2 }}
                />

                <Card>

                    <EmailTableToolbar
                        filterName={filterName}
                        //filterField={filterField}
                        onFilterName={handleFilterName}
                        //onFilterField={handleFilterField}
                        //optionsFields={filterList}
                        onSearch={onSearch}
                    />

                    <TableContainer sx={[{ minWidth: 800, maxHeight: 500, position: 'relative', height: dense ? '' : rowsPerPage <= 5 ? 400 : 500 }, scrollBarStyle]}>
                        <Table stickyHeader size={dense ? 'small' : 'medium'}>
                            <TableHeadCustom
                                order={order}
                                orderBy={orderBy}
                                headLabel={TABLE_HEAD}
                                rowCount={emailList.length}
                                onSort={onSort}
                            />
                            {isLoading ? (
                                <TableBody>
                                    <TableRow>
                                        <TableCell colSpan={12}>
                                            <Stack
                                                alignItems="center"
                                                justifyContent="center"
                                                sx={{
                                                    height: 1,
                                                    textAlign: 'center',
                                                    p: (theme) => theme.spacing(8, 2),
                                                }}
                                            >
                                                <CircularProgress />
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            ) : (
                                <TableBody>
                                    {emailList.map((row: Email) => (
                                        <EmailTableRow
                                            key={row.id}
                                            row={row}
                                            onDetailsRow={() => handleDetailRow(row.id)}
                                        />
                                    ))}

                                    <TableNoData isNotFound={isNotFound} />
                                </TableBody>
                            )}
                        </Table>
                    </TableContainer>
                    <Box sx={{ position: 'relative' }}>
                        <TablePagination
                            rowsPerPageOptions={[5, 10, 15, 30]}
                            component="div"
                            count={emailList.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={onChangePage}
                            onRowsPerPageChange={onChangeRowsPerPage}
                            labelRowsPerPage={`${translate('commons.rowsPerPage')}`}
                        />
                        <FormControlLabel
                            control={<Switch checked={dense} onChange={onChangeDense} />}
                            label={`${translate('commons.dense')}`}
                            sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
                        />
                    </Box>
                </Card>
            </Container>
        </Page>
    );
}
