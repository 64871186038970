import { DEFAULT_USER, UserManager } from "src/@types/user";

export function defaultNewUserData(user: UserManager | null, customFields: Record<string, string> | null | undefined, isEdit: boolean) {

    return {
        firstName: user?.firstName || DEFAULT_USER.firstName,
        lastName: user?.lastName || DEFAULT_USER.lastName,
        email: user?.email || DEFAULT_USER.email,
        username: user?.username || DEFAULT_USER.username,
        culture: user?.culture || DEFAULT_USER.culture,
        country: user?.country || DEFAULT_USER.country,
        measurementSystem: user?.measurementSystem || DEFAULT_USER.measurementSystem,
        currency: user?.currency || DEFAULT_USER.currency,
        timeZone: user?.timeZone || DEFAULT_USER.timeZone,
        externalId: user?.externalId || DEFAULT_USER.externalId,
        tags: user?.tags || DEFAULT_USER.tags,
        customFields: user?.customFields || customFields,
        enabled: user?.enabled ?? DEFAULT_USER.enabled,
        emailVerified: user?.emailVerified ?? DEFAULT_USER.emailVerified,
        company: user?.company || DEFAULT_USER.company,
        branch: user?.branch || DEFAULT_USER.branch,
        organizations: user?.organizations || DEFAULT_USER.organizations,
        roles: user?.roles || DEFAULT_USER.roles,
        displayName: user?.displayName || DEFAULT_USER.displayName,
        pictureUrl: user?.pictureUrl || DEFAULT_USER.pictureUrl,
        isOrganizationUser: user?.organizations ? user?.organizations.length > 0 : !isEdit,
        isAdminUser: user?.roles ? user?.roles.length > 0 : DEFAULT_USER.isAdminUser,
        authenticationProvider: user?.authenticationProvider || DEFAULT_USER.authenticationProvider
    };
}