import { Box, Button, Card, Container, Divider, Step, StepLabel, Stepper } from "@mui/material";
import Page from "src/appComponents/Page";
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from "src/routes/paths";
import { useSettingsContext } from "src/components/settings";
import { useLocales } from "src/locales";
import useResponsive from "src/hooks/useResponsive";
import { useCallback, useEffect, useMemo, useState } from "react";
import Complete from "src/sections/@dashboard/request/newRequestFlow/Complete";
import { DEFAULT_REQUEST, ItemSearchResult, RequestNew } from "src/@types/request";
import { useNavigate } from "react-router";
import SearchProduct from "src/sections/@dashboard/request/newRequestFlow/SearchProduct";
import Detail from "src/sections/@dashboard/request/newRequestFlow/Detail";
import Request from "src/sections/@dashboard/request/newRequestFlow/Request";
import { DeliveryNote, Invoice } from "src/@types/orders";
import { RootState, dispatch } from "src/redux/store";
import { requestOperations } from "src/redux/request";
import { DEFAULT_PARENT, OrganizationSearchResult } from "src/@types/organizations";
import SearchCustomer from "src/sections/@dashboard/request/newRequestFlow/SearchCustomer";
import { useSelector } from "react-redux";
import { organizationsOperations } from "src/redux/organizations";
import { useUserOrganizationContext } from "src/contexts/UserOrganizationContext";
import LoadingScreen from "src/appComponents/loading-screen/LoadingScreen";
import SearchSupplier from "src/sections/@dashboard/request/newRequestFlow/SearchSupplier";
import { SparePartSearchResult } from "src/@types/spareParts";
import { FileWithSection, handleUpload } from "src/appUtils/requestUpload";
import { setSuccessMessage } from "src/redux/modal/modal-slices";
import { termConditionOperations } from "src/redux/termCondition";

interface NewRequestProps {
    isVendor?: boolean
}

export default function NewRequest({ isVendor }: NewRequestProps) {

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const navigate = useNavigate();

    const { organizationId: orgId } = useUserOrganizationContext();

    const { owners, isOwnersLoading } = useSelector((state: RootState) => state.organizations);

    const { models } = useSelector((state: RootState) => state.vehicle);

    const [isSendingLoading, setIsSendingLoading] = useState(false);

    const [step, setStep] = useState(0);

    const [request, setRequest] = useState<RequestNew>(DEFAULT_REQUEST);

    const [disabled, setDisabled] = useState(true);

    const [exceededParts, setExceededParts] = useState<(SparePartSearchResult & { quantity: number })[]>([]);

    const [deliveryNotes, setDeliveryNotes] = useState<DeliveryNote[]>([]);

    const [invoices, setInvoices] = useState<Invoice[]>([]);

    const [warrantyArParts, setWarrantyArParts] = useState<ItemSearchResult[]>([]);

    const [terms, setTerms] = useState(false);

    const [ar, setAr] = useState(false);

    const [address, setAddress] = useState<OrganizationSearchResult>();

    const [organizationId, setOrganizationId] = useState("");

    const [checkFlag, setCheckFlag] = useState(false);

    const [images, setImages] = useState<FileWithSection[]>([]);

    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        dispatch(termConditionOperations.getDefaultTermCondition("ReturnRequest"));
    }, []);

    const STEPS: string[] = useMemo(() => [
        ...(isVendor ? ["searchCustomer"] : []),
        ...(!isVendor && owners && owners.length > 1 ? ["searchSupplier"] : []),
        "request",
        "searchProduct",
        "details",
        "complete"
    ], [isVendor, owners]);

    const totalReset = () => {
        setRequest(DEFAULT_REQUEST);
        setStep(0);
        setAddress(undefined);
        setImages([]);
        setCheckFlag(false);
        setInvoices([]);
        setDeliveryNotes([]);
        setTerms(false);
        setWarrantyArParts([]);
        setExceededParts([]);
    };

    useEffect(() => {
        if (organizationId && STEPS[step] === "request")
            dispatch(organizationsOperations.getGeneric(isVendor ? organizationId : orgId)).unwrap().then(v => {
                let com = v.customFields!['stellantis-europe-organization-warranty-type'];

                if (com === "AR")
                    setAr(true);
                else
                    setAr(false);

            });
    }, [STEPS, isVendor, orgId, organizationId, step]);

    const handleSetParts = (parts: ItemSearchResult[]) => {
        setWarrantyArParts(parts);
    };

    const handleDocs = (docs: (DeliveryNote | Invoice)[], inv: boolean) => {
        if (inv)
            setInvoices(docs as Invoice[]);
        else
            setDeliveryNotes(docs as DeliveryNote[]);
    };

    const handleSubmit = async () => {
        if (isSubmitting)
            return;

        setIsSubmitting(true);

        request.items.forEach(item => delete item.id);

        request.vendorId = !isVendor ? organizationId : orgId;

        request.customerId = isVendor ? organizationId : orgId;

        let reqId = "";

        if (request.requestReason === "SurplusDelivered") {
            request.exceededItems = request.items.map(v => ({
                productId: v.productId,
                quantity: v.quantity,
                bigAndHeavy: v.bigAndHeavy,
                itemIndex: v.orderItemIndex,
                orderId: v.orderId
            }));

            reqId = (await dispatch(requestOperations.createRequest({ ...request, items: [] })).unwrap()).id;
        }
        else if (request.requestReason === "MissingParts") {
            request.missingItems = request.items.map((v) => ({
                bigAndHeavy: v.bigAndHeavy,
                orderId: v.orderId!,
                orderItemIndex: v.orderItemIndex!,
                productId: v.productId,
                quantity: v.quantity
            }));

            reqId = (await dispatch(requestOperations.createRequest({ ...request, items: [] })).unwrap()).id;
        }
        else
            reqId = (await dispatch(requestOperations.createRequest(request)).unwrap()).id;

        if (images.length > 0) {
            setIsSendingLoading(true);
            await handleUpload(images, reqId, setIsSendingLoading);
        }

        dispatch(setSuccessMessage({ text: translate('request.save'), returnTo: isVendor ? PATH_DASHBOARD.request.vendor.list : PATH_DASHBOARD.request.customer.list }));

        totalReset();

        if (owners && owners.length > 1)
            setOrganizationId("");

        setIsSubmitting(false);
    };

    const handleChangeOrg = (org: string) => {
        setOrganizationId(org);
        totalReset();
    };

    useEffect(() => {
        if (!isVendor)
            dispatch(organizationsOperations.getOwner(orgId)).unwrap()
                .then((val) => {
                    if (val.length === 1)
                        setOrganizationId(val[0].id);
                    else if (val.length === 0)
                        setOrganizationId(orgId);
                });
    }, [isVendor, orgId]);

    const getDisabled = useCallback((): boolean => {

        if (request.requestType === "Core" || request.requestType === "NewPart" || request.requestReason === "PartOrderedIsNotComplete")
            return request.items[0].quantity <= 0 || !request.deliveryCompanyId;

        if (request.requestType === "DamagedPart") {
            let val = request.items[0].quantity <= 0 || !request.deliveryCompanyId || !request.note;

            val = val || images.length === 0;

            return val;
        }

        if (request.requestType === "Warranty") {

            if (!ar) {
                let val = request.items[0].quantity <= 0 || !request.deliveryCompanyId;
                if (!request.warranty)
                    return true;

                const { warranty } = request;

                val = val || Object.entries(warranty.vehicle!).some(([k, v]) => {

                    return v.trim() === "";
                });

                for (let i = 0; i < warranty.invoices.length; i++) {
                    if (i < 2)
                        val = val || !warranty.invoices[i].date || !warranty.invoices[i].number.trim();
                    else
                        val = val || !warranty.invoices[2].date || !warranty.invoices[2].number.trim() || !warranty.invoices[2].mileage;
                }

                val = val ||
                    (!warranty.note!.trim() || warranty.note!.length > 72) ||
                    (!warranty.diagnosis!.trim() || warranty.diagnosis!.length > 72) ||
                    (!warranty.solution!.trim() || warranty.solution!.length > 72);

                val = val || images.length !== 5;

                return val;
            }

            let val = request.items.some(i => i.quantity <= 0) || images.length !== 1 || !request.deliveryCompanyId;

            val = val || (!request.warranty?.invoices[0].date || !request.warranty.invoices[0].number);

            return val;

        }

        if (request.requestReason === "ThePartDoesntWork") {
            return request.items[0].quantity <= 0 || !request.deliveryCompanyId || !request.note;
        }
        if (request.requestReason === "MissingParts") {
            return request.items.some(i => i.quantity <= 0) || !request.deliveryCompanyId;
        }

        if (request.requestReason === "SurplusDelivered") {
            let val = request.items.some(i => i.quantity <= 0);

            if (!checkFlag)
                val = val || !request.deliveryCompanyId;

            return val;
        }

        if (request.requestReason === "OriginalPackagingContainsWrongParts") {
            let val = request.items.some(i => i.quantity <= 0) || !request.note || !request.deliveryCompanyId;

            return val;
        }

        return false;

    }, [ar, checkFlag, images.length, models.length, request]);

    useEffect(() => {
        setDisabled(true);

        switch (STEPS[step]) {
            case 'searchCustomer': {
                setDisabled(!organizationId);
                break;
            }
            case 'searchSupplier': {
                setDisabled(!organizationId);
                break;
            }
            case "request": {
                if ((request.requestType === 'Quality' || request.requestType === "WrongShipment") && request.requestReason)
                    setDisabled(false);
                else if (request.requestType !== null && request.requestType !== "" && request.requestType !== 'Quality' && request.requestType !== "WrongShipment")
                    setDisabled(false);
                else
                    setDisabled(true);

                break;
            }
            case "searchProduct": {
                setDisabled(request.items.length === 0);
                break;
            }
            case "details": {
                setDisabled(getDisabled());
                break;
            }
            case "complete": {
                setDisabled(!terms);
                break;
            }
            default:
                setDisabled(false);
        };

    }, [ar, organizationId, request, step, terms, images, STEPS, checkFlag, getDisabled]);

    const handleReset = () => {
        setRequest(prev => ({ ...DEFAULT_REQUEST, requestReason: prev.requestReason, requestType: prev.requestType }));
        setDeliveryNotes([]);
        setInvoices([]);
        setAddress(undefined);
        setImages([]);
        setCheckFlag(false);
        setWarrantyArParts([]);
        setExceededParts([]);
    };

    useEffect(() => {
        handleReset();

    }, [request.requestType, request.requestReason]);

    useEffect(() => {

        totalReset();
        if (owners && owners.length > 1)
            setOrganizationId("");

    }, [isVendor, owners]);

    const handleChange = (name: string, value: any, item?: boolean) => {

        if (name === 'terms')
            setTerms(value);

        else if (name === 'requestType') {
            setRequest({ ...request, [name]: value, requestReason: null });
        }

        else if (request.requestType === "WrongShipment" || (request.requestType === "Warranty" && ar)) {
            handleChangeWrong(name, value, !!item);

            return;
        }

        else if (item)
            setRequest({ ...request, items: [{ ...request.items[0], [name]: value }] });

        else if (name === 'document') {
            setRequest({ ...request, items: [{ orderId: value[0], productId: value[1], id: value[2], orderItemIndex: value[3], quantity: 0, bigAndHeavy: false }] });
        }
        else if (name === "deliveryCompanyId") {
            setRequest({ ...request, [name]: value[0] });
            setAddress(value[1]);
        }
        else if (name === "pack") {
            setCheckFlag(value);
            setRequest(prev => ({ ...prev, damagedPackaging: value }));
        }

        else if (name === "images")
            setImages(value);

        else if (name === "wrongPart") {
            setRequest(prev => ({ ...prev, exceededItems: value }));

        }

        else
            setRequest({ ...request, [name]: value });

    };

    const handleNextStep = () => {

        step < STEPS.length ? setStep(step + 1) : setStep(step);
    };

    const handlePreviousStep = (customStep?: number) => {

        if (customStep !== undefined)
            setStep(customStep);
        else
            step > 0 ? setStep(step - 1) : setStep(0);
    };

    // HANDLES FOR WRONG PART REQUEST TYPE

    const handleChangeWrong = (name: string, val: any, items: boolean) => {
        if (items) {

            setRequest(prev => ({ ...prev, items: val }));

        }

        else if (name === "keep") {
            setCheckFlag(val);
        }

        else if (name === "exceededItems")
            setRequest(prev => ({ ...prev, exceededItems: val }));

        else if (name === 'requestType') {
            setRequest({ ...request, [name]: val, requestReason: null });
        }
        else if (name === 'terms')
            setTerms(val);

        else if (name === "deliveryCompanyId") {
            setRequest({ ...request, [name]: val[0] });
            setAddress(val[1]);
        }
        else if (name === "images")
            setImages(val);
        else if (name === "exceed")
            setExceededParts(val);

        else if (name === 'customField') {
            setRequest(prev => ({ ...prev, customFields: { ...prev.customFields, [val[0]]: val[1] } }));
        }

        else
            setRequest({ ...request, [name]: val });
    };

    const STEPS_COMPONENT = [
        ...(isVendor ? [<SearchCustomer organization={organizationId} onChange={handleChangeOrg} key={0} />] : []),
        ...(!isVendor && owners && owners.length > 1 ? [<SearchSupplier key={7} owners={owners} onChange={handleChangeOrg} />] : []),
        <Request request={request} onChange={handleChange} key={1} />,
        <SearchProduct changeExceedItems={setExceededParts} exceededItems={exceededParts} arParts={warrantyArParts} changeArParts={handleSetParts} ar={ar} request={request} onChange={handleChange} isVendor={!!isVendor} organization={organizationId} key={2} />,
        <Detail exceededParts={exceededParts} chekFlag={checkFlag} warrantyParts={warrantyArParts} ar={ar} files={images} isVendor={!!isVendor} organization={organizationId} defaultAddress={address || DEFAULT_PARENT} setDocument={handleDocs} request={request} onChange={handleChange} key={3} />,
        <Complete deliveryNotes={deliveryNotes} invoices={invoices} customerId={isVendor ? organizationId : orgId} vendorId={!isVendor ? organizationId : orgId} exceededParts={exceededParts} ar={ar} warrantyParts={warrantyArParts} files={images} request={request} onChange={handleChange} key={4} />
    ];

    return (
        <Page title={`${translate("request.request")}: ${translate("request.form.title")}`}>
            {(isOwnersLoading || isSendingLoading) ? <LoadingScreen /> :
                <Container maxWidth={themeStretch ? false : 'lg'}>

                    <HeaderBreadcrumbs
                        heading={`${translate("request.form.title")}`}
                        links={[
                            { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                            { name: `${translate("menu.management.request.title")}`, href: isVendor ? PATH_DASHBOARD.request.vendor.list : PATH_DASHBOARD.request.customer.list },
                            { name: `${translate("returns.newReturn")}` },
                        ]}
                        sx={{ mb: isDesktop ? 5 : 2 }}
                    />

                    <Stepper
                        sx={{ mb: 2, px: isDesktop ? 5 : 2, }}
                        activeStep={step}
                        alternativeLabel
                    >
                        {STEPS.map((steps, ind) =>
                            <Step key={ind}>
                                <StepLabel>
                                    {isDesktop ? `${translate(`request.steps.${steps}`)}` : ''}</StepLabel>
                            </Step>
                        )}
                    </Stepper>

                    <Card sx={{ mt: 2 }}>
                        {
                            STEPS_COMPONENT[step]
                        }
                        <Divider />

                        <Box
                            sx={{
                                display: 'flex',
                                px: isDesktop ? 5 : 2,
                                py: isDesktop ? 3 : 1,
                                gap: 1,
                                flexDirection: isDesktop ? 'row' : 'column-reverse'
                            }}
                        >
                            <Button variant="soft" size={isDesktop ? "small" : 'large'} onClick={() => { navigate(isVendor ? PATH_DASHBOARD.request.vendor.list : PATH_DASHBOARD.request.customer.list); }}>
                                {`${translate("commons.cancel")}`}
                            </Button>

                            <Box sx={{ ml: isDesktop ? 'auto' : 0, display: 'flex', gap: 1, flexDirection: isDesktop ? 'defaul' : 'column-reverse' }}>

                                {step > 0 && (
                                    <Button
                                        variant="contained"
                                        size={isDesktop ? "small" : 'large'}
                                        onClick={() => handlePreviousStep()}
                                    >
                                        {`${translate("commons.previous")}`}
                                    </Button>
                                )}

                                {step < STEPS.length - 1 ? (
                                    <Button
                                        variant="contained"
                                        size={isDesktop ? "small" : 'large'}
                                        disabled={disabled}
                                        onClick={() => handleNextStep()}
                                    >
                                        {`${translate("commons.next")}`}
                                    </Button>
                                ) : (
                                    <Button
                                        disabled={disabled}
                                        onClick={() => handleSubmit()}

                                        variant="contained"
                                        size={isDesktop ? "small" : 'large'}
                                    >
                                        {`${translate("commons.create")}`}
                                    </Button>
                                )}
                            </Box>
                        </Box>
                    </Card>
                </Container>
            }
        </Page >
    );
}