import { CustomField } from "src/@types/customField";
import { convert } from "./currency";
import { Currency, Lang } from "src/config";
import { CurrencyTypes } from "src/@types/vehicle";
import { Rate } from "src/@types/currency";
import { LocalizedString } from "src/@types/commons";

export type HandleCustomFieldsType = {
    event: any,
    //----------------------------------------
    getValues: any,
    setValue: any,
    setError: any,
    clearErrors: any,
    customFields: CustomField[],
    fieldsWithError: CustomField[],
    setFieldsWithError: (value: CustomField[]) => void,
    setErrorInCustom: (value: boolean) => void,
    //----------------------------------------
    dateValue?: string,
    dateFieldName?: string,
    currentCurrency?: Currency,
    rates?: Rate[]
}

//Gets the type of error to display and use (regex or required)
export const getErrorItem = (
    name: string,
    type: "required" | "regEx",
    //--------------------------------
    customFields: CustomField[]
) => {
    const correctCustom = customFields.find(customField => customField.id === name);

    if (type === "required") {
        return correctCustom!.required;
    } else if (correctCustom!.regEx) {
        return new RegExp(correctCustom!.regEx.slice(1, -1));
    }

    return new RegExp("");
};

//Removes customfield without a Error from the list of all customfields with errors
export const removeField = (
    name: string,
    //------------------------------------------------------
    fieldsWithError: CustomField[],
    setFieldsWithError: (value: CustomField[]) => void
) => {
    const newFields: CustomField[] = [];

    fieldsWithError.forEach(customField => {
        if (customField.id !== name) {
            newFields.push(customField);
        }

        setFieldsWithError(newFields);
    });
};

//Adds customfield with a Error to the list of all customfields with errors
export const addField = (
    name: string,
    //-----------------------------------------------------------
    fieldsWithError: CustomField[],
    setFieldsWithError: (value: CustomField[]) => void,
    customFields: CustomField[]
) => {

    let field = fieldsWithError.find(customField => customField.id === name);

    if (!field) {
        const newFields: CustomField[] = fieldsWithError;

        const correctCustom = customFields!.find(customField => customField.id === name);

        newFields.push(correctCustom!);
        setFieldsWithError(newFields);
    }
};

//Checks the regex
export const checkRegEx = (
    name: string,
    value: any,
    actualName: string,
    //----------------------------------------------------------
    customFields: CustomField[],
    fieldsWithError: CustomField[],
    setFieldsWithError: (value: CustomField[]) => void,
    setErrorInCustom: (value: boolean) => void,
    setError: any,
    clearErrors: any
) => {
    const regEx = getErrorItem(name, "regEx", customFields) as RegExp;

    if (regEx && value && !regEx.test(value)) {
        if (name === actualName) {
            setError(`customFields.${name}`, { type: 'regExError', message: '' });
        }
        addField(name, fieldsWithError, setFieldsWithError, customFields);
        setErrorInCustom(true);

    } else {
        clearErrors(`customFields.${name}`);
        removeField(name, fieldsWithError, setFieldsWithError);
    }
};

//Gets the type of the selected customfield
export const getCustomFieldType = (
    id: string,
    //-----------------
    customFields: CustomField[]
) => {
    const customField = customFields.find(x => x.enabled && x.entityType.toLowerCase() === "organization" && x.id === id);

    return customField?.type;
};

//Manages customfields update and validation
export const handleCustomFields = ({
    event,
    getValues,
    setValue,
    setError,
    clearErrors,
    customFields,
    fieldsWithError,
    setFieldsWithError,
    setErrorInCustom,
    dateValue,
    dateFieldName,
    currentCurrency,
    rates
}: HandleCustomFieldsType) => {

    let customFieldList = getValues(`customFields`) || {};

    let newVal = "";

    let fieldName = "";

    if (dateValue && dateFieldName) {
        newVal = dateValue;
        fieldName = dateFieldName;
    } else {
        newVal = event.target.type !== "checkbox" ? event.target.value : (event.target.checked ? "enabled" : "disabled");

        if (getCustomFieldType(event.target.id, customFields) === "Currency" && currentCurrency && rates) {

            newVal = Math.round(convert(Number(event.target.value), currentCurrency.label, CurrencyTypes.EUR, rates)).toString();
        }

        fieldName = event.target.name;
    }

    customFieldList[fieldName] = newVal;

    setValue("customFields", customFieldList);

    if (newVal === "" || (event && event.target.type === "checkbox" && !event.target.checked)) {
        addField(fieldName, fieldsWithError, setFieldsWithError, customFields);
    }

    if (fieldsWithError.length > 0) {
        fieldsWithError.forEach((field) => {
            const name = field.id;

            const required = getErrorItem(name, "required", customFields) as boolean;

            const value = getValues(`customFields.${name}`);

            if (required) {
                if (field.type === 'Checkbox') {
                    if (value !== "enabled") {
                        if (name === fieldName) {
                            setError(`customFields.${name}`, { type: 'required', message: '' });
                        }
                        addField(name, fieldsWithError, setFieldsWithError, customFields);
                        setErrorInCustom(true);
                    } else {
                        clearErrors(`customFields.${name}`);
                        removeField(name, fieldsWithError, setFieldsWithError);
                    }
                } else if (!value || value === "") {
                    if (name === fieldName) {
                        setError(`customFields.${name}`, { type: 'required', message: '' });
                    }
                    addField(name, fieldsWithError, setFieldsWithError, customFields);
                    setErrorInCustom(true);
                } else {
                    checkRegEx(name, value, fieldName, customFields, fieldsWithError, setFieldsWithError, setErrorInCustom, setError, clearErrors);
                }
            } else {
                checkRegEx(name, value, fieldName, customFields, fieldsWithError, setFieldsWithError, setErrorInCustom, setError, clearErrors);
            }
        });
    }

};

export function getLocalizedString(localizedString: LocalizedString[], currentLang: Lang): string {
    if (!localizedString || localizedString.length <= 0)
        return "—";

    let val = localizedString.find(v => v.culture === currentLang.value)?.text;

    if (val)
        return val;

    return localizedString[0].text || "—";
}

export function getCustomFieldLabel(customFields: CustomField[], id: string, currentLang: Lang): string {
    let custom = customFields.find(cus => cus.id === id);

    if (!custom)
        return "—";

    return getLocalizedString(custom.name, currentLang);

}