import axios from "axios";
import { UploadParams, UploadTypes } from "src/@types/request";
import { upload, uploadComplete } from "src/services/requestsServices";

export const getFileType = (type: string): string => {

    if (type.includes("image"))
        return "Image";

    if (type.includes("audio"))
        return "Audio";

    if (type.includes("video"))
        return "Video";

    return "Attach";
};

export interface FileWithSection extends File {
    section?: string
}

export async function handleUpload(files: FileWithSection[], id: string, setIsSendingLoading?: (v: boolean) => void) {

    let param: UploadParams = {

        medias: files.map((f, ind) => ({

            filename: ind + "-" + f.name,
            type: getFileType(f.type) as UploadTypes,
            section: f.section || ""

        }))
    };

    try {
        const val = await upload(id, param);

        const promises = files.map((async (file, ind) => {

            const url = val.data[ind + "-" + file.name];

            delete file.section;

            const bin = new Blob([file], { type: file.type });

            return axios.put(url, bin, {
                headers: { 'Content-Type': bin.type }
            });

        }));

        await Promise.all(promises);

        await uploadComplete(id);

        if (setIsSendingLoading)
            setIsSendingLoading(false);
    }
    catch (e) {
        if (setIsSendingLoading)
            setIsSendingLoading(false);
        throw e;
    }

}