import { Box, Stack, Switch, Typography } from "@mui/material";

interface BoxSwitchProps {
    label?: string,
    subtitle?: string,
    value?: boolean,
    onChange?: (val: boolean) => void
}

export default function BoxSwitch({ onChange, label, subtitle, value }: BoxSwitchProps) {

    return (
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
            <Stack>
                <Typography variant="h6">{label}</Typography>
                {!!subtitle && <Typography variant="body2">{subtitle}</Typography>}
            </Stack>
            <Switch
                value={value}
                onChange={(_, checked) => {
                    if (onChange)
                        onChange(checked);
                }}
            />
        </Box>);
}