import { useAuth } from "src/appHooks/useAuth";
import { useLocales } from "src/locales";
import { Typography, TypographyProps } from "@mui/material";

export interface DateZoneProps extends TypographyProps {
    date?: Date
    onlyDate?: boolean,
    onlyHour?: boolean,
    noSeconds?: boolean,
    shortMonth?: boolean
}

/**
 * component for formatting the date based on the current language
 * 
 * @param prop 
 * -date: date to be formatted must be a Date type
 * 
 * -onlyDate: remove the time section from the date
 * 
 * -onlyHour: remove the date section from the date
 * 
 * -noSeconds: remove the seconds from time
 * 
 * -shortMonth: shows the month in short term (Ex. 24 May 2020 - 21 Dec 2010)
 * @returns 
 */
export default function DateZone(prop: DateZoneProps) {

    const { date, onlyDate, onlyHour, noSeconds, shortMonth, ...TypographyProps } = prop;

    const { user } = useAuth();

    const { currentLang } = useLocales();

    const d = new Date(date || "");

    const options = {
        timeZone: user.timeZone,
    };

    if (onlyDate) {
        const date = {
            day: '2-digit',
            month: shortMonth ? 'short' : '2-digit',
            year: 'numeric'
        };

        Object.assign(options, date);
    }
    else if (onlyHour) {
        let hour = {
            hour: '2-digit',
            minute: '2-digit',
        } as any;

        if (!noSeconds) { hour = { ...hour, second: '2-digit' }; }

        Object.assign(options, hour);
    }
    else if (noSeconds) {
        const hour = {
            day: '2-digit',
            month: shortMonth ? 'short' : '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
        };

        Object.assign(options, hour);
    }

    return (
        <Typography {...TypographyProps}
            sx={{
                wordWrap: prop.noWrap ? 'inherit' : 'break-word !important',
                whiteSpace: prop.noWrap ? 'none' : 'pre-line !important'
            }}
        >
            {d.toLocaleString(currentLang.value, options) === 'Invalid Date' ? '—' : d.toLocaleString(currentLang.value, options)}
        </Typography>
    );
}

