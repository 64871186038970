import { Box, Button, Card, Modal, Typography } from "@mui/material";
import { isNumber, isString } from "lodash";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { resetErrorMessage } from "src/redux/modal/modal-slices";
import { dispatch, RootState } from "src/redux/store";

const errorMessages: Record<number, string> = {
    400: "badRequest",
    401: "unauthorized",
    403: "forbidden",
    404: "notFound",
    415: "unsupportedMediaType",
    422: "unprocessableContent",
    500: "internalServerError",
    502: "badGateway",
    503: "serviceUnavailable",
    504: "gatewayTimeout"
};

interface ErrorModalProps {
    toggleSave?: () => void
}

export default function ErrorModal({ toggleSave }: ErrorModalProps) {

    const { translate } = useLocales();

    const isDesktop = useResponsive('up', 'lg');

    const { error } = useSelector((state: RootState) => state.modal);

    const errorMessage = useMemo(() => {
        if (isString(error)) return error;

        if (isNumber(error)) {
            return translate(`error.message.${errorMessages[error]}`);
        }

        return translate('commons.error');

    }, [error, translate]);

    return (
        <Modal open={!!error} onClose={() => dispatch(resetErrorMessage())}>
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: isDesktop ? '25vw' : '70vw',
                p: isDesktop ? 4 : 2,
            }}>
                <Box sx={{ textAlign: "center" }}>

                    <Box component="img" src="/assets/illustrations/illustration_empty_mail.svg" sx={{ width: "45%", m: "auto" }} />

                    <Typography variant="h6" sx={{ mt: 3 }}>
                        {translate(`commons.wrongError`)}
                    </Typography>

                    <Typography variant='body1'>
                        {errorMessage}
                    </Typography>

                    <Button
                        variant="contained"
                        size={isDesktop ? "small" : "medium"}
                        onClick={() => {
                            toggleSave && toggleSave();
                            dispatch(resetErrorMessage());
                        }}
                        sx={{ mt: 3, borderRadius: "100px" }}
                    >
                        {translate('commons.close')}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}
