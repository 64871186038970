import { Autocomplete, Box, Button, Card, FormControlLabel, IconButton, Switch, TextField, Typography } from "@mui/material";
import { ChangeEvent, useEffect, useRef, useState, UIEvent, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { Request, RequestType } from "src/@types/request";
import { CancelRounded } from "@mui/icons-material";
import { FileWithSection } from "src/appUtils/requestUpload";
import { useSelector } from "react-redux";
import { RootState, dispatch } from "src/redux/store";
import { containerOperations } from "src/redux/container";
import { ContainerTemplateSearchResult } from "src/@types/container";
import { debounce } from "lodash";
import { PATH_DASHBOARD } from "src/routes/paths";
import AddIcon from '@mui/icons-material/Add';

interface WarehouseNewArticleProps {
    handleFlag: (flags: any) => void,
    changeNote: (val: string) => void,
    changeContainer: (val: string | null) => void,
    changeFile: (val: File[]) => void,
    request: Request | null,
    images: FileWithSection[]
}

const typographySubtitle = {
    color: '#6D6D6D',
    fontSize: 12,
    fontFamily: 'Public Sans',
    fontWeight: '700',
    textTransform: 'uppercase',
    wordWrap: 'break-word'
};

export default function WarehouseNewArticle({ handleFlag, changeNote, changeContainer, request, images, changeFile }: WarehouseNewArticleProps) {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    const [files, setFiles] = useState<FileWithSection[]>(images);

    const [Url, SetUrl] = useState("");

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            setFiles((prev) => prev.concat(Object.assign(acceptedFiles, { section: 'warehouse' })));
            SetUrl(URL.createObjectURL(acceptedFiles[0]));
        },
        accept: {
            'image/*': ['.png', '.jpeg', '.jpg']
        },
        maxSize: 5000000
    });

    useEffect(() => {
        changeFile(files);
    }, [changeFile, files]);

    const handleContainer = (container: ContainerTemplateSearchResult | null) => {
        changeContainer(container?.id || null);
    };

    const [flags, setFlags] = useState({ reasonAccepted: true, partConditionAccepted: true, administrativeCheckAccepted: true, partAccepted: true });

    const handleFlags = (e: ChangeEvent<HTMLInputElement>) => {

        switch (e.target.name) {
            case "reasonCode":
                setFlags((prev) => ({ ...prev, reasonAccepted: e.target.checked }));
                break;
            case "goodCondition":
                setFlags((prev) => ({ ...prev, partConditionAccepted: e.target.checked }));
                break;
            case "adminCorrect":
                setFlags((prev) => ({ ...prev, administrativeCheckAccepted: e.target.checked }));
                break;
            case "correctPart":
                setFlags((prev) => ({ ...prev, partAccepted: e.target.checked }));
                break;

        }
    };

    useEffect(() => {
        handleFlag(flags);
    }, [flags, handleFlag]);

    return (
        <Box sx={{ m: 3 }}>
            <Typography sx={typographySubtitle}>{`${translate('request.checkCondition')}`}</Typography>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
                width: '100%',
                my: 2,
            }}
            >
                <FormControlLabel
                    label={`${translate('request.correctPart')}`}
                    control={<Switch checked={flags.partAccepted} name="correctPart" onChange={handleFlags} />}
                />
                <FormControlLabel
                    label={`${translate('request.correctCode')}`}
                    control={<Switch checked={flags.reasonAccepted} name="reasonCode" onChange={handleFlags} />}
                />
                <FormControlLabel
                    label={`${translate('request.goodCondition')}`}
                    control={<Switch checked={flags.partConditionAccepted} name="goodCondition" onChange={handleFlags} />}
                />

                {
                    !flags.partConditionAccepted &&
                    <Box>
                        {files.length === 0 ?
                            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                <Typography variant="body2">{`${translate('request.form.addPicture')}`}</Typography>
                                <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: isDesktop ? '50%' : '75%', p: 3, m: 2, mx: isDesktop ? 2 : 'auto' }}>
                                    <Box sx={{ display: 'flex', p: 1, borderRadius: '100px', border: '1px rgba(145, 158, 171, 0.32) dotted', width: 'min-content' }}>
                                        <div
                                            {...getRootProps({
                                                style: {
                                                    display: 'flex',
                                                    padding: '20px',
                                                    height: '150px',
                                                    width: '150px',
                                                    borderRadius: '100px',
                                                    background: '#F4F6F8',
                                                    color: '#bdbdbd',
                                                    outline: 'none',
                                                    cursor: 'pointer',
                                                    flexDirection: 'column',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    p: 10,
                                                    border: '1px rgba(145, 158, 171, 0.32) dotted'
                                                },
                                            })}
                                        >
                                            <input {...getInputProps()} />
                                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <AddAPhotoIcon />
                                                <Typography variant="caption">{`${translate('commons.uploadPhoto')}`}</Typography>
                                            </Box>
                                        </div>

                                    </Box>

                                    <Typography
                                        sx={{ textAlign: 'center', color: '#6D6D6D', fontSize: 12, fontFamily: 'Public Sans', fontWeight: '400', wordWrap: 'break-word' }}>
                                        {`${translate('request.messages.allowedFile')}`}<br />{`${translate('request.messages.maxSize')}`}
                                    </Typography>
                                </Card>

                            </Box>
                            :
                            <Box>
                                <Box sx={{ position: 'relative', borderRadius: '10px', maxWidth: '150px' }}>
                                    <img
                                        style={{ maxHeight: '150px', maxWidth: '150px', borderRadius: '10px', border: '1px ridge', borderColor: '#d3d4d4' }}
                                        alt={files[0].name}
                                        src={Url}
                                        onLoad={() => URL.revokeObjectURL(Url)}

                                    />
                                    <Box sx={{ position: 'absolute', top: 0, right: 0 }}>
                                        <IconButton
                                            aria-label="delete"
                                            size="large"
                                            sx={{
                                                padding: '5px',
                                                cursor: "pointer"
                                            }}
                                            onClick={() => {
                                                setFiles([]);
                                            }}
                                        >
                                            <CancelRounded sx={{ color: 'text.disabled' }} />
                                        </IconButton>
                                    </Box>
                                </Box>
                            </Box>

                        }
                    </Box>
                }
                <FormControlLabel
                    label={`${translate(`request.adminCorrect`)}`}
                    control={<Switch checked={flags.administrativeCheckAccepted} name="adminCorrect" onChange={handleFlags} />}
                />

            </Box>
            {request && request.approverNotes!.approvedType === "ReturnToSupplier" &&
                <ContainerSection onContainerChange={handleContainer} contentTypes={request.requestType} />
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 3 }}>
                <Typography sx={typographySubtitle}>{`${translate(`commons.note`)}`}</Typography>
                <TextField
                    name="description"
                    variant="outlined"
                    value={note}
                    onChange={(e) => {
                        setNote(e.target.value);
                        changeNote(e.target.value);
                    }}
                    rows={4}
                    multiline
                    fullWidth
                    type="text"
                    placeholder={`${translate(`commons.insertDesc`)}`}
                    sx={{
                        width: '100%',
                    }}
                />
            </Box>
            <Button variant="text">{`${translate(`request.exportCsv`)}`}</Button>
        </Box >);
}

interface ContainerSectionProps {
    onContainerChange: (container: ContainerTemplateSearchResult | null) => void,
    contentTypes?: RequestType,
}

export function ContainerSection({ onContainerChange, contentTypes }: ContainerSectionProps) {

    const { translate } = useLocales();

    const [reopen, setReopen] = useState(false);

    const handleNewContainer = () => {
        window.open(PATH_DASHBOARD.containers.templates.list, "_blank");
        setReopen(prev => !prev);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, my: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography sx={typographySubtitle}>{`${translate(`request.selectContainer`)}`}</Typography>
                <Button
                    sx={{ ml: 'auto' }}
                    variant="text"
                    startIcon={<AddIcon />}
                    onClick={() => handleNewContainer()}
                >
                    {`${translate('containers.addContainer')}`}
                </Button>
            </Box>
            <ContainerAutocomplete onContainerChange={onContainerChange} contentTypes={contentTypes} reopen={reopen} />
        </Box>);
}

interface ContainerAutocompleteProps {
    contentTypes?: RequestType,
    onContainerChange: (container: ContainerTemplateSearchResult | null) => void,
    reopen?: boolean
}

export function ContainerAutocomplete({ contentTypes, onContainerChange, reopen }: ContainerAutocompleteProps) {

    const { isLoading } = useSelector((state: RootState) => state.container);

    const [containersList, setContainersList] = useState<ContainerTemplateSearchResult[]>([]);

    const [error, setError] = useState("");

    const [infPage, setInfPage] = useState(0);

    const [hasNextPage, setHasNextPage] = useState(false);

    const [all, setAll] = useState<string>();

    const [container, setContainer] = useState<ContainerTemplateSearchResult | null>(null);

    const { translate } = useLocales();

    const firstRender = useRef(true);

    useEffect(() => {
        firstRender.current = true;
    }, [reopen]);

    const handleSearch = useCallback(async (name: string) => {

        const data = await dispatch(containerOperations.searchContainer({
            filters: {
                pageIndex: 0,
                pageSize: 10,
                status: 'Open',
                contentTypes: contentTypes ? [contentTypes] : undefined,
                name: name || undefined
            },
            isIstance: false
        })).unwrap();

        setContainersList(data.items);

        setInfPage(data.pageIndex);

        setHasNextPage(!!data.hasNextPage);

        setAll(name);
    }, [contentTypes]);

    const debounced = useRef(debounce((name: string) => handleSearch(name), 500));

    const getPage = (e: UIEvent<HTMLUListElement>) => {

        const { scrollTop, scrollHeight, offsetHeight } = e.currentTarget;

        //check if scrollbar reached bottom and if there are other elements

        if (Math.abs(scrollHeight - (scrollTop + offsetHeight)) < 1 && hasNextPage && !isLoading) {

            dispatch(containerOperations.searchContainer({ filters: { pageIndex: infPage + 1, pageSize: 15, all: all, status: 'Open', contentTypes: contentTypes ? [contentTypes] : undefined }, isIstance: false }))
                .unwrap()
                .then((v) => {
                    setContainersList(v.items);

                    setHasNextPage(!!v.hasNextPage);

                    setInfPage(v.pageIndex);
                });
        }
    };

    return <Autocomplete
        options={containersList}
        loading={isLoading}

        value={container}
        renderInput={(params) =>
            <TextField
                error={!!error}
                helperText={!!error && error}
                {...params}
                label={`${translate(`request.containerCode`)}`}

                sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
            />
        }
        getOptionLabel={(option) =>
            typeof option !== 'string' ? option.name : ''
        }
        onInputChange={(_, v) => debounced.current(v)}
        onChange={(_, value) => {

            setContainer(value);
            onContainerChange(value);

            if (!value)
                setError(`${translate('commons.validation.requiredField')}`);
            else
                setError('');
        }}
        filterOptions={options => options}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        ListboxProps={{
            sx: { maxHeight: '30vh' },
            onScroll: (e) => {
                getPage(e);
            }
        }}
        renderOption={(props, option) => {
            return (
                <li
                    {...props}
                    key={option.id}
                >
                    <Typography>
                        {option.name}
                    </Typography>
                </li>
            );
        }}
        onOpen={() => {
            if (firstRender.current) {
                handleSearch("");

                firstRender.current = false;
            }
        }}
    />;

}