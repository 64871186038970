import { Card, Divider, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import StepButtons from "src/components/buttons/StepButtons";
import { Upload } from "src/components/upload";

interface StockAvailabilityStepProps {
    changeStep: Dispatch<SetStateAction<number>>
}

export default function StockAvailabilityStep({ changeStep }: StockAvailabilityStepProps) {

    //const { translate } = useLocales();

    const [file, setFile] = useState<File | null>(null);

    return (
        <Card sx={{ p: 3 }}>
            <Stack sx={{ my: 2 }}>
                <Typography variant="h6">Stock Availability</Typography>
                <Typography variant="body2">[Upload the file with all available quantities.]</Typography>
            </Stack>
            <Upload onDropAccepted={(f) => setFile(f[0] ?? null)} file={file} />
            <Divider sx={{ my: 3 }} />
            <StepButtons changeStep={changeStep} />
        </Card>
    );
}