import Page from 'src/appComponents/Page';
import { PATH_DASHBOARD } from 'src/routes/paths';
import HeaderBreadcrumbs from '../../../components/custom-breadcrumbs';
import { Container, Step, StepLabel, Stepper } from '@mui/material';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useParams } from 'react-router';
import { useMemo, useState } from 'react';
import useResponsive from 'src/hooks/useResponsive';
import DetailStep from 'src/sections/@dashboard/webshop/newWebshop/DetailStep';
import CustomerStep from 'src/sections/@dashboard/webshop/newWebshop/CustomerStep';
import DiscountModelStep from 'src/sections/@dashboard/webshop/newWebshop/DiscountModelStep';
import StockAvailabilityStep from 'src/sections/@dashboard/webshop/newWebshop/StockAvailabilityStep';
import TermsConditionStep from 'src/sections/@dashboard/webshop/newWebshop/TermsConditionStep';

export default function NewEditWebshop() {

    const { translate } = useLocales();

    const { themeStretch } = useSettingsContext();

    const isDesktop = useResponsive('up', 'lg');

    const [step, setStep] = useState(0);

    const { id: isEdit } = useParams();
    //TODO:traduzioni

    const STEPS = useMemo(() => [
        "Details",
        "Customers",
        "Discount Models",
        "Stock Availability",
        "Terms and conditions",
        "Complete"
    ], []);

    const STEPS_COMPONENT = [
        <DetailStep key={0} changeStep={setStep} />,
        <CustomerStep key={1} changeStep={setStep} />,
        <DiscountModelStep key={2} changeStep={setStep} />,
        <StockAvailabilityStep key={3} changeStep={setStep} />,
        <TermsConditionStep key={4} changeStep={setStep} />
    ];

    return (
        <Page title={`new webshop`}>

            <Container maxWidth={themeStretch ? false : 'lg'}>

                {/* 
            <SaveModal
                toggle={toggleSave}
                isOpen={isOpenSave}
                handleSave={handleSaveBrand}
                saveText={`${translate('organization.form.brands.saveConfirm')}`}
            /> */}

                <HeaderBreadcrumbs
                    heading={!isEdit ? `new webshop` : `edit webshop`}
                    links={[
                        { name: `${translate("commons.home")}`, href: PATH_DASHBOARD.root },
                        { name: `${translate("webshop")}`, previousPage: 1 },
                        { name: !isEdit ? `new webshop` : `edit webshop` },
                    ]}
                />
                <Stepper
                    sx={{ mb: 2, px: isDesktop ? 5 : 2, }}
                    activeStep={step}
                    alternativeLabel
                >
                    {STEPS.map((st) => (
                        <Step key={st}>
                            <StepLabel>{st}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {STEPS_COMPONENT[step]}
            </Container>
        </Page>);
}