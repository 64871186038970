import { Box, Stack, Badge, Button, Drawer, Divider, IconButton, Typography, ButtonGroup, TextField, MenuItem, FormControlLabel, Switch, FormGroup } from '@mui/material';
import FilterListIcon from '@mui/icons-material/FilterList';
import { NAV } from 'src/config';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import CustomFieldSidebarRenderer from 'src/utils/CustomFieldSidebarRenderer';
import { useFormContext } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocales } from 'src/locales';
import PermissionBasedGuard from 'src/guards/PermissionBasedGuard';
import { ClusterPermissionTypes, OrganizationPermissionTypes } from 'src/@types/permissions';
import { FilterListType } from 'src/@types/list';
import { isArray, isEqual } from 'lodash';
import { RHFSelect } from 'src/components/hook-form';
import SidebarAutocomplete from './SidebarAutocomplete';
import SidebarSlider, { getSliderFilterNames } from './SidebarSlider';
import { AxiosResponse } from 'axios';
import { SelectItem, Tag } from 'src/@types/commons';
import SidebarMultiSelect from './SidebarMultiSelect';

interface GenericFilterSidebarProps {
    isOpen: boolean,
    onOpen: VoidFunction,
    onClose: VoidFunction,
    onFilter: VoidFunction,
    onResetAll: VoidFunction,
    resetTrigger: boolean,
    defaultFilters: any,
    filterValues: any,
    filterList: FilterListType[],
    isDefault: (filter: any, controller?: any) => boolean,
    resetFormElement: string,
    setResetFormElement: (value: string) => void,
    setShowSummary?: (value: boolean) => void,
    customfieldContext?: string[]
}

export default function GenericFilterSidebar({ isOpen, onOpen, onClose, onFilter, onResetAll, defaultFilters, resetTrigger, filterValues, filterList, isDefault, resetFormElement, setResetFormElement, setShowSummary, customfieldContext }: GenericFilterSidebarProps) {

    const { setValue, getValues } = useFormContext();

    const { translate } = useLocales();

    const [sidebarValues, setSidebarValues] = useState(filterValues);

    const [sidebarValuesAfterFilter, setSidebarValuesAfterFilter] = useState(filterValues);

    const [inEditAutocomplete, setInEditAutocomplete] = useState<boolean>(false);

    useEffect(() => {
        if (resetTrigger) setSidebarValues(defaultFilters);
    }, [defaultFilters, resetTrigger]);

    const handleChangeValues = useCallback((sidebarValues: any, newValue: any, name: string) => {

        const valuesCopy = JSON.parse(JSON.stringify(sidebarValues));

        if (filterList.find((filter) => filter.name === name && filter.type === "Slider")) {

            const [minLabel, maxLabel] = getSliderFilterNames(name);

            valuesCopy[minLabel] = newValue[0];

            valuesCopy[maxLabel] = newValue[1];
        } else if (filterList.find((filter) => filter.name === name && filter.type === "MultiSelect")) {

            if (isArray(newValue)) {
                const indexOfAll = newValue.indexOf('All');

                if (indexOfAll >= 0) {
                    if (indexOfAll === newValue.length - 1) valuesCopy[name] = defaultFilters[name];
                    else {
                        newValue.splice(indexOfAll, 1);
                        valuesCopy[name] = newValue;
                    }
                } else if (newValue.length > 0) {
                    valuesCopy[name] = newValue;
                } else valuesCopy[name] = defaultFilters[name];
            }
        } else {
            valuesCopy[name] = newValue;
        }

        setSidebarValues(valuesCopy);
    }, [defaultFilters, filterList]);

    useEffect(() => {
        if (resetFormElement) {

            if (filterList.find((filter) => filter.name === resetFormElement && filter.type === "Slider")) {
                const [minLabel, maxLabel] = getSliderFilterNames(resetFormElement);

                handleChangeValues(sidebarValues, [defaultFilters[minLabel], defaultFilters[maxLabel]], resetFormElement);
            } else {
                handleChangeValues(sidebarValues, defaultFilters[resetFormElement], resetFormElement);
            }
            setResetFormElement("");
        }
    }, [resetFormElement, handleChangeValues, sidebarValues, setResetFormElement, defaultFilters, filterList]);

    useEffect(() => {
        if (!isEqual(filterValues, sidebarValues)) {
            setSidebarValues(filterValues);
            setSidebarValuesAfterFilter(filterValues);
        }
    }, [filterValues]);

    const setAllValues = () => {
        for (const [key, value] of Object.entries(sidebarValues)) {

            if (key === "all") {
                setValue(key, defaultFilters[key]);
            } else {
                const formValue = getValues(key);

                if (!isEqual(value, formValue)) {
                    setValue(key, value);
                }
            }
        }
        setSidebarValuesAfterFilter(sidebarValues);
    };

    const handleOnFilter = () => {
        setAllValues();
        onFilter();
        if (setShowSummary) setShowSummary(true);
    };

    const handleCloseFilter = () => {
        if (resetTrigger) {
            setSidebarValues(defaultFilters);
        } else if (!isEqual(sidebarValues, sidebarValuesAfterFilter)) {
            setSidebarValues(sidebarValuesAfterFilter);
        }
        onClose();
    };

    const handleOnResetAll = () => {
        setSidebarValues(defaultFilters);
        onResetAll();
    };

    const dotControllerFilters = useMemo(() => {
        if (sidebarValuesAfterFilter) return sidebarValuesAfterFilter;

        return defaultFilters;
    }, [defaultFilters, sidebarValuesAfterFilter]);

    const filtersComponents = useMemo(() => ({
        Section: (label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                        {label}
                    </Typography>
                </PermissionBasedGuard>
            );

            return (
                <Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
                    {label}
                </Typography>
            );
        },
        TextField: (name: string, label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    <TextField
                        name={name}
                        label={label}
                        variant="outlined"
                        value={sidebarValues[name]}
                        onChange={(event) => handleChangeValues(sidebarValues, event.target.value, name)}
                    />
                </PermissionBasedGuard>
            );

            return (
                <TextField
                    name={name}
                    label={label}
                    variant="outlined"
                    value={sidebarValues[name]}
                    onChange={(event) => handleChangeValues(sidebarValues, event.target.value, name)}
                />
            );
        },
        Select: (name: string, label: string, options: SelectItem[], permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    <RHFSelect
                        name={name}
                        label={label}
                        value={sidebarValues[name]}
                        onChange={(event) => handleChangeValues(sidebarValues, event.target.value, name)}
                    >
                        <MenuItem key={"all"} value={"all"}>
                            {`${translate('commons.all')}`}
                        </MenuItem>
                        {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </RHFSelect>
                </PermissionBasedGuard>
            );

            return (
                <RHFSelect
                    name={name}
                    label={label}
                    value={sidebarValues[name]}
                    onChange={(event) => handleChangeValues(sidebarValues, event.target.value, name)}
                >
                    <MenuItem key={"all"} value={"all"}>
                        {`${translate('commons.all')}`}
                    </MenuItem>
                    {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </RHFSelect>
            );
        },
        MultiSelect: (name: string, label: string, options: SelectItem[], permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    <SidebarMultiSelect
                        name={name}
                        label={label}
                        options={options}
                        sidebarValues={sidebarValues}
                        defaultFilterValues={defaultFilters}
                        handleChangeValues={handleChangeValues}
                    />
                </PermissionBasedGuard>
            );

            return (
                <SidebarMultiSelect
                    name={name}
                    label={label}
                    options={options}
                    sidebarValues={sidebarValues}
                    defaultFilterValues={defaultFilters}
                    handleChangeValues={handleChangeValues}
                />
            );
        },
        Autocomplete: (name: string, label: string,
            service: (tag: string, size: number) => Promise<AxiosResponse<Tag[]>>,
            permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    <SidebarAutocomplete
                        name={name}
                        label={label}
                        defaultAutocompleteValues={sidebarValues[name]?.length ? sidebarValues[name] : getValues(name)}
                        filterValuesFromForm={sidebarValues}
                        handleChangeValues={handleChangeValues}
                        inEditTags={inEditAutocomplete}
                        setInEditTags={setInEditAutocomplete}
                        service={service}
                    />
                </PermissionBasedGuard>
            );

            return (
                <SidebarAutocomplete
                    name={name}
                    label={label}
                    defaultAutocompleteValues={sidebarValues[name]?.length ? sidebarValues[name] : getValues(name)}
                    filterValuesFromForm={sidebarValues}
                    handleChangeValues={handleChangeValues}
                    inEditTags={inEditAutocomplete}
                    setInEditTags={setInEditAutocomplete}
                    service={service}
                />
            );
        },
        Slider: (name: string, label: string, stepValue: number, stepsNumber: number, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[]) => {

            if (permissions) return (
                <PermissionBasedGuard permissions={permissions}>
                    <SidebarSlider
                        name={name}
                        label={label}
                        sidebarValues={sidebarValues}
                        defaultFilterValues={defaultFilters}
                        stepValue={stepValue}
                        stepsNumber={stepsNumber}
                        handleChangeValues={handleChangeValues}
                    />
                </PermissionBasedGuard>
            );

            return (
                <SidebarSlider
                    name={name}
                    label={label}
                    sidebarValues={sidebarValues}
                    defaultFilterValues={defaultFilters}
                    stepValue={stepValue}
                    stepsNumber={stepsNumber}
                    handleChangeValues={handleChangeValues}
                />
            );
        },
        SwitchGroup: (switchesInfo: { name: string, label: string, permissions?: (ClusterPermissionTypes | OrganizationPermissionTypes)[] }[]) => {

            const anyPermissions = switchesInfo.find((info) => info.permissions);

            if (anyPermissions) return (
                <FormGroup>
                    {switchesInfo.map((info, index) =>
                        <PermissionBasedGuard permissions={info.permissions} key={"switch." + index}>
                            <FormControlLabel
                                control={
                                    <Switch
                                        onChange={(event) => handleChangeValues(sidebarValues, event.target.checked, info.name)}
                                        checked={sidebarValues[info.name]}
                                    />
                                }
                                label={info.label}
                            />
                        </PermissionBasedGuard>
                    )}
                </FormGroup>
            );

            return (
                <FormGroup>
                    {switchesInfo.map((info, index) =>
                        <FormControlLabel
                            key={"switch." + index}
                            control={
                                <Switch
                                    onChange={(event) => handleChangeValues(sidebarValues, event.target.checked, info.name)}
                                    checked={sidebarValues[info.name]}
                                />
                            }
                            label={info.label}
                        />
                    )}
                </FormGroup>
            );
        }
    }), [defaultFilters, getValues, handleChangeValues, inEditAutocomplete, sidebarValues, translate]);

    return (
        <>
            <Button variant="outlined" size="small" onClick={onOpen}>
                <FilterListIcon />
            </Button>

            <Drawer
                anchor="right"
                open={isOpen}
                onClose={handleCloseFilter}
                PaperProps={{
                    sx: { width: NAV.W_DASHBOARD },
                    style: { boxShadow: 'none' }
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ px: 1, py: 2 }}
                >
                    <Typography variant="subtitle1" sx={{ ml: 1 }}>
                        {`${translate('commons.filters')}`}
                    </Typography>

                    <IconButton onClick={handleCloseFilter}>
                        <Iconify icon={'eva:close-fill'} width={20} height={20} />
                    </IconButton>
                </Stack>

                <Divider />

                <Scrollbar>

                    <Stack spacing={3} sx={{ p: 2.5 }}>

                        {filterList.map((filter, index) => {

                            let JSXFilter: JSX.Element = <></>;

                            switch (filter.type) {
                                case 'Section':
                                    JSXFilter = filtersComponents.Section(filter.label, filter.permissions);
                                    break;
                                case 'TextField':
                                    JSXFilter = filtersComponents.TextField(filter.name, filter.label, filter.permissions);
                                    break;
                                case 'Select':
                                    if (filter.options)
                                        JSXFilter = filtersComponents.Select(filter.name, filter.label, filter.options, filter.permissions);
                                    break;
                                case 'MultiSelect':
                                    if (filter.options)
                                        JSXFilter = filtersComponents.MultiSelect(filter.name, filter.label, filter.options, filter.permissions);
                                    break;
                                case 'Autocomplete':
                                    if (filter.service)
                                        JSXFilter = filtersComponents.Autocomplete(filter.name, filter.label, filter.service, filter.permissions);
                                    break;
                                case 'Slider':
                                    if (filter.stepValue && filter.stepsNumber)
                                        JSXFilter = filtersComponents.Slider(filter.name, filter.label, filter.stepValue, filter.stepsNumber, filter.permissions);
                                    break;
                                case 'SwitchGroup':
                                    if (filter.switchesInfo)
                                        JSXFilter = filtersComponents.SwitchGroup(filter.switchesInfo);
                                    break;
                            }

                            return <Stack key={filter.name + "." + index} spacing={1}>{JSXFilter}</Stack>;
                        })}

                        {customfieldContext &&
                            <CustomFieldSidebarRenderer
                                context={customfieldContext}
                                filterValues={sidebarValues}
                                setFilterValues={setSidebarValues}
                            />
                        }

                    </Stack>
                </Scrollbar>

                <Box sx={{ p: 1, pb: 2, position: 'relative' }}>

                    <Badge
                        color="error"
                        variant="dot"
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                        invisible={isDefault(sidebarValues, dotControllerFilters)}
                        sx={{ width: 1, right: 25, top: 20, position: 'absolute' }}
                    />

                    <ButtonGroup variant="outlined" aria-label="outlined button group" fullWidth>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnResetAll}
                            startIcon={<Iconify icon="ic:round-clear-all" />}
                            sx={{ px: 1 }}
                        >
                            {`${translate('commons.clear')}`}
                        </Button>
                        <Button
                            fullWidth
                            size="large"
                            type="submit"
                            color="inherit"
                            variant="outlined"
                            onClick={handleOnFilter}
                            startIcon={<Iconify icon="eva:search-fill" />}
                            disabled={inEditAutocomplete}
                            sx={{ px: 1 }}
                        >
                            {`${translate('commons.apply')}`}
                        </Button>
                    </ButtonGroup>

                </Box>

            </Drawer>
        </>
    );
}