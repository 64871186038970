import { Box, Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Stack, TextField, Tooltip } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocales } from 'src/locales';
import { GridColumnVisibilityModel } from '@mui/x-data-grid';
import { ToolbarSearchFilters } from 'src/@types/commons';
import VisibilityModelComponent from './VisibilityModelComponent';
import { useFormContext } from 'react-hook-form';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { isEqual, remove } from 'lodash';

interface GenericFilterToolbarProps {
    filterValues: any,
    defaultFiltersValues: any,
    onSearch: VoidFunction,
    onResetAll: VoidFunction,
    optionsFields?: ToolbarSearchFilters[],
    showDates?: boolean,
    datesNames?: [string, string],
    showSummary?: boolean,
    setShowSummary?: (value: boolean) => void,
    editableColumns?: boolean,
    columnVisibility?: GridColumnVisibilityModel,
    setColumnVisibility?: (model: GridColumnVisibilityModel) => void
}

const DEFAULT_DATE_ERRORS = {
    fromFormat: false,
    fromMissing: false,
    toFormat: false,
    toMissing: false,
    toInvalid: false
};

export default function GenericFilterToolbar({ filterValues, defaultFiltersValues, onSearch, onResetAll, optionsFields, showDates, editableColumns, showSummary, setShowSummary, columnVisibility, setColumnVisibility, datesNames }: GenericFilterToolbarProps) {

    const { translate } = useLocales();

    const { setValue } = useFormContext();

    const [resetFilter, setResetFilter] = useState(false);

    const [openTooltip, setOpenTooltip] = useState(false);

    const [showDeleteButton, setShowDeleteButton] = useState(false);

    const [datesErrors, setDatesErrors] = useState(DEFAULT_DATE_ERRORS);

    const [toolbarFilterValues, setToolbarFilterValues] = useState({ ...filterValues, searchType: optionsFields ? optionsFields[0].key : "all", searchValue: "" });

    //-----------------------------------------------------------------------------------

    //---- IS DEFAULT - START ----//
    const fullKeysToRemove: string[] = useMemo(() => ["pageIndex", "pageSize", "sortField", "sortAscending"], []);

    const fullKeyRemover = useCallback((key: string) => { return !(fullKeysToRemove.includes(key)); }, [fullKeysToRemove]);

    const isDefaultSimple = useCallback((filter: any) => {
        const found = remove(Object.keys(filter), fullKeyRemover)
            .find((element) => (filter[element] && filter[element] !== defaultFiltersValues[element]));

        return (!found);
    }, [defaultFiltersValues, fullKeyRemover]);
    //---- IS DEFAULT - END ----//

    const searchServiceAndValue = useCallback(() => {

        let returnOption: string;

        returnOption = optionsFields?.find((option) => filterValues[option.key])?.key ?? "";

        return returnOption ? returnOption : (optionsFields ? optionsFields[0].key : "all");

    }, [filterValues, optionsFields]);

    useEffect(() => {

        if (!isDefaultSimple(filterValues) && !showSummary) {

            const searchType = searchServiceAndValue();

            let searchValue = filterValues[searchType];

            setToolbarFilterValues({
                ...filterValues,
                searchType: searchType,
                searchValue: searchValue || "",
                from: filterValues.from || defaultFiltersValues.from,
                to: filterValues.to || defaultFiltersValues.to
            });

            if (!filterValues.from) {
                setDatesErrors(DEFAULT_DATE_ERRORS);
            }
            if (filterValues.from || filterValues[searchType]) {
                setShowDeleteButton(true);
            } else {
                setShowDeleteButton(false);
            }
        } else {
            setToolbarFilterValues({
                ...filterValues,
                searchType: optionsFields ? optionsFields[0].key : "all",
                searchValue: "",
                from: defaultFiltersValues.from,
                to: defaultFiltersValues.to
            });
            setDatesErrors(DEFAULT_DATE_ERRORS);
            setShowDeleteButton(false);
        }

    }, [defaultFiltersValues, filterValues, isDefaultSimple, optionsFields, searchServiceAndValue, showSummary]); //filterValues, isDefaultx

    useEffect(() => {
        if (!toolbarFilterValues.from && !toolbarFilterValues.to && !isEqual(DEFAULT_DATE_ERRORS, datesErrors)) {
            setDatesErrors(DEFAULT_DATE_ERRORS);
        }
    }, [datesErrors, toolbarFilterValues]);

    useEffect(() => {
        if (resetFilter) {
            setToolbarFilterValues({
                ...defaultFiltersValues,
                searchType: optionsFields ? optionsFields[0].key : "all",
                searchValue: "",
                from: defaultFiltersValues.from,
                to: defaultFiltersValues.to
            });
            setResetFilter(false);
            onResetAll();
        }
    }, [resetFilter, onResetAll, defaultFiltersValues, optionsFields]);

    //-----------------------------------------------------------------------------------

    const handleChangeValues = (newValue: any, name: string) => {

        const valuesCopy = JSON.parse(JSON.stringify(toolbarFilterValues));

        valuesCopy[name] = newValue;

        setToolbarFilterValues(valuesCopy);
    };

    const setAllValues = () => {

        const optionsKeys = optionsFields?.map(({ key, label }) => key) || [];

        for (const [key, value] of Object.entries(toolbarFilterValues)) {

            if (key !== "searchType" && key !== "searchValue") {

                if (key === "from" || key === "to") {
                    setValue(key, value);
                } else if (toolbarFilterValues.searchType === key) {
                    /*
                    if (key === "itemCustomFields") {
                        setValue(key, { "stellantis-europe-spareparts-deposit-number": toolbarFilterValues.searchValue });
                    } else 
                     */
                    setValue(key, toolbarFilterValues.searchValue);
                } else if (optionsKeys.includes(key)) {
                    setValue(key, defaultFiltersValues[key]);
                }
            }
        }
    };

    const handleSearch = () => {
        if (toolbarFilterValues.from && !toolbarFilterValues.to) {
            setDatesErrors({ ...datesErrors, toMissing: true });
        } else if (!toolbarFilterValues.from && toolbarFilterValues.to) {
            setDatesErrors({ ...datesErrors, fromMissing: true });
        } else if (toolbarFilterValues.from || toolbarFilterValues.searchValue) {
            setAllValues();
            onSearch();
            setShowDeleteButton(true);
            if (setShowSummary) setShowSummary(false);
        }
    };

    const handleDelete = () => {
        setResetFilter(true);
        setDatesErrors(DEFAULT_DATE_ERRORS);
        setShowDeleteButton(false);
    };

    //----------DATES MANAGEMENT-----------//

    const handleChangeFrom = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            handleChangeValues(date.format('YYYY-MM-DD'), "from");

            let toInvalidCheck = false;

            if (toolbarFilterValues.to && dayjs(toolbarFilterValues.to).diff(date, "d") < 0) {
                toInvalidCheck = true;
            }

            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: toInvalidCheck });
        }
        else {
            setDatesErrors({ ...datesErrors, fromFormat: true });
        }
    };

    const handleChangeTo = (date: dayjs.Dayjs) => {

        if (date.toString() !== 'Invalid Date') {

            if (date.diff(toolbarFilterValues.from, "d") < 0) {
                setDatesErrors({ ...datesErrors, toInvalid: true, toFormat: false, toMissing: false });
            } else {
                handleChangeValues(date.format('YYYY-MM-DD'), "to");
                setDatesErrors({ ...datesErrors, toInvalid: false, toFormat: false, toMissing: false });
            }
        }
        else {
            setDatesErrors({ ...datesErrors, toFormat: true });
        }
    };

    const handleDeleteDate = (type: string) => {

        handleChangeValues("", type);

        if (type === "from") {
            setDatesErrors({ ...datesErrors, fromFormat: false, fromMissing: false, toInvalid: false });
        } else {
            setDatesErrors({ ...datesErrors, toFormat: false, toMissing: false, toInvalid: false });
        }
    };

    const errorChecker = () => {
        if (datesErrors.fromFormat) return true;
        if (datesErrors.fromMissing) return true;
        if (datesErrors.toFormat) return true;
        if (datesErrors.toMissing) return true;
        if (datesErrors.toInvalid) return true;

        return false;
    };

    const getDateErrorMessage = (type: string) => {

        if (datesErrors.fromFormat && type === "from") return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.toFormat && type === "to") return `${translate("commons.validation.wrongDate")}`;
        if (datesErrors.fromMissing && type === "from") return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toMissing && type === "to") return `${translate("commons.validation.missingDate")}`;
        if (datesErrors.toInvalid && type === "to") return `${translate("commons.validation.invalidDate")}`;

        return "";
    };

    return (
        <Stack
            direction={{ xs: 'column', sm: 'column', md: 'row' }}
            sx={{ px: 3, pt: 2.5, pb: { xs: 0, md: 2.5 }, display: 'flex', alignItems: 'center' }}
        >

            {optionsFields &&
                <FormControl
                    sx={{
                        minWidth: { xs: "100%", md: 160 },
                        mr: 2, mb: { xs: 2, md: 0 }
                    }}
                    variant="outlined"
                >
                    <InputLabel sx={{ '&.Mui-focused': { color: 'grey.500' } }}>
                        {`${translate('returns.searchType')}`}
                    </InputLabel>

                    <Select
                        value={toolbarFilterValues.searchType}
                        input={<OutlinedInput label={`${translate('returns.searchType')}`} />}
                        onChange={(event) => handleChangeValues(event.target.value, "searchType")}
                    >
                        {optionsFields.map((option) => (
                            <MenuItem
                                key={option.key}
                                value={option.key}
                                sx={{
                                    mx: 1, my: 0.5,
                                    borderRadius: 0.75,
                                    typography: 'body2',
                                    textTransform: 'capitalize'
                                }}
                            >
                                {option.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            }

            {showDates &&
                <Box
                    sx={{
                        minWidth: { xs: "100%", md: 410 },
                        mr: 2,
                        mb: { xs: 2, md: 0 }
                    }}
                >
                    <Stack
                        spacing={2}
                        direction={{ xs: 'column', sm: 'row' }}
                    >
                        <DatePicker
                            label={`${translate('commons.from')}`}
                            views={['year', 'month', 'day']}
                            format={'dd/MM/yyyy'}
                            value={filterValues.from ? dayjs(filterValues.from).toDate() : null}

                            onAccept={(newValue) => {
                                if (newValue)
                                    handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), "from");
                            }}

                            onChange={(fromDateValue, inputval) => {

                                if (!inputval.validationError && fromDateValue) {

                                    const date = dayjs(fromDateValue);

                                    handleChangeFrom(date);
                                } else {
                                    setDatesErrors({ ...datesErrors, fromFormat: true });
                                }
                            }}

                            slotProps={{
                                textField: {
                                    name: 'from',
                                    fullWidth: true,
                                    error: datesErrors.fromFormat || datesErrors.fromMissing,
                                    helperText: getDateErrorMessage("from"),
                                    InputProps: {
                                        startAdornment: (filterValues.from &&
                                            <InputAdornment position="end">
                                                <IconButton
                                                    sx={{ ml: -2, mr: 1 }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDeleteDate("from");
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            }}
                        />

                        <DatePicker
                            label={`${translate('commons.to')}`}
                            views={['year', 'month', 'day']}
                            format={'dd/MM/yyyy'}
                            value={filterValues.to ? dayjs(filterValues.to).toDate() : null}

                            onAccept={(newValue) => {
                                if (newValue)
                                    handleChangeValues(dayjs(newValue).format('YYYY-MM-DD'), "to");
                            }}

                            onChange={(fromDateValue, inputval) => {
                                if (!inputval.validationError && fromDateValue) {

                                    const date = dayjs(fromDateValue);

                                    handleChangeTo(date);
                                } else {
                                    setDatesErrors({ ...datesErrors, toFormat: true });
                                }
                            }}

                            slotProps={{
                                textField: {
                                    name: 'to',
                                    fullWidth: true,
                                    error: datesErrors.toFormat || datesErrors.toMissing || datesErrors.toInvalid,
                                    helperText: getDateErrorMessage("to"),
                                    InputProps: {
                                        startAdornment: (filterValues.to &&
                                            <InputAdornment position="end">
                                                <IconButton
                                                    sx={{ ml: -2, mr: 1 }}
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        handleDeleteDate("to");
                                                    }}
                                                >
                                                    <ClearIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }
                                }
                            }}
                        />

                    </Stack>
                </Box>
            }

            <TextField
                type="search"
                value={toolbarFilterValues.searchValue}
                onChange={(event) => handleChangeValues(event.target.value, "searchValue")}
                placeholder={`${translate('commons.search')}`}
                sx={{ width: "100%", mr: 2 }}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        handleSearch();
                    }
                }}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <Iconify
                                icon={'eva:search-fill'}
                                sx={{ color: 'text.disabled', width: 20, height: 20 }}
                            />
                        </InputAdornment>
                    ),
                }}
            />

            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

                <Tooltip
                    title={`${translate("commons.validation.invalidData")}`}
                    disableFocusListener
                    disableTouchListener
                    disableInteractive
                    open={openTooltip}
                    sx={{ mb: () => errorChecker() ? 4 : 0, width: { xs: "100%" } }}
                >
                    <Box
                        onMouseEnter={() => { if (errorChecker()) setOpenTooltip(true); }}
                        onMouseLeave={() => { if (errorChecker()) setOpenTooltip(false); }}
                    >
                        <Button
                            startIcon={<Iconify icon={'bx:search-alt'} sx={{ ml: 1.5, mt: 0.5 }} />}
                            onClick={handleSearch}
                            disabled={errorChecker()}
                            size={"large"}
                            sx={{ mt: 0.5, width: { xs: "100%" } }}
                        />
                    </Box>
                </Tooltip>

                <Button
                    startIcon={<DeleteIcon sx={{ ml: 1.5, mt: 0.75 }} />}
                    onClick={handleDelete}
                    size={"large"}
                    sx={{
                        display: `${(showDeleteButton) ? "inline" : "none"}`,
                        mt: 0.5, width: { xs: "100%" }
                    }}
                />
            </Box>

            {editableColumns &&
                <VisibilityModelComponent model={columnVisibility!} onChangeModel={setColumnVisibility!} />
            }

        </Stack>
    );
}

