import { alpha, Autocomplete, Box, Card, Divider, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import StepButtons from "src/components/buttons/StepButtons";

interface CustomerStepProps {
    changeStep: Dispatch<SetStateAction<number>>
}

export default function CustomerStep({ changeStep }: CustomerStepProps) {
    // const { translate } = useLocales();

    const [allElegibles, setAllElegibles] = useState(false);

    return (
        <Box >
            <Card sx={{ p: 3, display: 'flex', gap: 2, flexDirection: 'column' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" >Eligible customers</Typography>
                    <FormControlLabel
                        label={'All customers are eligible'}
                        onChange={(_, check) => setAllElegibles(check)}
                        control={<Switch checked={allElegibles} />}
                    />
                </Box>
                {!allElegibles &&
                    <Box sx={{ display: 'flex', gap: 2, flexDirection: 'column' }}>
                        <Typography variant="subtitle2">Select category</Typography>
                        <Autocomplete

                            options={[]}
                            renderOption={((props, option) => (
                                <Box component="li" {...props}>
                                    {option}
                                </Box>
                            ))}
                            renderInput={(params) =>
                                <TextField
                                    label={'Search ID or Group Name...'}
                                    {...params}
                                    InputProps={{ ...params.InputProps }}
                                    sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                                />}
                        />
                        <GreyCounter label="0 Customers selected" />
                        <Divider sx={{ my: 3 }} />

                        <Typography variant="subtitle2">Select blacklist</Typography>
                        <Autocomplete

                            options={[]}
                            renderOption={((props, option) => (
                                <Box component="li" {...props}>
                                    {option}
                                </Box>
                            ))}
                            renderInput={(params) =>
                                <TextField
                                    label={'Search ID or Group Name...'}
                                    {...params}
                                    InputProps={{ ...params.InputProps }}
                                    sx={{ '& .MuiInputLabel-asterisk': { color: 'error.main' } }}
                                />}
                        />
                        <GreyCounter label="0 Customers selected" />
                    </Box>}
                <Divider sx={{ my: 3 }} />
                <StepButtons changeStep={changeStep} />
            </Card>

        </Box>
    );
}

interface GreyCounterProps {
    label: string
}

export function GreyCounter({ label }: GreyCounterProps) {
    return (
        <Box sx={{
            width: '100%',
            height: "50px",
            borderRadius: '12px',
            bgcolor: (theme) => theme.palette.background.neutral,
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: (theme) => `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
        }}>
            <Typography variant="body2" sx={{ color: (theme) => theme.palette.text.disabled }}>{label}</Typography>
        </Box>
    );
}