import { Box, Typography, TypographyProps } from "@mui/material";
import { useEffect, useState } from "react";
import { CurrencyTypes } from "src/@types/vehicle";
import useCurrency from "src/appHooks/useCurrency";
import { useSettingsContext } from "src/components/settings";
import useResponsive from "src/hooks/useResponsive";
import { useLocales } from "src/locales";
import { convert } from "src/utils/currency";

interface CurrencyAdapterProps extends TypographyProps {
    value: number | string,
    currency: CurrencyTypes,
    label?: string,
    noVatAsterisk?: boolean,
    noSpace?: boolean
}

export default function CurrencyAdapter({ value, noSpace, currency, label, noVatAsterisk, ...TypographyProps }: CurrencyAdapterProps) {

    const { currency: currentCurrency } = useSettingsContext();

    const { currentLang } = useLocales();

    const { rates } = useCurrency();

    const isDesktop = useResponsive('up', 'sm');

    const [convertedValue, setConvertedValue] = useState(value !== '—' ? Number(value) : 0);

    const [oldValue, setOldValue] = useState(value);

    const [actualCurrency, setActualCurrency] = useState(currency);

    useEffect(() => {
        if (oldValue !== value) {
            setConvertedValue(+value);
            setOldValue(value);
        }
        else {
            setConvertedValue(Math.round((convert(convertedValue, actualCurrency, currentCurrency.label, rates) + Number.EPSILON) * 100) / 100);
            setActualCurrency(currentCurrency.label);
        }
    }, [value, currency, currentCurrency, rates, oldValue]);

    return (
        <>
            {label ?
                <Box sx={{ display: 'grid', alignItems: 'end', gridTemplateColumns: { sm: 'repeat(2, 1fr)' } }}>
                    <Typography display="inline" sx={{ color: 'text.secondary', fontWeight: '400' }}>
                        {`${label}: `}
                    </Typography>
                    <Typography display="inline" sx={{ color: 'text.primary', fontWeight: '600', textAlign: 'left', pl: isDesktop ? 2 : 0 }}>
                        {currentCurrency.symbol + convertedValue.toFixed(2)}
                    </Typography>
                </Box>
                :
                <Typography {...TypographyProps}>
                    {value !== '—' ?
                        (convertedValue.toLocaleString(currentLang.locale.code, { style: "currency", currency: currentCurrency.label, maximumFractionDigits: 2, minimumFractionDigits: 2 }) + (noVatAsterisk ? " *" : ""))
                        :
                        value
                    }
                </Typography>
            }
        </>
    );
}