import { Box, Button, Card, Divider, Modal, Stack, TextField, Typography } from "@mui/material";
import { useLocales } from "src/locales";
import CancelIcon from '@mui/icons-material/Cancel';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { useNavigate, useParams } from "react-router";
import Label from "src/components/label/Label";
import useResponsive from "src/hooks/useResponsive";
import { useCallback, useEffect, useMemo, useState } from "react";
import { DeleteForever } from '@mui/icons-material';
import { LabelColor } from "src/components/label";
import dayjs from "dayjs";
import { GridLabeledText } from "src/appComponents/GridLabeledText";
import { PATH_DASHBOARD } from "src/routes/paths";
import { RootState, dispatch, useSelector } from "src/redux/store";
import { requestOperations } from "src/redux/request";
import { Media, RedStatusType, RedStatusesArr, Request } from "src/@types/request";
import LoadingScreen from "src/appComponents/loading-screen";
import MediaSlider from "src/components/mediaSlider/MediaSlider";
import PartsDetail from "src/components/request-utils/PartsDetail";
import ApprovedProductsDetails from "src/components/request-utils/ApprovedProductsDetails";
import MediaList from "src/components/mediaList/MediaList";
import { brandOperations } from "src/redux/brand";
import MoreDetails from "src/components/request-utils/MoreDetails";
import { CurrencyTypes } from "src/@types/vehicle";
import { ApprovedTypeDetail, BigAndHeavyDetail, QuantityDetail } from "src/components/request-utils/LabelDetail";
import { RefusedNotesAlert, AmendNotesAlert, ApproverNotesAlert, ClaimNotesAlert } from "src/components/request-utils/FeesAndNotes";
import { setSuccessMessage } from "src/redux/modal/modal-slices";

interface ReturnDetailProp {
    request: Request
};

export default function AnomalyRequestDetail({ request }: ReturnDetailProp) {

    const { translate } = useLocales();

    const [refused, setRefused] = useState(false);

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'sm');

    const { backTo } = useParams();

    const { isLoading, filtersInUrl } = useSelector((state: RootState) => state.request);

    const { isLoading: isOrganizationLoading } = useSelector((state: RootState) => state.organizations);

    const { publicBrandList } = useSelector((state: RootState) => state.brand);

    useEffect(() => {
        if (!publicBrandList) dispatch(brandOperations.getBrandList(false));
    }, [publicBrandList]);

    const getIcon = () => {
        switch (request.requestType) {
            case 'NewPart':
                return <PublishedWithChangesIcon />;
            case 'DamagedPart':
                return <CancelIcon />;
            case 'WrongShipment':
                return <LocalShippingIcon />;
            case 'Core':
                return <KeyboardReturnIcon />;
            case 'Quality':
                return <FactCheckIcon />;
            case 'Warranty':
                return <BrokenImageIcon />;
            case "":
                return <></>;
        };
    };

    const getStatusColor = (status: string): LabelColor => {

        let color = 'warning';

        if (status && RedStatusesArr.includes(status as RedStatusType)) color = "error";

        return color as LabelColor;
    };

    const handleSubmit = async (note: string) => {
        if (refused) {
            await dispatch(requestOperations.refusedOp({ id: request.id, note: note, customFields: {} })).unwrap();
        }
        else {
            await dispatch(requestOperations.closeAnomalyOp({ id: request.id, note: "", customFields: {} })).unwrap();
        }

        dispatch(setSuccessMessage({ text: translate('request.anomalies.detail.successMessage'), returnTo: PATH_DASHBOARD.request.vendor.anomalies.list }));
    };

    const handleRestore = async () => {
        await dispatch(requestOperations.restoreApprove({ id: request.id, note: null, customFields: null })).unwrap();

        dispatch(setSuccessMessage({ text: translate('request.anomalies.detail.successMessage'), returnTo: PATH_DASHBOARD.request.vendor.anomalies.list }));
    };

    const allQuantities = useMemo(() => {

        let counter = 0;

        if (request.items.length > 0) request.items.forEach((item) => counter += item.requiredQuantity || item.approvedQuantity);
        if (request.missingItems.length > 0) request.missingItems.forEach((item) => counter += item.requiredQuantity || item.approvedQuantity);
        if (request.exceededItems.length > 0) request.exceededItems.forEach((item) => counter += item.quantity);

        return counter;

    }, [request]);

    const getBrandName = useCallback((brandId: string) => {
        if (publicBrandList) return publicBrandList.find((brand) => brand.id === brandId)?.name;

        return brandId;
    }, [publicBrandList]);

    const isMediaImage = useMemo(() => request.hasMedia && request.requestType !== "Warranty", [request]);

    const isMissingParts = useMemo(() => request.requestType === "WrongShipment" && request.requestReason === "MissingParts", [request]);

    const isAR = useMemo(() => request.requestType === "Warranty" && request.warranty && request.warranty.invoices.length === 1, [request]);

    const getFileLabel = useCallback((label: string) => {
        switch (label) {
            case "firstInvoice": return `${translate('request.form.firstInvoice')}`;
            case "secondInvoice": return `${translate('request.form.secondInvoice')}`;
            case "vehicleInfo": return `${translate('request.form.vehicleInfo')}`;
            case "firstRepairInvoice": return `${translate('request.form.invoiceRepair')}`;
            case "secondRepairInvoice": return `${translate('request.form.invoiceSecondRepair')}`;
            case "maddDoc": return `${translate('request.detail.maddDoc')}`;
        }

        return label;
    }, [translate]);

    const mediaFromFile = useMemo(() => {
        if (request.media && request.warranty) {
            return {
                media: request.media.map((file, index) => {
                    return {
                        section: getFileLabel(file.section || ""),
                        mediaType: file.mediaType,
                        fileInfo: file.fileInfo,
                        uploadDateInfo: isAR ? request.warranty?.invoices[index]?.date || "" : ""
                    } as Media;
                }),
                mediaData: request.media.map((file, index) => index === 2 ? (getBrandName(request.warranty!.vehicle!.brand) + " " + request.warranty!.vehicle!.model) : request.warranty?.invoices[(index > 2 ? index - 1 : index)]?.number || "")
            };
        }

        return null;

    }, [request, getFileLabel, getBrandName, isAR]);

    const showApprovedItems = useMemo(() => {

        const differentQuantities = !!(request.items.find((item) => item.approvedQuantity !== item.requiredQuantity));

        return request.approverNotes && request.items.length > 0 && differentQuantities;
    }, [request]);

    return (
        (isOrganizationLoading || isLoading) ? <LoadingScreen /> :
            <Box sx={{ mt: 3 }}>

                <RefuseModal
                    refused={refused}
                    handleSubmit={handleSubmit}
                    setRefused={setRefused}
                />

                <Card>
                    <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2
                        }}
                    >
                        <Stack>
                            <Box
                                sx={{
                                    display: 'inline-flex',
                                    justifyContent: 'flex-start',
                                    alignItems: "flex-start",
                                    flexDirection: isDesktop ? "row" : "column",
                                    gap: 1.5, mb: 1
                                }}
                            >
                                <Box>
                                    <Typography variant="h4">
                                        {`${translate('returns.filter.idRequest')} ${request.prettyName}`}
                                    </Typography>

                                    <Typography variant="body2">
                                        {`${translate(`commons.createdOn`)} `}
                                        <b>{dayjs(request.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                                    </Typography>
                                </Box>

                                <Label
                                    color={getStatusColor(request.status)}
                                    sx={{ textTransform: 'uppercase', ml: 2, mt: 1 }}
                                >
                                    {`${translate(`commons.order.returnRequest.${request.status}`)}`}
                                </Label>
                            </Box>

                            <Typography
                                variant="h6"
                                sx={{
                                    mb: isDesktop ? 1 : 0,
                                    mt: isDesktop ? 1 : 3,
                                    display: 'flex',
                                    alignContent: 'center',
                                    gap: 2
                                }}
                            >
                                {getIcon()}
                                {`${translate(`request.${request.requestType}`)}`}
                                {request.requestReason && ` - ${translate(`request.selectReason.${request.requestReason[0].toLowerCase() + request.requestReason.slice(1)}`)}`}
                            </Typography>
                        </Stack>

                        <Box
                            sx={{
                                display: 'flex', gap: 2,
                                alignContent: 'center',
                                flexDirection: isDesktop ? "row" : "column"
                            }}
                        >
                            {request.approverNotes && request.approverNotes.approvedType &&
                                <ApprovedTypeDetail approvedType={request.approverNotes.approvedType} />
                            }

                            {(request.items[0]?.bigAndHeavy ||
                                request.missingItems[0]?.bigAndHeavy ||
                                request.exceededItems[0]?.bigAndHeavy) && <BigAndHeavyDetail />}

                            <QuantityDetail quantity={allQuantities} />

                        </Box>
                    </Box>

                    <Divider />

                    <PartsDetail
                        items={request.requestType === "WrongShipment" ? request.missingItems : request.items}
                        clickToDetail
                        missingChanges={isMissingParts}
                        exceededItems={request.exceededItems}
                        customFields={request.customFields}
                        showDepositRef={request.requestType === "Warranty" || request.requestType === "Core"}
                        icar={request.approverNotes?.customFields["stellantis-europe-returnrequestapprover-icar-request"] || ""}
                        sap={request.approverNotes?.customFields["stellantis-europe-returnrequestapprover-sapcsps-code"] || ""}
                    />

                </Card>

                {request.refusedNotes && <RefusedNotesAlert refusedNotes={request.refusedNotes} />}

                {request.toBeAmendedNotes && request.status === "ToBeAmended" && <AmendNotesAlert amendNotes={request.toBeAmendedNotes} />}

                {request.approverNotes && request.approverNotes.surveillanceFee > 0 && <ApproverNotesAlert approverNotes={request.approverNotes} />}

                {request.customerClaimNotes && <ClaimNotesAlert isVendor customerClaimNotes={request.customerClaimNotes} vendorClaimNotes={request.vendorClaimNotes} />}

                <Card sx={{ mt: 2 }}>

                    {request.acceptNotes &&
                        <>
                            <Typography
                                display="flex"
                                variant="h6"
                                sx={{
                                    color: 'text.primary',
                                    fontWeight: '600',
                                    p: 3, pb: 1
                                }}
                            >
                                {`${translate('request.anomalies.detail.warehouseReport')}`}
                            </Typography>

                            <Box
                                sx={{
                                    p: 3, pt: 2,
                                    display: 'grid',
                                    columnGap: 2,
                                    rowGap: 2,
                                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                                }}
                            >
                                <GridLabeledText
                                    align={"left"}
                                    label={`${translate('request.anomalies.detail.reasonCode')}`}
                                    value={request.acceptNotes?.reasonAccepted ? `${translate('commons.yes')}` : `${translate('commons.no')} - ` + request.acceptNotes?.reasonCode || ""}
                                    left={4.5}
                                    right={7.5}
                                    variant="body2"
                                />
                                <GridLabeledText
                                    align={"left"}
                                    label={`${translate('request.anomalies.detail.correctPart')}`}
                                    value={request.acceptNotes?.partAccepted ? `${translate('commons.yes')}` : `${translate('commons.no')}`}
                                    left={5.5}
                                    right={6.5}
                                    variant="body2"
                                />
                                <GridLabeledText
                                    align={"left"}
                                    label={`${translate('request.anomalies.detail.goodCondition')}`}
                                    value={request.acceptNotes?.partConditionAccepted ? `${translate('commons.yes')}` : `${translate('commons.no')}`}
                                    left={4.5}
                                    right={7.5}
                                    variant="body2"
                                />
                                <GridLabeledText
                                    align={"left"}
                                    label={`${translate('request.anomalies.detail.administrationCorrect')}`}
                                    value={request.acceptNotes?.administrativeCheckAccepted ? `${translate('commons.yes')}` : `${translate('commons.no')}`}
                                    left={5.5}
                                    right={6.5}
                                    variant="body2"
                                />
                            </Box>
                        </>
                    }

                    {(!request.acceptNotes?.partConditionAccepted && request.hasMedia && isMediaImage) &&
                        <>
                            <Typography
                                display="flex"
                                variant="h6"
                                sx={{
                                    color: 'text.primary',
                                    fontWeight: '600',
                                    p: 3, pb: 1
                                }}
                            >
                                {`${translate('request.anomalies.detail.badCondition')}`}
                            </Typography>
                            <Box sx={{ p: 3, pt: 0 }}>
                                <MediaSlider images={request.media} />
                            </Box>
                        </>
                    }

                    <MoreDetails request={request} />

                    {request.warranty &&
                        <>
                            {request.warranty.vehicle &&
                                <>
                                    <Divider />
                                    <Typography sx={{ p: 3, pb: 4 }} variant="subtitle1">{`${translate('request.anomalies.detail.vehicleInfo')}`}</Typography>
                                    <Box
                                        sx={{
                                            display: 'grid',
                                            columnGap: 3, rowGap: 4,
                                            p: 3, pt: 0,
                                            gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' }
                                        }}>
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.brand")}`}
                                            value={getBrandName(request.warranty.vehicle.brand)}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.model")}`}
                                            value={request.warranty.vehicle.model || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.vin")}`}
                                            value={request.warranty.vehicle.vin || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.plate")}`}
                                            value={request.warranty.vehicle.plate || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate("commons.registrationDate")}`}
                                            value={request.warranty.vehicle.registrationDate || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                    </Box>
                                </>
                            }

                            {request.warranty.note &&
                                <>
                                    <Divider />
                                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.issueDescription')}`}</Typography>
                                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty.note}</Typography>
                                </>
                            }

                            {request.warranty.diagnosis &&
                                <>
                                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.diagnosis')}`}</Typography>
                                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty.diagnosis}</Typography>
                                </>
                            }

                            {request.warranty.solution &&
                                <>
                                    <Typography sx={{ p: 3 }} variant="subtitle1">{`${translate('request.remedy')}`}</Typography>
                                    <Typography sx={{ p: 3, pt: 0 }} variant="body1">{request.warranty.solution}</Typography>
                                    <Box
                                        sx={{
                                            display: 'flex',
                                            flexDirection: isDesktop ? "row" : "column",
                                            columnGap: 3, rowGap: 4, px: 3
                                        }}
                                    >
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate('request.timeRepair')}`}
                                            value={request.warranty.hoursSpent?.toString() || '—'}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                        <GridLabeledText
                                            align={"left"}
                                            label={`${translate('request.hourRateRepair')}`}
                                            value={request.warranty.ratePerHour?.withoutVat?.toString() || '—'}
                                            currentCurrency={request.warranty.ratePerHour?.currentCurrency as CurrencyTypes}
                                            left={4.5}
                                            right={7.5}
                                            variant="body2"
                                        />
                                    </Box>
                                </>
                            }
                        </>
                    }

                    {(request.hasMedia && !isMediaImage && mediaFromFile && mediaFromFile.mediaData) &&
                        <Box sx={{ mt: 3, p: 3, pt: 0, pb: isDesktop ? 3 : 0 }}>
                            <MediaList
                                media={mediaFromFile.media}
                                mediaData={mediaFromFile.mediaData}
                            />
                        </Box>
                    }

                    {showApprovedItems &&
                        <ApprovedProductsDetails
                            approvedProducts={request.items}
                            hideFirstDivider
                            sx={{ px: 3, pb: 2, pt: 2 }}
                        />
                    }

                    <Divider />

                    <Box
                        sx={{
                            p: 3,
                            justifyContent: 'space-between',
                            ml: isDesktop ? 'auto' : 0,
                            display: 'flex',
                            gap: 1,
                            flexDirection: isDesktop ? 'default' : 'column-reverse'
                        }}
                    >
                        <Button
                            variant="soft"
                            sx={{ borderRadius: '100px' }}
                            onClick={() => backTo === "a" ?
                                navigate(PATH_DASHBOARD.request.vendor.anomalies.list + filtersInUrl, { replace: true }) :
                                navigate(PATH_DASHBOARD.request.vendor.list + filtersInUrl, { replace: true })
                            }
                        >
                            {`${translate('request.goBack')}`}
                        </Button>
                        {
                            (request.status === "LabelRestoreRequested") &&
                            <Button
                                sx={{ borderRadius: '100px' }}
                                variant="contained"
                                onClick={() => handleRestore()}
                            >
                                {`${translate('request.detail.approveRestore')}`}
                            </Button>
                        }
                        {request.status === "WaitingForInvestigation" && <Box sx={{ display: isDesktop ? 'flex' : 'inline', gap: 2, width: isDesktop ? 'auto' : '100%' }}>
                            <Button
                                variant="outlined"
                                color="error"
                                sx={{ borderRadius: '100px', width: isDesktop ? 'auto' : '100%' }}
                                onClick={() => setRefused(true)}
                                startIcon={<DeleteForever />}
                            >
                                {`${translate("request.anomalies.detail.return")}`}
                            </Button>

                            <Box>
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: '100px', mt: isDesktop ? 0 : 1, width: isDesktop ? 'auto' : '100%' }}
                                    onClick={() => handleSubmit("")}
                                >
                                    {`${translate("request.anomalies.detail.close")}`}
                                </Button>
                            </Box>
                        </Box>
                        }
                    </Box>

                </Card >

                <Box display={'flex'}>
                    <Typography sx={{ mx: "auto", mt: 3 }} fontSize={"12px"}>
                        {`${translate('request.lastModified')} `}
                        <b>{dayjs(request.modifiedOn ? request.modifiedOn : request.createdOn).format('DD MMM YYYY, HH:mm')}</b>
                    </Typography>
                </Box>
            </Box >
    );
}

interface RefuseModalProps {
    refused: boolean,
    handleSubmit: (note: string) => void,
    setRefused: (v: boolean) => void
}

function RefuseModal({ handleSubmit, refused, setRefused }: RefuseModalProps) {

    const isDesktop = useResponsive('up', 'sm');

    const { translate } = useLocales();

    const [note, setNote] = useState("");

    useEffect(() => setNote(""), [refused]);

    return (
        <Modal
            open={refused}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Card sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                p: isDesktop ? 5 : 2,
            }}>
                <Box sx={{ display: 'flex', flexDirection: 'column', mb: 1 }}>
                    <Typography
                        variant="h6"
                        textAlign={'center'}
                    >
                        {`${translate("request.anomalies.detail.close")}`}
                    </Typography>
                    <Typography
                        variant="body1"
                        textAlign={'center'}
                    >
                        {`${translate("request.anomalies.detail.confirm")}`}
                    </Typography>
                </Box>

                <TextField
                    value={note}
                    onChange={(e) => setNote(e.target.value)}
                    sx={{ my: 2 }}
                    multiline
                    rows={2}
                    fullWidth
                    variant="outlined"
                    placeholder={`${translate(`request.insertNote`)}`}
                />

                <Box
                    sx={{
                        mt: 2,
                        justifyContent: 'space-between',
                        ml: isDesktop ? 'auto' : 0,
                        display: 'flex',
                        gap: 1,
                        flexDirection: isDesktop ? 'default' : 'column-reverse'
                    }}
                >

                    <Button
                        variant="outlined"
                        color="inherit"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => {
                            setRefused(false);
                            setNote("");
                        }}
                    >
                        {`${translate(`commons.cancel`)}`}
                    </Button>

                    <Button
                        variant="contained"
                        startIcon={<DeleteForever />}
                        color="error"
                        sx={{ borderRadius: '100px' }}
                        onClick={() => {
                            handleSubmit(note);
                            setRefused(false);
                        }}
                    >
                        {`${translate('commons.confirm')}`}
                    </Button>

                </Box>
            </Card>
        </Modal>
    );
}